<template>
  <div class="article-item-container" @click="onPress">
    <img v-if="item.img" :src="item.img" alt="" />
    <div class="right-wrapper">
      <p class="title">{{ item.itemName }}</p>
      <p class="secondary">
        {{ item.UserInfo?.User?.School?.name || item.properties?.author || "" }} |
        {{ formatViewCount(item.ItemCount?.viewCount || 0) }}阅读
      </p>
    </div>
  </div>
</template>

<script setup>
import { formatViewCount } from "@/utils/render";

const { useRouter } = require("vue-router");

const props = defineProps({
  // 文章信息
  item: {
    type: Object,
    default: () => {},
  },
});

const router = useRouter();

// 点击
function onPress() {
  const itemId = props.item.itemId;
  router.push({ path: "/share-article", query: { itemId } });
}
</script>

<style scoped>
.article-item-container {
  padding: 24px 0;
  border-bottom: 1px solid #99999950;
  display: flex;
  flex-direction: row;
}
.article-item-container img {
  width: 220px;
  height: 140px;
  border-radius: 10px;
  background-color: #999999;
}
.article-item-container .right-wrapper {
  flex: 1;
  margin-left: 20px;
  min-height: 120px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.article-item-container .right-wrapper .title {
  color: #333333;
  font-size: 28px;
  line-height: 36px;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
.article-item-container .right-wrapper .secondary {
  color: #999999;
  font-size: 18px;
}
</style>