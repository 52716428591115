<template>
    <div class="id-view">
      <div class="title">请先验证用户身份</div>
      <div class="id-code-wrapper">
        <input  class="ipt-code" v-model="inputCode" placeholder="请输入验证码">
        <div class="refresh" @click="refresh">换一换</div>
      </div>
      <identify ref="idfy" @refreshCode="onRefresh"></identify>
      <div class="cer-code" @click="certification">确定</div>
    </div>
</template>

<script>
  import Identify from './identify'
  import {sha256As} from "../utils/secure";
  import storage from "../utils/storage";

  export default {
    name: "identify-view",
    components: {
      Identify
    },
    data() {
      return {
        identifyCode: '',
        inputCode: '',
        enableIdentify: false,
        count: 0
      }
    },
    methods: {
      refresh() {
        this.count++
        if (this.count > 5) {
          this.$emit('onHint', '请不要频繁刷新')
          return
        }
        this.$refs.idfy.refreshCode()
      },
      onRefresh(code) {
        this.identifyCode = code
      },
      certification(e) {
        if (!e) {
          return
        }
        let clickSign = '_' + e.offsetX + '__' + e.offsetY
        clickSign = sha256As(clickSign)
        let lastSign = storage.getText('_cs_hxz_')
        // if (lastSign && lastSign == clickSign) {
        //   return
        // }
        storage.setText('_cs_hxz_', clickSign)

        let code = this.inputCode
        if (!code) {
          this.$emit('onIdentify', false, '请填写验证码')
          return
        }

        // 2秒内填写验证码的视为刷票
        if (!this.enableIdentify) {
          clearTimeout(this.timer)
          return
        }

        code = code.replace(/\s*/g,"");
        if (String(this.identifyCode).toLowerCase() == String(code).toLowerCase()) {
          this.$emit('onIdentify', true, '验证通过', String(this.identifyCode).toLowerCase(), lastSign && lastSign == clickSign)
        } else {
          this.$emit('onIdentify', false, '验证码错误')
        }
      }
    },
    mounted() {
      // this.identifyCode = this.$refs.idfy.makeIdentifyCode()
      // console.log('this.identifyCode', this.identifyCode)
      this.timer = setTimeout(() => {
        this.enableIdentify = true
      }, 2000)
    }
  }
</script>

<style scoped>

  .id-view {
    width: 450px;
    height: 520px;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0px 4px 12px 0px rgba(230, 224, 216, 0.9);
    border-radius: 30px;
    background-color: white;
  }

  .title {
    font-size: 32px;
    color: #333333;
    line-height: 100px;
    font-weight: bold;
  }

  .ipt-code {
    width: 200px;
    height: 50px;
    line-height: 50px;
    font-size: 30px;
    color: #666666;
    border: none;
    /*border-radius: 40px;*/
    /*background-color: #f2f2f2;*/
    padding: 0 30px;
  }

  .id-code-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 1px solid #666666;
    margin-top: 20px;
    margin-bottom: 30px;
  }

  .refresh {
    line-height: 80px;
    font-size: 26px;
    color: #E71E28;
  }

  .cer-code {
    width: 250px;
    height: 60px;
    line-height: 60px;
    text-align: center;
    font-size: 30px;
    color: white;
    background-color: #E71E28;
    border-radius: 40px;
    margin-top: 90px;
  }
</style>
