<template>
  <div class="slide-page" :style="{
    backgroundImage: `url(${content.background})`,
    backgroundRepeat: 'no-repeat',
    backgroundPositionX: 'center',
    backgroundPositionY: 'center',
    backgroundSize: 'cover'}">
    <!--<img class="bg" :src="content.background" />-->
    <img v-for="(item, index) in content.images"
         :key="index"
         :src="item.src"
         :style="{width: item.width + 'px', height: item.height + 'px', position: 'absolute', left: item.left + 'px', top: item.top + 'px'}"/>
    <div style="flex: 1"></div>
    <div class="choice-container" v-if="content.choices">
      <div class="choice" v-for="(item, cIndex) in content.choices" :key="cIndex" @click="next(cIndex)">
        <img
            :src="selectedIndex === cIndex ? 'https://educator.oss-cn-hangzhou.aliyuncs.com/static/event/ev3/btn-light.png' : 'https://educator.oss-cn-hangzhou.aliyuncs.com/static/event/ev3/btn-dark.png'"/>
        <div class="choice-text">{{item.text}}</div>
      </div>
    </div>
    <div class="choice-container" style="justify-content: center" v-if="content.showStart">
      <img class="start"
           src="https://educator.oss-cn-hangzhou.aliyuncs.com/static/event/ev3/start-test.png"
           @click="start"/>
    </div>
    <div class="choice-container" style="justify-content: center" v-if="content.showResult">
      <div class="choice" @click="toResult">
        <img src="https://educator.oss-cn-hangzhou.aliyuncs.com/static/event/ev3/btn-dark.png"/>
        <div class="choice-text">查看结果</div>
      </div>
    </div>
    <div class="slide-hint" v-if="content.showSlideHint">
      <img style="width: 46px; height: 30px"
           src="https://educator.oss-cn-hangzhou.aliyuncs.com/static/event/ev3/slide-up.png"/>
      <div style="font-size: 24px; color: white; margin-left: 30px">上滑进入下一页</div>
    </div>
    <div class="scene" v-if="content.scene">{{content.scene}}</div>
  </div>
</template>

<script>
  import apiCommon from "@/assets/api/api_common";
  export default {
    name: 'slidePage',
    props: ['content', 'playMusic'],
    data() {
      return {
        selectedIndex: -1
      }
    },
    mounted() {
    },
    methods: {
      start() {
        this.next()
        apiCommon.traceReport({
          eventName: 'clickElement',
          reportData: {
            function_type: '教育类型首页',
            element_type: '立即测试'
          }
        }).then(() => {})
      },
      next(cIndex) {
        this.selectedIndex = cIndex
        this.$emit('next', cIndex)
        apiCommon.traceReport({
          eventName: 'clickElement',
          reportData: {
            function_type: '教育类型故事页（8个）',
            element_type: `按钮${cIndex + 1}`
          }
        }).then(() => {})
      },
      toResult() {
        this.$emit('toResult')
        apiCommon.traceReport({
          eventName: 'clickElement',
          reportData: {
            function_type: '教育类型故事页（8个）',
            element_type: '查看结果'
          }
        }).then(() => {})
      }
    }
  }
</script>

<style scoped>

  .slide-page {
    width: 100vw;
    height: 100vh;
    position: relative;
    display: flex;
    flex-direction: column;
  }

  .choice-container {
    width: 750px;
    height: 304px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 58px;
  }

  .choice {
    width: 496px;
    height: 80px;
    position: relative;
  }

  .choice-text {
    width: 496px;
    height: 80px;
    line-height: 80px;
    text-align: center;
    color: #333;
    font-size: 26px;
    position: absolute;
    left: 0;
    top: 0;
  }

  .start {
    width: 220px;
    height: 92px;
  }

  .slide-hint {
    width: 750px;
    height: 30px;
    position: absolute;
    bottom: 25px;
    left: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .scene {
    font-size: 30px;
    color: #333;
    position: absolute;
    left: 43px;
    top: 37px;
    font-weight: bold;
  }
</style>

