<template>
  <div class="list-container">
    <div class="tabs">
      <div
        class="tab"
        v-for="(item, index1) in tabs"
        :key="index1"
        @click="onTabPress(index1)"
      >
        <div
          :class="
            currentIndex === index1 ? 'tab-text-active' : 'tab-text-normal'
          "
        >
          {{ item }}
        </div>
        <img
          v-if="currentIndex === index1"
          style="width: 20px; height: 10px"
          :src="images.arrowDownGreen"
        />
      </div>
    </div>
    <div ref="content" class="content" @scroll="handleScroll">
      <van-list
        v-if="!showLoading"
        v-model="loading"
        :finished="isLastPage"
        finished-text="没有更多了"
        @load="getData"
      >
        <div
          class="line-container"
          v-for="(item, index2) in bookList"
          :key="index2"
          @click="onItemPress(item)"
        >
          <div class="left-container" v-if="index2 % 2 === 0">
            <img class="divider" :src="images.divider" />
            <div class="cover-wrapper">
              <img class="cover" :src="item.pic" />
              <van-swipe
                class="swiper-wrapper"
                v-if="item.BookRecomInfo && item.BookRecomInfo.showInfo"
                :autoplay="3000"
                :show-indicators="false"
              >
                <van-swipe-item
                  v-for="(rc, rindex1) in item.BookRecomInfo.showInfo"
                  :key="rindex1"
                >
                  <div class="swiper-line">{{ rc }}</div>
                </van-swipe-item>
              </van-swipe>
            </div>
            <img class="divider" :src="images.divider" />
          </div>

          <div class="right-container" v-if="index2 % 2 === 0">
            <div class="title">{{ item.bookName }}</div>
            <div class="sub-title">{{ item.author || "" }}</div>
            <div class="sub-title">{{ item.publishHouse || "" }}</div>
            <div class="desc">
              {{ item.ec ? item.subDetail : item.detail
              }}<span class="more" v-if="item.ec">查看详情</span>
              <img
                style="width: 20px; height: 20px; margin-bottom: -3px"
                v-if="item.ec"
                :src="images.arrowMoreGreen"
              />
            </div>
            <div
              style="
                display: flex;
                flex-direction: row;
                align-items: center;
                margin-top: 40px;
              "
            >
              <div
                class="action-btn action-btn-center-text"
                @click.stop="toggleLike(item)"
              >
                <img
                  class="vote"
                  :src="item.BookVote.isVote ? images.likeActive : images.like"
                />
                <div
                  class="vote-count"
                  :style="{
                    color: item.BookVote.isVote ? '#486d43' : 'b1b1b1',
                  }"
                >
                  {{
                    item.initVote === item.BookVote.isVote
                      ? item.BookVoteCount.bookCount
                      : item.BookVoteCount.bookToggleCount
                  }}
                </div>
              </div>

              <div
                class="action-btn"
                style="margin-left: 25px"
                @click.stop="share(item)"
              >
                <img
                  style="width: 31px; height: 31px; margin-left: 8px"
                  :src="images.share"
                />
                <div class="vote-count">分享</div>
              </div>
            </div>
          </div>

          <div class="right-container" v-if="index2 % 2 !== 0">
            <div class="title">{{ item.bookName }}</div>
            <div class="sub-title">{{ item.author || "" }}</div>
            <div class="sub-title">{{ item.publishHouse || "" }}</div>
            <div class="desc">
              {{ item.ec ? item.subDetail : item.detail
              }}<span class="more" v-if="item.ec">查看详情</span>
              <img
                style="width: 20px; height: 20px; margin-bottom: -3px"
                v-if="item.ec"
                :src="images.arrowMoreGreen"
              />
            </div>
            <div
              style="
                display: flex;
                flex-direction: row;
                align-items: center;
                margin-top: 40px;
              "
            >
              <div
                class="action-btn action-btn-center-text"
                @click.stop="toggleLike(item)"
              >
                <img
                  class="vote"
                  :src="item.BookVote.isVote ? images.likeActive : images.like"
                />
                <div
                  class="vote-count"
                  :style="{
                    color: item.BookVote.isVote ? '#486d43' : 'b1b1b1',
                  }"
                >
                  {{
                    item.initVote === item.BookVote.isVote
                      ? item.BookVoteCount.bookCount
                      : item.BookVoteCount.bookToggleCount
                  }}
                </div>
              </div>

              <div
                class="action-btn"
                style="margin-left: 25px"
                @click.stop="share(item)"
              >
                <img
                  style="width: 31px; height: 31px; margin-left: 8px"
                  :src="images.share"
                />
                <div class="vote-count">分享</div>
              </div>
            </div>
          </div>

          <div class="left-container" v-if="index2 % 2 !== 0">
            <img class="divider" :src="images.divider" />
            <div class="cover-wrapper">
              <img class="cover" :src="item.pic" />
              <van-swipe
                class="swiper-wrapper"
                v-if="item.BookRecomInfo && item.BookRecomInfo.showInfo"
                :autoplay="3000"
                :show-indicators="false"
              >
                <van-swipe-item
                  v-for="(rc, rindex2) in item.BookRecomInfo.showInfo"
                  :key="rindex2"
                >
                  <div class="swiper-line">{{ rc }}</div>
                </van-swipe-item>
              </van-swipe>
            </div>
            <img class="divider" :src="images.divider" />
          </div>
        </div>
      </van-list>
      <div v-else class="loading-container">
        <img
          webp="webp"
          style="width: 100px; height: 100px"
          src="/static/images/loading.gif"
        />
      </div>
    </div>
  </div>
</template>

<script>
import api_activity from "@/assets/api/api_activity";
import { subLine } from "@/utils/render";
import { isWeixin } from "@/assets/utils/utils";
import HOST from "@/assets/api";
import { AppId } from "@/assets/utils/utils";

export default {
  name: "ev1TabOne",
  components: {},
  data() {
    return {
      tokenId: null,
      tabs: ["3-6岁", "7-10岁", "11-14岁"],
      currentIndex: 0,
      images: {
        arrowDownGreen:
          "http://educator.oss-cn-hangzhou.aliyuncs.com/static/event/ev1/arrow-down-green.png",
        arrowMoreGreen:
          "http://educator.oss-cn-hangzhou.aliyuncs.com/static/event/ev1/arrow-more-green.png",
        divider:
          "http://educator.oss-cn-hangzhou.aliyuncs.com/static/event/ev1/ev1-divider.png",
        like: "http://educator.oss-cn-hangzhou.aliyuncs.com/static/event/ev1/like.png",
        likeActive:
          "http://educator.oss-cn-hangzhou.aliyuncs.com/static/event/ev1/like-active.png",
        share:
          "http://educator.oss-cn-hangzhou.aliyuncs.com/static/event/ev1/share.png",
      },
      pageNum: 1,
      isLastPage: false,
      bookList: [],
      user: null,
      showLoading: true,
      loading: false,
      scrollTop: 0,
    };
  },
  created() {
    if (isWeixin()) {
      this.tokenId = null;
    } else {
      this.tokenId = this.$route.query.tokenId;
    }
    this.getData();
  },
  activated() {
    this.$refs.content.scrollTop = this.scrollTop;
  },
  methods: {
    share(item) {
      this.$emit("onItemShare", item);
    },
    async getData() {
      if (this.loading) {
        return;
      }
      this.loading = true;
      const tokenId = this.tokenId;
      if (this.isLastPage) {
        return;
      }

      let params = {
        tokenId,
        start: (this.pageNum - 1) * 10,
        length: 10,
        bookTypeId: this.currentIndex + 4,
      };
      const res = await api_activity.getVoteBookList(params);
      this.loading = false;
      if (res.errcode === 2) {
        this.authLogin();
        return;
      }
      if (res.errcode !== 0) {
        this.$toast.fail(res.errmsg);
        return;
      }
      let data = res.data.rows ? res.data.rows : [];
      this.showLoading = false;
      data.forEach((item) => {
        let sub = subLine(item.detail, 20, 1150);
        item.subDetail = sub.txt;
        item.ec = sub.ec;
        if (!item.BookVoteCount) {
          item.BookVoteCount = {};
        }
        if (!Object.keys(item.BookVoteCount).length) {
          item.BookVoteCount = { bookCount: 0 };
        }
        if (!item.BookVote) {
          item.BookVote = {};
        }
        if (!Object.keys(item.BookVote).length) {
          item.BookVote = { isVote: 0 };
        }

        item.BookVoteCount.bookToggleCount =
          (item.BookVote.isVote ? -1 : 1) + item.BookVoteCount.bookCount;
        item.initVote = item.BookVote.isVote;
        item.pic = "https://educator.oss-cn-hangzhou.aliyuncs.com" + item.pic;
      });
      if (this.pageNum <= 1) {
        this.bookList = data;
      } else {
        this.bookList = this.bookList.concat(data);
      }
      this.isLastPage = !(this.bookList.length < res.data.count);
      this.pageNum++;
    },
    onTabPress(index) {
      this.currentIndex = index;
      this.pageNum = 1;
      this.isLastPage = false;
      this.bookList = [];
      this.showLoading = true;
      this.getData();
    },
    onItemPress(item) {
      if (item.ItemInfo && item.ItemInfo.itemId) {
        this.$router.push({
          name: "BookIntroduction",
          query: { itemId: item.ItemInfo.itemId },
        });
      }
    },
    // 投票
    async toggleLike(item) {
      const tokenId = this.tokenId;
      const bookId = item.bookId;
      const params = { tokenId, bookId };
      let res;
      let message = "";
      if (item.BookVote.isVote === 1) {
        res = await api_activity.cancelVote(params);
        message = "已取消投票";
      } else {
        res = await api_activity.vote(params);
        message = "感谢您的参与";
      }
      if (res.errcode !== 0) {
        this.$toast.fail(res.errmsg);
        return;
      }
      this.$toast.success(message);
      item.BookVote.isVote = item.BookVote.isVote === 1 ? 0 : 1;
    },
    // 授权登录
    authLogin() {
      const fromId = `&fromId=activity/100cb`;
      const applyId = `&applyId=${null}`;
      let redirectUrl = encodeURIComponent(
        `${HOST}/login/wxLoginV3?${applyId}${fromId}`
      );
      let url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${AppId}&redirect_uri=${redirectUrl}&response_type=code&scope=snsapi_userinfo&state=STATE&connect_redirect=1#wechat_redirect`;
      window.location.href = url;
    },
    // 列表滚动
    handleScroll(e) {
      const scrollTop = e.target.scrollTop;
      this.scrollTop = scrollTop;
    }
  },
};
</script>

<style scoped>
.list-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.tabs {
  width: 750px;
  height: 90px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #fff;
  z-index: 9999;
}
.content {
  flex: 1;
  /* height: 200px; */
  overflow: scroll;
}

.tab {
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.tab-text-active {
  color: #43693d;
  font-size: 30px;
}

.tab-text-normal {
  color: #313332;
  font-size: 24px;
}

.action-btn {
  min-width: 140px;
  padding: 0 20px;
  height: 50px;
  box-sizing: border-box;
  background-color: #ffffff;
  box-shadow: 0 2px 20px 0 rgba(81, 151, 72, 0.2);
  border-radius: 25px;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
}

.action-btn-center-text {
  width: 140px;
}

.action-cover-btn {
  width: 140px;
  height: 50px;
  background: transparent;
  border: none;
  position: absolute;
  left: 0;
  top: 0;
}

.divider {
  width: 344px;
  height: 27px;
}

.line-container {
  width: 690px;
  margin-left: 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 30px;
  margin-bottom: 30px;
}

.left-container {
  width: 344px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.right-container {
  width: 303px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.cover-wrapper {
  margin-top: 40px;
  margin-bottom: 40px;
  width: 300px;
  height: 300px;
  position: relative;
}

.cover {
  width: 300px;
  height: 300px;
  background: #f2f2f2;
}

.title {
  color: #486d43;
  font-size: 28px;
  line-height: 40px;
  font-weight: bold;
  text-align: center;
}

.sub-title {
  color: #313332;
  font-size: 20px;
  margin-top: 20px;
  text-align: center;
}

.desc {
  margin-top: 10px;
  color: #787878;
  font-size: 20px;
  line-height: 32px;
}

.vote-count {
  color: #b1b1b1;
  font-size: 24px;
  flex: 1;
  text-align: center;
}

.vote {
  width: 36px;
  height: 36px;
}

.more {
  color: #486d43;
  font-size: 20px;
  padding-left: 9px;
  padding-right: 9px;
}

.swiper-wrapper {
  width: 300px;
  height: 50px;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  bottom: 0;
  left: 0;
}

.swiper-line {
  width: 300px;
  height: 50px;
  line-height: 50px;
  color: white;
  font-size: 24px;
  text-align: center;
}
</style>
