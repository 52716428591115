<template>
  <div class="rs-display-container">
    <div class="content">
      <div class="card">
        <div class="search-bar">
          <input
            class="input"
            v-model="queryParams.search"
            placeholder="请输入作者姓名、作品编号或推荐单位"
            type="search"
          />
          <div class="button" @click="onSearch">搜索</div>
        </div>
        <div class="area-wrapper">
          <van-field
            v-model="queryParams.area"
            placeholder="按照区县搜索"
            readonly
            clearable
            @click="showPicker = true"
          />
          <van-button v-if="queryParams.area" icon="close" @click="handleRemoveArea"></van-button>
        </div>
        <van-tabs
          v-model:active="queryParams.type"
          type="card"
          color="#e41926"
          style="margin-top: 20px"
          @change="onTabsChange"
        >
          <van-tab title="短视频" :name="1"></van-tab>
          <van-tab title="征文" :name="2"></van-tab>
        </van-tabs>
        <van-list
          v-model:loading="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoadMore"
        >
          <div class="list">
            <VideoItem :item="item" v-for="(item, index) in list" :key="index" @click="toVideoDetail(item)" />
          </div>
        </van-list>
      </div>
    </div>
    <van-popup v-model:show="showPicker" position="bottom">
      <van-picker
        :columns="areaList"
        @confirm="onPickerConfirm"
        @cancel="showPicker = false"
        item-height="80"
      />
    </van-popup>
  </div>
</template>

<script>
import VideoItem from "./components/videoItem";
import api_activity from "@/assets/api/api_activity";
import { Notify } from "vant";
import { AuthMixin } from "@/assets/utils/mixin";

export default {
  mixins: [AuthMixin],
  name: "RSDisplay",
  components: { VideoItem },
  data() {
    return {
      queryParams: { search: "", type: 1, start: 0, length: 10, area: "" },
      hasMroe: true,
      list: [],
      loading: false,
      finished: false,
      showPicker: false,
      areaList: [
        "万州区",
        "黔江区",
        "涪陵区",
        "渝中区",
        "大渡口区",
        "江北区",
        "沙坪坝区",
        "九龙坡区",
        "南岸区",
        "北碚区",
        "渝北区",
        "巴南区",
        "长寿区",
        "江津区",
        "合川区",
        "永川区",
        "南川区",
        "綦江区",
        "大足区",
        "璧山区",
        "铜梁区",
        "潼南区",
        "荣昌区",
        "开州区",
        "梁平区",
        "武隆区",
        "城口县",
        "丰都县",
        "垫江县",
        "忠县",
        "云阳县",
        "奉节县",
        "巫山县",
        "巫溪县",
        "石柱县",
        "秀山县",
        "酉阳县",
        "彭水县",
        "两江新区",
        "高新区",
        "万盛经开区",
      ],
    };
  },
  methods: {
    // 刷新数据
    refreshData() {
      this.queryParams.start = 0;
      this.queryParams.search = "";
      this.hasMroe = true;
      this.getList();
    },
    // 上拉加载更多
    onLoadMore() {
      this.getList();
    },
    // 获取数据
    getList() {
      if (!this.hasMroe) {
        return;
      }
      const params = Object.assign({}, this.queryParams);
      api_activity.getReadStoryWorks(params).then((res) => {
        this.loading = false;
        if (res.errcode !== 0) {
          Notify({ type: "danger", message: res.errmsg });
          return;
        }
        if (params.start === 0) {
          this.list = res.data?.rows || [];
        } else {
          this.list = this.list.concat(res.data?.rows || []);
        }
        this.finished = this.list.length >= res.data?.count || 0;
        this.hasMroe = res.data?.count || 0 > this.list.length;
        this.queryParams.start += 10;
      });
    },
    // tabs改变
    onTabsChange() {
      this.queryParams.start = 0;
      this.hasMroe = true;
      this.getList();
    },
    // 跳转到视频详情中
    toVideoDetail(item) {
      if (item.type === 1) {
        this.$router.push({ path: "/activity/read-story/video-detail", query: { itemId: item.id } });
      } else if (item.type === 2) {
        this.$router.push({ path: "/activity/read-story/article-detail", query: { itemId: item.id } });
      }
    },
    // 搜索
    onSearch() {
      this.queryParams.start = 0;
      this.hasMroe = true;
      this.getList();
    },
    // 地区选择后
    onPickerConfirm(val) {
      this.queryParams.start = 0;
      this.hasMroe = true;
      this.queryParams.area = val;
      this.showPicker = false;
      this.getList();
    },
    // 移除区域搜索
    handleRemoveArea() {
      this.queryParams.start = 0;
      this.hasMroe = true;
      this.queryParams.area = "";
      this.getList();
    },
  },
  mounted() {
    this.initWxConfig({
      itemName: "“最美红色故事发声人”作品展示",
      desc: "“最美红色故事发声人”作品展示",
      pic: "https://image2.cqcb.com/d/file/image/default/2021-05-17/7f0a5358236913593b031b3fdbdd31f8.jpeg",
      link: `activity/read-story/display`,
    });
  },
};
</script>

<style lang="less" scoped>
.rs-display-container {
  .content {
    margin-top: -4px;
    background-color: #e41926;
    padding: 20px;

    .card {
      background-color: white;
      border-radius: 10px;
      padding: 20px;

      .search-bar {
        display: flex;
        flex-direction: row;
        align-items: center;
        border-radius: 10px;
        border: 2px solid #e41926;
        overflow: hidden;
        padding-left: 20px;

        .input {
          height: 60px;
          border: none;
          font-size: 24px;
          flex: 1;
          padding: 0;
          background-color: white;
        }

        .button {
          box-sizing: border-box;
          background-color: #e41926;
          color: white;
          font-size: 28px;
          font-weight: bold;
          width: 200px;
          height: 60px;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          padding: 0;
        }
      }

      .list {
        margin-top: 20px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;
      }
    }
  }
  .area-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    button {
      border: none;
    }
  }
}
</style>