<template>
  <div class="ranking-container">
    <van-list v-if="!showLoading" v-model="showLoading" :finished="isLastPage" finished-text="没有更多了" @load="getData">
       <div class="rank-item">
        <div class="rank rank-text">排名</div>
        <div
          class="rank-text"
          style="flex: 1; margin-left: 60px; text-align: center"
        >
          书名
        </div>
        <div class="rank-text" style="width: 164px">得票数</div>
      </div>
      <div class="rank-item" v-for="(item, index) in rankList" :key="index">
        <img class="rank" v-if="index === 0" :src="images.r1st" />
        <img class="rank" v-else-if="index === 1" :src="images.r2nd" />
        <img class="rank" v-else-if="index === 2" :src="images.r3rd" />
        <div class="rank rank-text" v-else>{{ index + 1 }}</div>
        <img class="cover" :src="item.pic" />
        <div class="title">{{ item.bookName }}</div>
        <div class="count">{{ item.BookVoteCount.bookCount }}</div>
      </div>
    </van-list>
    <div v-else class="loading-container" style="height: calc(100vh - 430px)">
      <img
        webp="webp"
        style="width: 100px; height: 100px"
        src="/static/images/loading.gif"
       />
    </div>
  </div>
</template>

<script>
import api_activity from "@/assets/api/api_activity";

export default {
  name: "ev1TabThree",
  props: {},
  data() {
    return {
      rankList: [],
      images: {
        r1st: "http://educator.oss-cn-hangzhou.aliyuncs.com/static/event/ev1/r_1st.png",
        r2nd: "http://educator.oss-cn-hangzhou.aliyuncs.com/static/event/ev1/r_2nd.png",
        r3rd: "http://educator.oss-cn-hangzhou.aliyuncs.com/static/event/ev1/r_3rd.png",
      },
      isLastPage: false,
      pageNum: 1,
      showLoading: true,
    };
  },
  created() {
    this.getData();
  },
  methods: {
    async getData() {
      const tokenId = this.$route.query.tokenId;
      if (this.isLastPage) {
        return;
      }
      let params = {
        tokenId,
        start: (this.pageNum - 1) * 150,
        length: 150,
      };
      const res = await api_activity.getVoteRank(params);
      let data = res.data.rows ? res.data.rows : [];
      this.showLoading = false;
      data.forEach((item) => {
        if (!item.BookVoteCount) {
          item.BookVoteCount = {};
        }
        if (!Object.keys(item.BookVoteCount).length) {
          item.BookVoteCount = { bookCount: 0 };
        }

        item.pic = "http://educator.oss-cn-hangzhou.aliyuncs.com/" + item.pic;
      });
      if (this.pageNum <= 1) {
        this.rankList = data;
      } else {
        this.rankList = this.rankList.concat(data);
      }
      this.isLastPage = data.length < 10;
      this.pageNum++;
    },
  },
};
</script>

<style scoped>
.ranking-container {
  flex: 1;
  overflow: scroll;
}

.rank-item {
  width: 750px;
  height: 124px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.rank {
  width: 64px;
  height: 60px;
  margin-left: 30px;
}

.rank-text {
  line-height: 60px;
  text-align: center;
  font-size: 26px;
  color: #b1b1b1;
}

.cover {
  width: 84px;
  height: 84px;
  border-radius: 42px;
  background: #f2f2f2;
  margin-left: 60px;
}

.title {
  flex: 1;
  margin-left: 30px;
  color: #313332;
  font-size: 26px;
  display: -webkit-box;
  word-break: break-all;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  line-clamp: 2;
}

.count {
  width: 164px;
  text-align: center;
  font-size: 30px;
  color: #486d43;
}
</style>
