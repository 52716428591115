<template>
    <div class="art-video-container">
        <video id="player" :src="itemInfo.videoUrl" controls :poster="itemInfo.cover" />
        <div class="content">
            <div class="title">{{ itemInfo.name }}</div>
            <div class="desc">
                <span>{{ itemInfo.properties }}</span>
            </div>
        </div>
    </div>
</template>

<script>
import { getItemInfo } from "@/assets/api/api_art"

export default {
    name: "ArtVideo",
    components: {},
    data() {
        return {
            // 作品id
            id: undefined,
            // 作品信息
            itemInfo: {},
        }
    },
    computed: {},
    watch: {},
    created() {
        this.id = this.$route.query.id
    },
    mounted() {
        this.getItemInfo()
    },
    methods: {
        // 获取作品信息
        async getItemInfo() {
            const res = await getItemInfo(this.id)
            if (res.errcode !== 0) {
                return
            }
            this.itemInfo = res.data
        },
    },
}
</script>
<style lang="less" scoped>
.art-video-container {
    width: 100vw;

    #player {
        width: 100%;
        aspect-ratio: 750 / 440;
    }

    .content {
        margin-top: 40px;
        margin-inline: 32px;
        padding: 36px 40px;
        background-image: url("/static/images/art/bg.png");
        background-position: center;
        background-repeat: no-repeat;
        background-size: 100% 100%;

        .title {
            color: #333333;
            font-size: 32px;
            line-height: 48px;
            font-weight: bold;
        }

        .desc {
            margin-top: 20px;
            text-align: justify;

            span {
                color: #333333;
                font-size: 26px;
                line-height: 48px;
                text-align: justify;
                display: inline-block;
                white-space: pre-wrap;
            }
        }
    }
}
</style>
