<template>
    <div class='lenovo-container'>
        <img src="../../../../public/static/images/activity/lenovo/1.png" alt="" @click="enroll">
        <div class="tip"><span>点击上方图片预约报名</span></div>
        <img src="../../../../public/static/images/activity/lenovo/2.png" alt="">
        <img src="../../../../public/static/images/activity/lenovo/3.png" alt="">
        <img src="../../../../public/static/images/activity/lenovo/4.png" alt="" @click="subscribe">
        <div class="swiper-wrapper">
            <img src="../../../../public/static/images/activity/lenovo/5.png" alt="">
            <van-swipe ref="v_swipe" class="swiper" :autoplay="5000" :show-indicators="true">
                <van-swipe-item v-for="(banner, bannerIdx) in bannerList" :key="bannerIdx">
                    <img :src="banner" alt="">
                </van-swipe-item>
            </van-swipe>
        </div>
        <img src="../../../../public/static/images/activity/lenovo/6.png" alt="">
        <!-- <div class="bottom-wrapper">
            <div class="button" @click="navigateToEnroll">立即报名</div>
        </div> -->
    </div>
</template>

<script>
import banner1 from '../../../../public/static/images/activity/lenovo/banner-1.png';
import banner2 from '../../../../public/static/images/activity/lenovo/banner-2.png';
import banner3 from '../../../../public/static/images/activity/lenovo/banner-3.png';
import banner4 from '../../../../public/static/images/activity/lenovo/banner-4.png';
import banner5 from '../../../../public/static/images/activity/lenovo/banner-5.png';
import banner6 from '../../../../public/static/images/activity/lenovo/banner-6.png';
export default {
    name: '',
    components: {},
    data() {
        return {
            bannerList: [banner1, banner2, banner3, banner4, banner5, banner6],
        };
    },
    computed: {},
    watch: {},
    created() { },
    mounted() {
        setTimeout(() => {
            this.$refs.v_swipe.resize();
        }, 2000);
    },
    methods: {
        // 报名
        navigateToEnroll() {
            this.$router.push({ name: 'lenovoEventEnroll' });
        },
        // 报名
        enroll() {
            window.location.href = 'https://app.ma.scrmtech.com/svip/sapIndex/SapSourceData?pf_uid=18839_1837&sid=68832&source=2&pf_type=3&channel_id=29779&channel_name=%E5%AA%92%E4%BD%93-%E5%85%89%E6%98%8E%E7%A4%BE%E6%95%99%E8%82%B2%E5%AE%B6&tag_id=d2bfea91d858ae5e'
        },
        // 预约直播
        subscribe() {
            window.location.href = 'https://app.ma.scrmtech.com/meetings-api/sapIndex/SapSourceData?pf_uid=18839_1837&sid=69532&source=2&pf_type=3&channel_id=36192&channel_name=%E5%AA%92%E4%BD%93&tag_id=f45553313b63bc0b'
        }
    },
}
</script>
<style lang='less' scoped>
.lenovo-container {
    // padding-bottom: 100px;

    img {
        width: 100%;
        vertical-align: top;
        margin-top: -2px;
    }

    .tip {
        padding: 10px 0;
        font-size: 24px;
        font-style: italic;
        font-weight: bold;
        text-align: center;
    }

    .swiper-wrapper {
        position: relative;

        .swiper {
            position: absolute;
            top: 200px;
            left: 36px;
            right: 36px;
            border-radius: 16px;
            overflow: hidden;
            box-sizing: border-box;
            aspect-ratio: 1620 / 1080;
        }
    }

    .bottom-wrapper {
        position: fixed;
        bottom: 0;
        height: 100px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        .button {
            background-color: #FF724F;
            color: white;
            font-size: 40px;
            width: 500px;
            height: 88px;
            border-radius: 44px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}
</style>