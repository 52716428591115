<template>
    <div class='lenovo-enroll-container'>
        <!-- 表单 -->
        <van-form ref="form" @submit="submit" v-model="form" label-width="130px">
            <van-field v-model="form.name" label="姓名" placeholder="请输入姓名" :rules="rules.name" required />
            <van-field v-model="form.mobile" label="手机号码" placeholder="请输入手机号码" :rules="rules.mobile" required />
            <!-- <van-field v-model="form.code" label="验证码" placeholder="请输入验证码" type="digit" :rules="rules.code" required>
                <template #button>
                    <van-button :loading="sending" size="small" type="primary" color="#FF724F" :disabled="interval < 60"
                        @click="sendCode">
                        {{ interval !== 60 ? `${interval}s后重新发送` : "发送验证码" }}
                    </van-button>
                </template>
            </van-field> -->
            <van-field v-model="form.email" label="邮箱" placeholder="请输入电子邮箱" :rules="rules.email" required />
            <van-field v-model="form.address" label="地址" placeholder="请选择您的地址" clickable readonly :rules="rules.address"
                @click="addressPickerVisible = true" required />
            <van-field v-model="form.workUnit" label="单位全称" placeholder="请输入单位全称" :rules="rules.workUnit" required />
            <van-field v-model="form.post" label="您的职位" placeholder="请选择您的职位" :rules="rules.post" clickable readonly
                @click="postPickerVisible = true" required />
            <van-button style="margin-top: 30px;" round block type="primary" native-type="submit" color="#FF724F">
                提 交
            </van-button>
        </van-form>
        <!-- 职位选择弹窗 -->
        <van-popup v-model:show="postPickerVisible" round position="bottom">
            <van-picker show-toolbar title="您的职位" :item-height="80" :columns="postOptions"
                @cancel="postPickerVisible = false" @confirm="onPostPickerConfirm" />
        </van-popup>
        <!-- 地址选择弹窗 -->
        <van-popup v-model:show="addressPickerVisible" round position="bottom">
            <van-picker show-toolbar title="您的地址" :item-height="80" :columns="city" @cancel="addressPickerVisible = false"
                @confirm="onAddressPickerConfirm" />
        </van-popup>
    </div>
</template>

<script>
import city from "@/assets/json/city";
import { sendMsgCode } from "@/assets/api/api_auth";
import { submitForm } from "@/assets/api/api_lenovo";
import { Notify, Dialog } from "vant";
import pattern from "@/utils/pattern";
export default {
    name: 'lenovo-enroll',
    components: {},
    data() {
        return {
            city,
            // 是否显示职位弹窗
            postPickerVisible: false,
            // 职位选项
            postOptions: ['机构类管理者', '机构类员工', '校领导', '学院管理者', '信息化部门', '教师'],
            // 是否显示地址弹窗
            addressPickerVisible: false,
            // 定时器
            timer: null,
            // 间隔
            interval: 60,
            // 正在发送验证码
            sending: false,
            // 表单
            form: {},
            // 表单验证
            rules: {
                name: [{ required: true, message: "姓名不能为空" }],
                mobile: [
                    {
                        pattern: pattern.mobile,
                        message: "手机号格式错误",
                    },
                ],
                // code: [{ required: true, message: "验证码不能为空" }],
                email: [
                    {
                        pattern: pattern.email,
                        message: "电子邮箱格式错误",
                    },
                ],
                address: [{ required: true, message: "地址不能为空" }],
                workUnit: [{ required: true, message: "工作单位不能为空" }],
                post: [{ required: true, message: "职位不能为空" }],
            },
        };
    },
    computed: {},
    watch: {},
    created() { },
    mounted() { },
    methods: {
        // 职位选择确认
        onPostPickerConfirm(value) {
            this.form.post = value;
            this.postPickerVisible = false;
        },
        // 地址选择确认
        onAddressPickerConfirm(value) {
            let address = "";
            value.forEach((item, index) => {
                address += item.text;
                if (index < value.length - 1) {
                    address += '-';
                }
            });
            this.form.address = address;
            this.addressPickerVisible = false;
        },
        // 发送验证码
        async sendCode() {
            const params = { mobile: this.form.mobile, type: 'MOBILE_FORM_VERIFY' };
            if (!params.mobile) {
                Notify({ type: "danger", message: "请输入手机号" });
                return;
            }
            if (!pattern.mobile.test(params.mobile)) {
                Notify({ type: "danger", message: "请填写正确的手机号" });
                return;
            }
            try {
                this.sending = true;
                const res = await sendMsgCode(params);
                this.sending = false;
                this.interval -= 1;
                this.countdown();
                if (res.errcode !== 0) {
                    Notify({ type: "danger", message: res.errmsg });
                    return;
                }
            } catch (error) {
                this.sending = false;
                Notify({ type: "danger", message: JSON.stringify(error) });
            }
        },
        // 倒计时
        countdown() {
            setTimeout(() => {
                if (this.interval === 1) {
                    this.interval = 60;
                    return;
                }
                this.interval -= 1;
                this.countdown();
            }, 1000);
        },
        // 提交
        async submit() {
            try {
                const params = { ...this.form };
                const res = await submitForm(params);
                if (res.errcode !== 0) {
                    Notify({ type: "danger", message: res.errmsg });
                    return;
                }
                Dialog.confirm({ title: '提交成功', message: '您的报名已提交', showCancelButton: false }).then(() => {
                    this.form = {};
                });
            } catch (error) {
                Notify({ type: "danger", message: JSON.stringify(error) });
            }
        }
    },
}
</script>
<style lang='less' scoped>
.lenovo-enroll-container {
    box-sizing: border-box;
    width: 100vw;
    padding: 24px;

    .van-cell {
        padding: 30px 0;
    }
}
</style>