<template>
  <div class="container">
    <!--<identify-view @onIdentify="onIdentify"></identify-view>-->
    <!--<toast ref="toast"></toast>-->
  </div>
</template>

<script>
import HOST from "../assets/api";
import { AppId, getHashParameters } from "../assets/utils/utils";
import api from "../assets/api/api_vote";

export default {
  name: "Auth",
  data() {
    return {};
  },
  components: {},
  methods: {
    isWeixin() {
      let ua = navigator.userAgent.toLowerCase();
      return ua.indexOf("micromessenger") != -1;
    },
    redirectUrl() {
      let requestParams = getHashParameters();
      let fromId = requestParams.fromId ? `&fromId=${requestParams.fromId}` : "";
      let applyId = requestParams.applyId ? `&applyId=${requestParams.applyId}` : "";
      let redirectUrl = encodeURIComponent(
        `${HOST}/login/wxLogin?web_id=${requestParams.web_id}${fromId}${applyId}`
      );
      let url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${AppId}&redirect_uri=${redirectUrl}&response_type=code&scope=snsapi_userinfo&state=STATE&connect_redirect=1#wechat_redirect`;
      window.location.href = url;
    },
    routeTo(query) {
      let { fromId, applyId, source } = query;
      if (!source) {
        source = "h5";
      }
      console.log(fromId, fromId === "CourseDetail");
      if (fromId === "CourseDetail") {
        this.$router.replace({ name: fromId, query: { itemId: applyId, source } });
      } else if (fromId === "TeacherDetail") {
        this.$router.replace({ name: fromId, query: { principalId: applyId, source } });
      } else {
        this.$router.replace({ name: fromId, query: { source } });
      }
    },
    onIdentify(result, msg) {
      if (!result) {
        this.$refs.toast.toast(msg);
        return;
      }
      this.login();
    },
    login() {
      let isWeixin = this.isWeixin();
      if (isWeixin) {
        this.redirectUrl();
      } else {
        let tokenId = this.$route.query.tokenId;
        let fromId = this.$route.query.fromId;
        let applyId = this.$route.query.applyId;
        console.log("tokenId", tokenId);
        api.getUserSession({ tokenId }).then((res) => {
          if (fromId) {
            this.routeTo(this.$route.query);
          } else {
            this.routeTo({ fromId: "Home" }, null);
          }
        });
      }
    },
  },
  mounted() {
    this.login();
  },
};
</script>

<style scoped>
.container {
  width: 750px;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
