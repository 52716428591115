<template>
  <div class="container">
    <div ref="form">
      <form-item v-for="(item, index) in formItemList" :key="index" :item="item" :form-index="index" @onInput="onFormInput" />
    </div>

    <div class="submit" @click="onSubmit">提交</div>
  </div>
</template>

<script>
  import FormItem from './formItem'
  import apiCommon from "../../../assets/api/api_vote";
  import { Notify } from 'vant'
  /**
   * 最美园长报名
   */
  export default {
    name: 'ZmyzAbout',
    components: {
      FormItem
    },
    data() {
      return {
        showPicker: false,
        formItemList: [
          {name: '推荐单位', type: 0, header: '单位信息'},
          {name: '推荐单位地区', type: 2, picker: 'area'},
          {name: '推荐单位联系人', type: 0},
          {name: '推荐单位电话', type: 0},
          {name: '邮箱', type: 0},
          {name: '微信号', type: 0},
          {name: '照片', type: 3, header: '参选人信息', maxCount: 1, uploadHint: '推荐尺寸345 x 345'},
          {name: '姓名', type: 0},
          {name: '性别', type: 2, picker: 'sex'},
          {name: '民族', type: 0},
          {name: '出生年月', type: 2, picker: 'date'},
          {name: '学历', type: 2, picker: 'education'},
          {name: '职务', type: 0},
          {name: '职称', type: 0},
          {name: '政治面貌', type: 0},
          {name: '工作单位', type: 0},
          {name: '联系电话', type: 0},
          {name: '微信号', type: 0},
          {name: '邮箱', type: 0},
          {name: '个人简介', type: 1, hint: '请输入（可复制材料在文本框粘贴）'},
          {name: '个人事迹', type: 1, hint: '最低600字（可复制材料在文本框粘贴）'},
          {name: '园所简介', type: 1, hint: '请输入（可复制材料在文本框粘贴）'},
          {name: '园所照片', type: 3, maxCount: 5, uploadHint: '最多上传5张'},
          {name: '推荐单位意见', type: 1, noCheck: true},
        ],
        uploadList: [
          'recommended', // 推荐单位
          'province', // 推荐单位地区
          'contacts', // 推荐单位联系人名称
          'phone', // 推荐单位联系电话
          'organizationEmail', // 推荐单位邮箱
          'sponsor_wechat',  // 推荐单位微信号
          'image_url', //参选人照片列表[{"url":""},{"url":""}]
          'name', //参选人姓名
          'sex', // 参选人性别 0：女  1：男
          'nation', // 参选人民族
          'birth', // 生日
          'education', // 参选人学历
          'duty', //参选人职务
          'technical_title', //参选人职称
          'politics_status', //参选人政治面貌
          'organization',  //参选人工作单位
          'mobile',  // 参选人手机号
          'wechat', // 参选人微信
          'email', //参选人邮箱
          'introduction', // 参选人个人简介
          'deed', // 参选人个人事迹
          'organizationDeed', // 参选人园所简介
          'organizationUrlList', // 参选人园所照片 [{"url":""},{"url":""}]
          'recommend_idea' // 推荐单位意见
        ],
        form: {},
        submitting: false
      }
    },
    methods: {
      onFormInput(content, index) {
        this.form[this.uploadList[index]] = content
      },
      onSubmit() {
        if (this.submitting) {
          return
        }

        console.log(this.form)

        // 检查是否填写
        for (let i = 0; i < this.uploadList.length; ++i) {
          if (this.formItemList[i].noCheck) {
            continue
          }
          if (this.form[this.uploadList[i]] || this.form[this.uploadList[i]] === 0) {
            // 正常
          } else {
            Notify({ type: 'danger', message: `请填写${this.formItemList[i].name}` });
            return
          }
        }

        this.submitting = true
        apiCommon.uploadApplyInfo(this.form).then(res => {
          if (res.errcode !== 0) {
            this.submitting = false
            Notify({ type: 'danger', message: res.errmsg });
            return
          }
          Notify({ type: 'success', message: '提交成功' });
        })
      }
    },
    mounted() {

    }
  }
</script>

<style scoped>

  .container {
    width: 750px;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .submit {
    width: 710px;
    height: 88px;
    border-radius: 20px;
    line-height: 88px;
    text-align: center;
    font-size: 36px;
    color: white;
    margin-top: 30px;
    margin-bottom: 30px;
    background: #e71e28;
  }
</style>
