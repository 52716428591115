<template>
  <div class="container">
    <img src="../../../../public/static/images/activity/sc-read/sc-read.png" alt="" />
    <div class="container-bottom">
      <div class="container-bottom-button button1" @click="handleAction">立即评测</div>
    </div>
  </div>
</template>

<script>
import apiActivity from "@/assets/api/api_activity";
export default {
  data() {
    return {};
  },
  methods: {
    /** 立即评测点击 */
    handleAction() {
      apiActivity.getSCReadMiniUrlScheme().then((res) => {
        location.href = res.data.openlink;
      });
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  padding-bottom: 118px;
  img {
    width: 100%;
  }
  &-bottom {
    background: #65c588;
    position: fixed;
    left: 0;
    bottom: 0;
    height: 120px;
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    z-index: 999;
    &-button {
      width: 40vw;
      height: 70px;
      font-size: 24px;
      border-radius: 35px;
    }
  }
}
.button1 {
  color: white;
  font-weight: bold;
  background: #f95d22;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>