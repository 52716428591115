<template>
    <div v-if="items.length" class="recommend-article-container">
        <div class="label">推荐阅读</div>
        <div class="list">
            <div class="item" v-for="item in items" :key="item.itemId" @click="handleClick(item)">
                {{ item.itemName }}
            </div>
        </div>
    </div>
</template>

<script>
import api from "../../assets/api/api_share"
export default {
    props: {
        userId: {
            type: String,
            default: "",
        },
        itemId: {
            type: Number,
        },
    },
    data() {
        return {
            items: [],
        }
    },
    mounted() {
        this.getRecommendList()
    },
    watch: {
        itemId() {
            this.getRecommendList()
        },
    },
    methods: {
        // 获取推荐列表
        getRecommendList() {
            const { userId, itemId } = this
            const params = { userId, itemId }
            api.getItemList(params).then((res) => {
                if (res.errcode !== 0) return
                this.items = res.data
            })
        },
        // 点击
        handleClick(item) {
            this.$router.push({ name: "ShareArticle", query: { itemId: item.itemId } })
        },
    },
}
</script>

<style lang="less" scoped>
.recommend-article-container {
    margin-top: 40px;
    padding-left: 30px;
    padding-right: 30px;
    width: 100%;
    box-sizing: border-box;

    .label {
        font-size: 36px;
        font-weight: 600;
        color: #00000090;
    }

    .list {
        padding-top: 20px;

        .item {
            padding: 36px 0;
            font-size: 32px;
            color: #333;
            border-bottom: 1px solid #f2f2f2;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }
}
</style>
