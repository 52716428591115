<template>
  <div class="container">
    <img class="header"
         src="https://educator.oss-cn-hangzhou.aliyuncs.com/static/share-top-banner.png"
         @click="downloadApk"/>
    <div :class="overflowHidden ? 'content-oh' : 'content'">
      <div class="rich-text" v-if="overflowHidden" v-html="detail.content" @click="imageChange($event)"></div>
      <div class="rich-text" v-if="!overflowHidden" v-html="detail.content" @click="imageChange($event)"></div>

      <img class="white-cover"
           v-if="overflowHidden"
           src="https://educator.oss-cn-hangzhou.aliyuncs.com/static/share-white-cover.png"/>
      <img class="share-more"
           v-if="overflowHidden"
           src="https://educator.oss-cn-hangzhou.aliyuncs.com/static/share-more.png"
           @click="showHintBox"/>

      <div :class="['bottom-open-app', overflowHidden ? 'jump-btn' : 'jump-btn-ext']" @click="downloadApk">
        打开光明教育家APP观看直播
      </div>
    </div>
    <div class="shadow" v-if="showShadow" @click="showShadow = false">
      <img class="hint" src="http://educator.oss-cn-hangzhou.aliyuncs.com/static/open-browser.png"/>
    </div>
    <hint-box content="是否打开光明教育家APP阅读原文?"
              ref="hb"
              @onCancel="overflowHidden = false"
              @onConfirm="downloadApk"/>
  </div>
</template>

<script>

  import {jump, tagAddStyle} from "../../utils/render";
  import api from "../../assets/api/api_share";
  import HintBox from "../../components/hintBox";
  import {AuthMixin} from "../../assets/utils/mixin";
  import { ImagePreview } from 'vant';

  export default {
    mixins: [AuthMixin],
    components: {HintBox},
    name: 'ShareEvent',

    data() {
      return {
        itemId: null,
        detail: {},
        showShadow: false,
        overflowHidden: true
      }
    },
    methods: {
      showHintBox() {
        this.$refs.hb.show()
      },
      downloadApk() {
        let param = {
          itemId: this.itemId,
        }
        let page = 'GuideDetail'
        jump(this, page, param, () => {
          this.showShadow = true
        })
      },
      imageChange(e){
        if (e.target.nodeName === 'IMG') {
          ImagePreview({
            images: [e.target.currentSrc],
            showIndex: false});
        }
      }
    },
    mounted() {
      this.itemId = this.$route.query.itemId
      api.getItem({itemId: this.itemId}).then(res => {
        let item = res.data
        let itemName = item.itemName
        if (res.data.properties) {
          res.data.properties = JSON.parse(res.data.properties)
        }
        if (item.properties.picList) {
          item.properties.picList = JSON.parse(item.properties.picList)
        }
        let content = tagAddStyle(res.data.properties.content)
        let reg = /(font-size\s*:)(\s*\d+\.?\d*)(px|pt)(\s*;?\s*)/g;
        content = content.replace(reg, (matched, capture1, capture2, capture3, capture4) => {
          return capture1 + capture2 * 1.8 + capture3 + capture4;
        })
        this.detail = {
          itemName,
          content
        }
        document.title = res.data.itemName

        this.initWxConfig({
          itemName: res.data.itemName,
          pic: 'https://educator.oss-cn-hangzhou.aliyuncs.com/static/app-icon.png',
          link: `share-article?itemId=${item.itemId}`
        })
      })
    }
  }
</script>

<style scoped>

  .container {
    width: 750px;;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .header {
    width: 750px;
    height: 80px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
  }

  .rich-text {
    width: 690px;
    overflow: hidden;
    padding-bottom: 204px;
    line-height: 60px;
  }

  .app-icon {
    width: 58px;
    height: 58px;
    border-radius: 29px;
    background: #f2f2f2;
  }

  .open-app {
    background: #d8201e;
    width: 120px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    color: white;
    font-size: 20px;
    border-radius: 20px;
  }

  .content-oh {
    width: 690px;
    min-height: calc(100vh - 80px);
    max-height: calc(200vh - 80px);
    overflow: hidden;
    flex-direction: column;
    margin-top: 80px;
    align-items: center;
    position: relative;
  }

  .content {
    width: 690px;
    overflow: hidden;
    flex-direction: column;
    margin-top: 80px;
    align-items: center;
  }

  .title {
    width: 690px;
    font-size: 36px;
    line-height: 48px;
    color: #313332;
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: center;
  }

  .divider {
    width: 690px;
    height: 2px;
    background: #f2f2f2;
  }

  .author-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 125px;
  }

  .follow {
    background: #d8201e;
    width: 105px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    color: white;
    font-size: 22px;
    border-radius: 20px;
  }

  .bottom-open-app {
    background: #d8201e;
    width: 575px;
    height: 84px;
    line-height: 84px;
    text-align: center;
    color: white;
    font-size: 28px;
    border-radius: 42px;
    z-index: 999;
  }

  .white-cover {
    width: 750px;
    height: 400px;
    position: absolute;
    left: 0;
    bottom: 0;
  }

  .jump-btn {
    position: absolute;
    left: 57.5px;
    bottom: 60px;
  }

  .jump-btn-ext {
    margin-left: 57.5px;
    margin-bottom: 60px;
  }

  .shadow {
    position: fixed;
    z-index: 99999;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.3);
  }

  .hint {
    width: 378px;
    height: 236px;
    position: fixed;
    z-index: 999;
    right: 20px;
    top: 0;
  }

  .share-more {
    width: 50px;
    height: 50px;
    position: absolute;
    bottom: 200px;
    left: 320px;
  }
</style>
