<template>
  <div class="bottom-tab">
    <div class="tab-item" @click.stop="onTabPress(index)" v-for="(item, index) in tabs" :key="index">
      <img class="tab-icon" :src="currentTab === index ? item.activeIcon : item.normalIcon" />
      <div :style="{color: currentTab === index ? themeColor : '#222'}">{{item.name}}</div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'BottomTab',
    props: ['currentTab', 'tabs', 'themeColor'],
    data () {
      return {

      }
    },
    methods: {
      onTabPress(index) {
        this.$emit('onBottomTabPress', index)
      }
    }
  }
</script>

<style scoped>

  .bottom-tab {
    width: 750px;
    height: 98px;
    background: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 9999;
  }

  .tab-icon {
    width: 42px;
    height: 42px;
    margin-bottom: 9px;
  }

  .tab-item {
    flex: 1;
    height: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 22px;
  }

  .tab-active {
    color: red;
  }

  .tab-normal {
    color: black;
  }
</style>
