<template>
  <div class="container">
    <img class="poster" src="https://pckapp.oss-cn-hangzhou.aliyuncs.com/app/static/download-pck.png" />

    <div class="download-btn" style="position: absolute; top: 1002px; left: 175px" @click="downloadApk">
      iOS下载
    </div>
    <div class="download-btn" style="position: absolute; top: 1118px; left: 175px" @click="downloadApk">
      Android下载
    </div>

    <div class="shadow" v-if="showShadow" @click="showShadow = false">
      <img class="hint" src="https://educator.oss-cn-hangzhou.aliyuncs.com/static/open-browser.png" />
    </div>
  </div>
</template>

<script>
import { jumpPck } from "../../utils/render";
export default {
  name: "EduDownload",

  data() {
    return {
      showShadow: false,
    };
  },
  methods: {
    downloadApk() {
      jumpPck(this, null, null, () => {
        this.showShadow = true;
      });
    },
  },
  mounted() {},
};
</script>

<style scoped>
.container {
  width: 750px;
  flex-direction: column;
  align-items: center;
}

.poster {
  width: 750px;
}

.shadow {
  position: fixed;
  z-index: 99999;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
}

.hint {
  width: 378px;
  height: 236px;
  position: fixed;
  z-index: 999;
  right: 20px;
  top: 0;
}

.download-btn {
  width: 400px;
  height: 84px;
  line-height: 84px;
  font-size: 40px;
  color: white;
  text-align: center;
  background: #2ed1a0;
  border-radius: 42px;
}
</style>
