<template>
  <div class="order-success-container">
    <div v-if="orderInfo.CampOrder?.payStatus === 'SUCCESS'" class="title">购买成功</div>
    <div v-else-if="orderInfo.CampOrder?.payStatus === 'CLOSED'" class="title">支付失败</div>
    <div v-else class="title">正在查询订单状态...</div>
    <div class="card order-info">
      <span class="desc">购买课程</span>
      <div class="course-info">
        <img :src="orderInfo.cover" alt="" />
        <div class="course-name">{{ orderInfo.name }}</div>
      </div>
      <div class="order-item">
        <div class="name">课程学习账号</div>
        <div class="right-wrapper">
          <div class="main-text">{{ orderInfo.CampOrder?.userMobile || "--" }}</div>
          <div class="tip">（请您务必使用购买时所填写的手机号进行登录）</div>
        </div>
      </div>
      <div class="order-item">
        <div class="name">课程学习平台</div>
        <div class="right-wrapper">
          <div class="main-text">光明教育家</div>
          <a class="download" @click="hanleDownload">前往下载</a>
        </div>
      </div>
    </div>
    <div v-if="orderInfo.CampOrder?.payStatus === 'SUCCESS'" class="tip-text">
      请您务必添加辅导老师的微信，享受专属学习群福利！
    </div>
    <div v-if="orderInfo.CampOrder?.payStatus === 'SUCCESS'" class="qrcode-wrapper">
      <img class="qrcode" :src="orderInfo.qrCode" alt="" />
    </div>
    <div v-if="orderInfo.CampOrder?.payStatus === 'CLOSED'" class="tip-text">
      如有疑问，请联系客服 17501656848
    </div>
  </div>
</template>

<script>
import { callApp } from "@/utils/callapp";
import { IS_IOS } from "@/utils/platform";
import { getOrderInfo } from "@/assets/api/api_shop";
import { Notify } from "vant";
export default {
  name: "TrainingCampOrderSuccess",
  components: {},
  data() {
    return {
      // 订单编号
      orderTradeNo: this.$route.query.orderTradeNo,
      // 订单信息
      orderInfo: {},
    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {
    this.queryOrder();
  },
  beforeCreate() {},
  beforeMount() {},
  beforeUpdate() {},
  updated() {},
  activated() {},
  methods: {
    // 下载按钮点击
    hanleDownload() {
      const path = IS_IOS ? "wx_conn" : "main/home";
      callApp(path);
    },
    // 查看订单详情
    async queryOrder() {
      const params = { orderTradeNo: this.orderTradeNo };
      const res = await getOrderInfo(params);
      if (res.errcode !== 0) {
        Notify({ type: "danger", message: "支付二维码生成失败" });
        return;
      }
      this.orderInfo = res.orderInfo;
      // 如果订单未完成或者未关闭 5秒钟查询一次
      if (
        this.orderInfo.CampOrder?.payStatus !== "SUCCESS" &&
        this.orderInfo.CampOrder?.payStatus !== "CLOSED"
      ) {
        setTimeout(() => {
          this.queryOrder();
        }, 5000);
      }
    },
  },
};
</script>
<style lang='less' scoped>
.order-success-container {
  --theme-color: #19b157;
  box-sizing: border-box;
  background-color: #99999910;
  width: 100vw;
  height: 100vh;
  padding: 32px;
  font-size: 24px;
  .title {
    width: 100%;
    text-align: center;
    font-size: 38px;
    font-weight: bold;
  }
  .card {
    background-color: white;
    padding: 24px;
    border-radius: 12px;
  }
  .order-info {
    margin-top: 40px;
    color: #a6a6a6;
    .course-info {
      margin-top: 16px;
      display: flex;
      img {
        width: 120px;
        height: 120px;
        background-color: #989898;
        border-radius: 4px;
      }
      .course-name {
        flex: 1;
        margin-left: 26px;
        color: black;
        font-size: 28px;
        line-height: 1.5;
        font-weight: bold;
      }
    }
    .order-item {
      margin-top: 48px;
      display: flex;
      justify-content: space-between;
      .right-wrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        text-align: right;
        .main-text {
          color: black;
        }
        .tip {
          margin-top: 10px;
          color: #d43030;
          font-size: 20px;
        }
        .download {
          margin-top: 20px;
          color: blue;
        }
      }
    }
  }
  .tip-text {
    margin-top: 80px;
    width: 100%;
    color: #d43030;
    font-size: 28px;
    font-weight: bold;
    text-align: center;
  }
  .qrcode-wrapper {
    margin-top: 60px;
    display: flex;
    justify-content: center;
    .qrcode {
      width: 300px;
      height: 300px;
    }
  }
}
</style>