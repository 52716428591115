<template>
  <div style="display: flex; flex-direction: column; align-items: center">
    <canvas id="s-canvas" :width="contentWidth" :height="contentHeight"></canvas>
    <!--<canvas id="h-canvas" v-if="mode >= 2" :width="200" :height="50"></canvas>-->
  </div>
</template>
<script>
  import {getTextCodeTable} from "../utils/secure";

  export default {
    name: 'SIdentify',
    props: {
      // 图片验证码
      // identifyCode: {
      //   type: String,
      //   default: ''
      // },
      // 字体最小值
      // fontSizeMin: {
      //   type: Number,
      //   default: 36
      // },
      // // 字体最大值
      // fontSizeMax: {
      //   type: Number,
      //   default: 40
      // },
      // 背景颜色色值最小值，最小为0
      backgroundColorMin: {
        type: Number,
        default: 180
      },
      // 背景颜色色值最大值，最大为255
      backgroundColorMax: {
        type: Number,
        default: 220
      },
      // 字体颜色色值最小值，最小为0
      colorMin: {
        type: Number,
        default: 50
      },
      // 字体颜色色值最大值，最大为255
      colorMax: {
        type: Number,
        default: 180
      },
      // 干扰线颜色色值最小值，最小为0
      lineColorMin: {
        type: Number,
        default: 50
      },
      // 干扰线颜色色值最大值，最大为255
      lineColorMax: {
        type: Number,
        default: 200
      },
      // 干扰点颜色色值最小值，最小为0
      dotColorMin: {
        type: Number,
        default: 100
      },
      // 干扰点颜色色值最大值，最大为255
      dotColorMax: {
        type: Number,
        default: 180
      },
      // 画布宽度
      contentWidth: {
        type: Number,
        default: 250
      },
      // 画布高度
      contentHeight: {
        type: Number,
        default: 80
      }
    },
    data() {
      return {
        mode: -1,
        identifyCode: '',
        makeCode: '',
        calResult: 0,
        degMin: -45,
        degMax: 45,
        fontSizeMin: 32,
        fontSizeMax: 36
      }
    },
    mounted () {
      try {
        this.makeIdentifyCodeRandom()
        this.drawPic()
      } catch (e) {
        console.log(e);
      }
    },
    methods: {
      /**
       * 生成一个随机数
       * @param {number} min 随机数最小值
       * @param {number} max 随机数最大值
       */
      randomNum (min, max) {
        return Math.floor(Math.random() * (max - min) + min)
      },

      /**
       * 生成一个随机的颜色
       * @param {number} min 随机数最小值
       * @param {number} max 随机数最大值
       */
      randomColor (min, max) {
        const r = this.randomNum(min, max)
        const g = this.randomNum(min, max)
        const b = this.randomNum(min, max)
        return 'rgb(' + r + ',' + g + ',' + b + ')'
      },

      /**
       * 绘制图片验证码
       */
      drawPic () {
        let canvas = document.querySelector('#s-canvas')
        let ctx = canvas.getContext('2d')
        ctx.textBaseline = 'bottom'
        // 绘制背景
        ctx.fillStyle = this.randomColor(this.backgroundColorMin, this.backgroundColorMax)
        ctx.fillRect(0, 0, this.contentWidth, this.contentHeight)
        // 绘制干扰点
        this.drawDot(ctx)
        // 绘制验证码
        for (let i = 0; i < this.identifyCode.length; i++) {
          this.drawText(ctx, this.identifyCode[i], i)
        }
        // 绘制干扰线
        this.drawLine(ctx)
      },

      /**
       * 绘制文本单个验证码
       * @param {object} ctx canvas上下文对象
       * @param {string} txt 单个验证码
       * @param {number} i 单个验证码序号
       */
      drawText (ctx, txt, i) {
        ctx.fillStyle = this.randomColor(this.colorMin, this.colorMax)
        ctx.font = this.randomNum(this.fontSizeMin, this.fontSizeMax) + 'px SimHei'
        let x = (i + 1) * (this.contentWidth / (this.identifyCode.length + 1))
        let y = this.randomNum(this.fontSizeMax + 5, this.fontSizeMax + 25)
        let deg = this.randomNum(this.degMin, this.degMax)
        // 修改坐标原点和旋转角度
        ctx.translate(x, y)
        ctx.rotate(deg * Math.PI / 180)
        ctx.fillText(txt, 0, 0)
        // 恢复坐标原点和旋转角度
        ctx.rotate(-deg * Math.PI / 180)
        ctx.translate(-x, -y)
      },

      /**
       * 绘制干扰线
       * @param {object} ctx canvas上下文对象
       */
      drawLine (ctx) {
        for (let i = 0; i < 8; i++) {
          ctx.strokeStyle = this.randomColor(this.lineColorMin, this.lineColorMax)
          ctx.beginPath()
          ctx.moveTo(this.randomNum(0, this.contentWidth), this.randomNum(0, this.contentHeight))
          ctx.lineTo(this.randomNum(0, this.contentWidth), this.randomNum(0, this.contentHeight))
          ctx.stroke()
        }
      },

      /**
       * 绘制干扰点
       * @param {object} ctx canvas上下文对象
       */
      drawDot (ctx) {
        for (let i = 0; i < 60; i++) {
          ctx.fillStyle = this.randomColor(0, 255)
          ctx.beginPath()
          ctx.arc(this.randomNum(0, this.contentWidth), this.randomNum(0, this.contentHeight), 1, 0, 2 * Math.PI)
          ctx.fill()
        }
      },
      /**
       * 随机生成一个0~9之间的数
       */
      randomNum029 () {
        return Math.floor(Math.random() * 10)
      },

      /**
       * 随机生成一个字母
       * @param {boolean} isUupper 生成大写字母
       */
      randomAlphabet ({ isUupper = false } = {}) {
        // a的Unicode值为97,z的Unicode值为123
        const alphabet = String.fromCharCode(Math.floor(Math.random() * 25) + 97)
        if (!isUupper) {
          return alphabet
        } else {
          return alphabet.toUpperCase()
        }
      },

      makeIdentifyCodeRandom() {
        let mode = Math.floor(Math.random() * 2)
        this.mode = mode
        if (mode === 0) {
          this.degMin = -45
          this.degMax = 45
          this.makeIdentifyCode()
        } else if (mode === 1) {
          this.degMin = -45
          this.degMax = 45
          this.makeIdentifyCodeV2()
        } else {
          // this.degMin = -90
          // this.degMax = 90
          // this.makeIdentifyCodeV3()
        }
      },

      makeIdentifyCodeV3() {
        let textCodeTable = getTextCodeTable()
        let t1 = Math.floor(Math.random() * textCodeTable.length)
        let t2 = Math.floor(Math.random() * textCodeTable.length)
        let t3 = Math.floor(Math.random() * textCodeTable.length)
        let t4 = Math.floor(Math.random() * textCodeTable.length)
        let t5 = Math.floor(Math.random() * textCodeTable.length)
        let mkCode = textCodeTable[t1] + textCodeTable[t2] + textCodeTable[t3] + textCodeTable[t4] + textCodeTable[t5]

        // let c1 = Math.floor(Math.random() * 5) + 1
        // let c2 = Math.floor(Math.random() * 5) + 1
        this.identifyCode = mkCode

        // let canvas = document.querySelector('#s-canvas')
        // let ctx = canvas.getContext('2d')
        // ctx.textBaseline = 'bottom'
        // ctx.font = '26px'
        // ctx.fillText(`请填写左起第${c1}和第${c2}的汉字`, 0, 0)
        this.$emit('refreshCode', mkCode)
      },

      makeIdentifyCodeV2() {
        let mkCode = ''
        let idCode = ''
        let left = 0
        let right = 0
        let mode = Math.floor(Math.random() * 3)
        if (mode === 0) {
          left = Math.floor(Math.random() * 99)
          right = Math.floor(Math.random() * 9)
          mkCode += left
          mkCode += '\u52a0'
          mkCode += right
          this.identifyCode = mkCode
          idCode = left + right
        } else if (mode === 1) {
          left = Math.floor(Math.random() * 99)
          right = Math.floor(Math.random() * 9)
          mkCode += left
          mkCode += '\u51cf'
          mkCode += right
          this.identifyCode = mkCode
          idCode = left - right
        } else {
          left = Math.floor(Math.random() * 9)
          right = Math.floor(Math.random() * 9)
          mkCode += left
          mkCode += '\u4e58'
          mkCode += right
          this.identifyCode = mkCode
          idCode = left * right
        }
        this.$emit('refreshCode', idCode)
      },

      /**
       * 生成图片验证码
       * @param {number} length 图片验证码位数
       * @param {boolean} typeMix 数字和字母混合
       * @param {string} pureNumber 纯数字('number')或者字母('alphabet')
       * @param {boolean} randomTypeLen 随机生成类型个数组合
       * @param {boolean} capsLookMix 字母大小写混合
       * @param {number} numLength 混合类型下的数字个数
       * @param {number} uupperLength 大写字母的个数
       */
      makeIdentifyCode ({ length = 4, typeMix = true, pureNumber = 'alphabet', randomTypeLen = false, capsLookMix = true, numLength = 2, uupperLength = 1 } = {}) {
        this.makeCode = ''
        // 数字和字母混合
        if (typeMix) {
          // 随机生成类型个数组合
          if (randomTypeLen) {
            // 字母大小写混合
            if (capsLookMix) {
              const numLength = Math.floor(Math.random() * length) + 1
              const uupperLength = numLength === length ? 0 : Math.floor(Math.random() * (length - numLength)) + 1
              for (let i = 0; i < numLength; i++) {
                this.makeCode += this.randomNum029()
              }
              for (let i = 0; i < uupperLength; i++) {
                this.makeCode += this.randomAlphabet({ isUupper: true })
              }
              if (numLength + uupperLength < length) {
                for (let i = 0; i < length - numLength - uupperLength; i++) {
                  this.makeCode += this.randomAlphabet()
                }
              }
            } else {
              const numLength = Math.floor(Math.random() * length) + 1
              for (let i = 0; i < numLength; i++) {
                this.makeCode += this.randomNum029()
              }
              if (numLength < length) {
                for (let i = 0; i < length - numLength; i++) {
                  this.makeCode += this.randomAlphabet()
                }
              }
            }
          } else {
            // 字母大小写混合
            if (capsLookMix) {
              const tempNumLength = numLength < 0 ? 2 : numLength > length ? 2 : numLength
              const tempUupperLength = uupperLength < 0 ? 1 : uupperLength > length - tempNumLength ? 1 : uupperLength
              for (let i = 0; i < tempNumLength; i++) {
                this.makeCode += this.randomNum029()
              }
              for (let i = 0; i < tempUupperLength; i++) {
                this.makeCode += this.randomAlphabet({ isUupper: true })
              }
              if (tempNumLength + tempUupperLength < length) {
                for (let i = 0; i < length - tempNumLength - tempUupperLength; i++) {
                  this.makeCode += this.randomAlphabet()
                }
              }
            } else {
              const tempNumLength = numLength < 0 ? 2 : numLength > length ? 2 : numLength
              for (let i = 0; i < tempNumLength; i++) {
                this.makeCode += this.randomNum029()
              }
              if (tempNumLength < length) {
                for (let i = 0; i < length - tempNumLength; i++) {
                  this.makeCode += this.randomAlphabet()
                }
              }
            }
          }
        } else {
          // 纯数字('number')
          if (pureNumber === 'number') {
            for (let i = 0; i < length; i++) {
              this.makeCode += this.randomNum029()
            }
          }
          // 纯字母('alphabet')
          if (pureNumber === 'alphabet') {
            // 字母大小写混合
            if (capsLookMix) {
              const tempUupperLength = uupperLength < 0 ? 1 : uupperLength > length ? 1 : uupperLength
              for (let i = 0; i < tempUupperLength; i++) {
                this.makeCode += this.randomAlphabet({ isUupper: true })
              }
              if (tempUupperLength < length) {
                for (let i = 0; i < length - tempUupperLength; i++) {
                  this.makeCode += this.randomAlphabet()
                }
              }
            } else {
              for (let i = 0; i < length; i++) {
                this.makeCode += this.randomAlphabet()
              }
            }
          }
        }
        let sCode = this.shuffle(this.makeCode)
        this.identifyCode = sCode
        this.$emit('refreshCode', sCode)
      },

      /**
       * 图片验证码随机排序
       * @param {string} str 图片验证码
       */
      shuffle (str) {
        this.identifyCode = [...str].sort(() => Math.random() - 0.5).join('')
        return this.identifyCode
      },

      /**
       * 更换图片验证码
       */
      refreshCode () {
        this.makeIdentifyCodeRandom({ randomTypeLen: true })
        this.drawPic()
      },
    },
    // watch: {
    //   identifyCode () {
    //     this.drawPic()
    //   }
    // }
  }
</script>
