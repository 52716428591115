<template>
  <div class="marquee-wrap">
    <div class="scroll">
      <p class="marquee">{{text}}</p>
      <!--<p class="copy"></p>-->
    </div>
    <p class="getWidth">{{text}}</p>
  </div>
</template>

<script>
  export default {
    name: 'marquee',
    props: ['val'],
    data () {
      return {
        timer: null,
        delay: null,
        text: ''
      }
    },
    created () {
      // let timer = setTimeout(() => {
      //   this.move()
      //   clearTimeout(timer)
      // }, 3000)
    },
    watch: {
      val: {
        immediate: true,
        handler(newVal){
          if (newVal) {
            this.text = newVal
            clearInterval(this.timer)
            clearTimeout(this.delay)
            this.delay = setTimeout(() => {
              this.move(newVal)
              clearTimeout(this.delay)
            }, 1000)
            this.$nextTick(() => {
              let mq = document.querySelector('.marquee-wrap')
              let maxWidth = mq.clientWidth
              let width = document.querySelector('.getWidth').scrollWidth
              let scroll = document.querySelector('.scroll')
              scroll.style.transform = 'translateX(0px)'
              if (width <= maxWidth) {
                mq.style.justifyContent = 'center'
                scroll.style.justifyContent = 'center'
              } else {
                mq.style.justifyContent = 'flex-start'
                scroll.style.justifyContent = 'flex-start'
              }
            })
          }
        }
      }
    },
    mounted () {
    },
    methods: {
      move () {
        let mq = document.querySelector('.marquee-wrap')
        let maxWidth = mq.clientWidth
        let width = document.querySelector('.getWidth').scrollWidth
        let scroll = document.querySelector('.scroll')
        if (width <= maxWidth) {
          return
        }
        // let copy = document.querySelector('.copy')
        // copy.innerText = this.text
        let distance = 0
        this.timer = setInterval(() => {
          distance -= 1
          if (-distance >= width) {
            distance = 16
          }
          scroll.style.transform = 'translateX(' + distance + 'px)'
        }, 20)
      }
    },
    beforeUnmount () {
      clearInterval(this.timer)
    }
  }
</script>

<style scoped>
  .marquee-wrap {
    width: 100%;
    overflow: hidden;
    position: relative;
    display: flex;
    justify-content: center;
  }
  .marquee{
    /*margin-right: 36px;*/
  }
  p {
    word-break:keep-all;
    white-space: nowrap;
    font-size: 36px;
    font-family: "微软雅黑 Light";
  }
  .scroll {
    display: flex;
    justify-content: center;
  }
  .getWidth {
    word-break:keep-all;
    white-space:nowrap;
    position: absolute;
    opacity: 0;
    top: 0;
  }
</style>

