<template>
  <div class="container">
    <img src="../../../../public/static/images/activity/dgls/page1.png" alt="" class="container-image" />
    <div class="container-bottom">
      <div class="container-bottom-button button1" @click="handleGotoApply">在线申报</div>
      <div
        data-clipboard-text="jyjzz@gmrbjyjzz.com"
        class="container-bottom-button button1"
        @click="handleLinkTap"
      >
        复制邮箱地址
      </div>
    </div>
  </div>
</template>

<script>
/**
 * "寻找大国良师"公益活动
 */
import Clipboard from "clipboard";
import { Notify } from "vant";
export default {
  created() {
    const clipboard = new Clipboard(".button1");
    clipboard.on("success", () => {
      Notify({ type: "success", message: "邮箱地址复制成功" });
    });
  },
  methods: {
    handleGotoApply() {
      this.$router.push("/activity/dgls/apply");
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  &-image {
    width: 100%;
    flex: 1;
  }
  &-bottom {
    background: #fdfcf0;
    position: fixed;
    left: 0;
    bottom: 0;
    height: 160px;
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    z-index: 999;
    box-shadow: 0 -5px 10px -1px #f3f1e5;
    &-button {
      width: 40vw;
      height: 70px;
      font-size: 24px;
    }
  }
}
.button1 {
  color: #525b89;
  font-weight: bold;
  background: #fae8e5;
  display: flex;
  justify-content: center;
  align-items: center;
}
.button2 {
  color: rgb(79, 94, 119);
  display: flex;
  justify-content: center;
  align-items: center;
  border: 3px solid rgb(79, 94, 119);
  box-sizing: border-box;
}
</style>