<template>
  <div class="container">
    <img class="img" alt="" src="../../../../public/static/images/activity/little-reporter/banner1.jpg" />
    <div class="form">
      <van-form @submit="submit" v-model="form" label-width="120px">
        <van-field v-model="form.name" label="姓名" placeholder="请输入姓名" :rules="rules.name" />
        <van-field name="radio" label="性别" :rules="rules.sex">
          <template #input>
            <van-radio-group v-model="form.sex" direction="horizontal">
              <van-radio name="男" icon-size="32px">男</van-radio>
              <van-radio name="女" icon-size="32px">女</van-radio>
            </van-radio-group>
          </template>
        </van-field>
        <van-field
          v-model="form.birthday"
          label="出生日期"
          placeholder="请选择出生日期"
          clickable
          readonly
          :rules="rules.birthday"
          @click="datePickerVisible = true"
        />
        <van-field
          v-model="form.mobile"
          label="联系电话"
          placeholder="请输入联系电话"
          :rules="rules.mobile"
        />
        <van-field
          v-model="form.nation"
          label="民族"
          placeholder="请选择民族"
          :rules="rules.nation"
          readonly
          clickable
          @click="nationTap"
        />
        <van-field
          v-model="form.school"
          label="学校名称"
          placeholder="请输入学校名称"
          :rules="rules.school"
        />
        <van-field
          v-model="form.schoolAdd"
          label="学校地址"
          placeholder="请输入学校地址"
          :rules="rules.schoolAdd"
        />
        <van-field
          v-model="form.area"
          label="所在区域"
          placeholder="请选择所在区域"
          :rules="rules.area"
          readonly
          clearable
          @click="cityTap"
        />
        <van-field
          v-model="form.contactAdd"
          label="联系地址"
          placeholder="请输入联系地址"
          :rules="rules.contactAdd"
        />
        <van-field
          v-model="form.organizationName"
          label="推荐机构"
          placeholder="请选择推荐机构"
          :rules="rules.schoolId"
          readonly
          clickable
          @click="organizationTap"
        />
        <van-button style="margin-top: 40px" round block type="primary" color="#EB002A" native-type="submit">
          申 请
        </van-button>
      </van-form>
    </div>
    <van-popup v-model:show="datePickerVisible" round position="bottom">
      <van-datetime-picker
        type="year-month"
        title="出生日期"
        :item-height="60"
        :min-date="new Date(1949, 0, 1)"
        :max-date="new Date(2022, 3, 1)"
        :formatter="formatter"
        @cancel="datePickerVisible = false"
        @confirm="onDatePickerConfirm"
      />
    </van-popup>
    <van-popup v-model:show="pickerVisible" round position="bottom">
      <van-picker
        show-toolbar
        :title="pickerData.title"
        :item-height="60"
        :columns="pickerData.columns"
        :columns-field-names="pickerData.customFieldName"
        @cancel="pickerVisible = false"
        @confirm="onPickerConfirm"
      />
    </van-popup>
  </div>
</template>

<script>
import apiActivity from "@/assets/api/api_activity";
import { Notify, Dialog } from "vant";
import nation from "@/assets/json/nation";
import city from "@/assets/json/city";
export default {
  data() {
    return {
      pickerData: {
        title: "",
        columns: [],
        customFieldName: {},
      },
      form: {},
      rules: {
        name: [{ required: true, message: "姓名不能为空" }],
        sex: [{ required: true, message: "请选择性别" }],
        birthday: [{ required: true, message: "请选择出生日期" }],
        mobile: [
          {
            pattern: /^1(3\d|4[5-9]|5[0-35-9]|6[567]|7[0-8]|8\d|9[0-35-9])\d{8}$/,
            message: "手机号格式错误",
          },
        ],
        nation: [{ required: true, message: "民族不能为空" }],
        school: [{ required: true, message: "学校名称不能为空" }],
        schoolAdd: [{ required: true, message: "学校地址不能为空" }],
        contactAdd: [{ required: true, message: "联系地址不能为空" }],
        area: [{ required: true, message: "区域不能为空" }],
        schoolId: [{ required: true, message: "请选择推荐机构" }],
      },
      datePickerVisible: false,
      pickerVisible: false,
      // 机构列表
      organizationList: [],
    };
  },
  mounted() {
    this.getOrganizationList();
  },
  methods: {
    // 民族选择
    nationTap() {
      this.pickerData = { title: "民族", columns: nation, customFieldName: {} };
      this.pickerVisible = true;
    },
    // 区域选择
    cityTap() {
      this.pickerData = { title: "所在区域", columns: city, customFieldName: {} };
      this.pickerVisible = true;
    },
    // 机构选择
    organizationTap() {
      this.pickerData = {
        title: "推荐机构",
        columns: this.organizationList,
        customFieldName: { text: "name", value: "schoolId" },
      };
      this.pickerVisible = true;
    },
    // 获取机构列表
    async getOrganizationList() {
      const params = {};
      const res = await apiActivity.getLROrganizationList(params);
      if (res.errcode !== 0) {
        Notify({ type: "danger", message: res.errmsg });
        return;
      }
      this.organizationList = res.data;
    },
    async submit() {
      const res = await apiActivity.submitLittleReporterForm(this.form);
      if (res.errcode !== 0) {
        Notify({ type: "danger", message: res.errmsg });
        return;
      }
      Dialog.alert({
        title: "提示",
        message: "您的申请已提交",
      }).then(() => {
        this.form = {};
      });
    },
    onDatePickerConfirm(date) {
      this.form.birthday = `${date.getFullYear()}年${date.getMonth() + 1}月`;
      this.datePickerVisible = false;
    },
    onPickerConfirm(selected) {
      if (this.pickerData.title === "民族") {
        this.form.nation = selected;
      } else if (this.pickerData.title === "所在区域") {
        this.form.area = `${selected[0].text}-${selected[1].text}-${selected[2].text}`;
      } else if (this.pickerData.title === "推荐机构") {
        this.form.schoolId = selected.schoolId;
        this.form.organizationName = selected.name;
      }
      this.pickerVisible = false;
    },
    // 年月格式化
    formatter(type, val) {
      if (type === "year") {
        return `${val}年`;
      } else if (type === "month") {
        return `${val}月`;
      }
      return val;
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  box-sizing: border-box;
  width: 750px;
  padding: 0 32px;
  .img {
    margin-top: 20px;
    width: 100%;
    height: 236px;
    border-radius: 16px;
    background-color: #f5f5f5;
  }
  .form {
    width: 100%;
    padding: 16px 0;
  }
}
.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  &-p {
    padding: 14px 0;
  }
  &-between {
    justify-content: space-between;
  }
}
.form-border {
  border-bottom: 1px solid #ebedf0;
}
.form-text-normal {
  font-size: 28px;
  color: #646566;
}
.form-item {
  padding-top: 20px;
}
.form-label {
  font-size: 28px;
  font-weight: bold;
  line-height: 30px;
  padding: 20px 0px;
}
</style>