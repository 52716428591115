import {XMLY, XMplayer} from '@xmly-fem/web-jssdk'
import api from "../assets/api/api_xmly"
import storage from "./storage";
import {uuid} from "./render";
import storageAction from "./storageAction";

// const TEST_KEY = 'b617866c20482d133d5de66fceb37da3'
const PRO_KEY = 'e9f226e4e91209d3dad80bfb48a0f98a'

let init = false

let xmlyPlayer = {}
export let xmly = null

export function initXMLYService(thirdToken) {
  if (!init) {
    let cfg = {
      app_key: PRO_KEY,
      get_access_token: () => {
        // if (storage.getText('__xst_xmly_token__')) {
        //   return storage.getText('__xst_xmly_token__')
        // }
        let deviceId = storageAction.getUUID()
        let param = {
          deviceId: deviceId,
          thirdToken: thirdToken
        }
        return api.getXimalayaToken(param).then(res => {
          storage.setText('__xst_xmly_token__', res.data.accessToken)
          return res.data.accessToken
        })
      },
      debug: true
    }

    xmly = new XMLY(cfg)
  }
  init = true
}

export function initXMLYPlayer(thirdToken) {
  let cfg = {
    app_key: PRO_KEY,
    get_access_token: () => {
      // if (storage.getText('__xst_xmly_token__')) {
      //   return storage.getText('__xst_xmly_token__')
      // }
      let deviceId = storage.getText('__xst_uuid__')
      if (!deviceId) {
        deviceId = uuid()
        storage.setText('__xst_uuid__', deviceId)
      }
      let param = {
        deviceId: deviceId,
        thirdToken: thirdToken
      }
      return api.getXimalayaToken(param).then(res => {
        storage.setText('__xst_xmly_token__', res.data.accessToken)
        return res.data.accessToken
      })
    },
    debug: true
  }

  xmlyPlayer = new XMplayer({
    ...cfg,
    playlist: []
  })

  return xmlyPlayer
}

