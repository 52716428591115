<template>
  <div class="container">
    <img class="header"
         src="https://educator.oss-cn-hangzhou.aliyuncs.com/static/share-top-banner.png"
         @click="downloadApk" />
    <div :class="overflowHidden ? 'content-oh' : 'content'">
      <div class="album-info-container">
        <div style="width: 690px; display: flex; flex-direction: row; align-items: center; margin-top: 30px">
          <img style="width: 160px; height: 160px; border-radius: 8px" :src="detail.cover_url_large" />
          <div style="width: 500px; display: flex; flex-direction: column; margin-left: 30px">
            <div class="album-title">{{detail.album_title}}</div>
            <div style="font-size: 22px; color: #999999; margin-top: 30px">以下内容来自喜马拉雅</div>
          </div>
        </div>
        <div :class="expand ? 'album-intro-expand' : 'album-intro'">{{detail.album_intro}}</div>
        <div style="font-size: 28px; color: #7dcdf2; margin-bottom: 30px; margin-top: 10px" @click="expandIntro">{{expand ? '收起': '简介'}}</div>
      </div>
      <!--专辑列表-->
      <div class="audio-container" v-for="(item, index) in audioList" :key="index" @click="downloadApk">
        <div class="audio-index">{{index + 1}}</div>
        <div class="audio-content-container">
          <div class="audio-title">{{item.track_title}}</div>
          <div class="audio-time-container">
            <img style="width: 28px ;height: 28px"
                 src="https://educator.oss-cn-hangzhou.aliyuncs.com/static/xmly-time.png" />
            <div style="font-size: 24px; color: #999; margin-left: 12px">{{item.duration}}</div>
          </div>
        </div>
        <img style="width: 36px; height: 36px; margin-right: 30px"
             src="https://educator.oss-cn-hangzhou.aliyuncs.com/static/xmly-track-play.png" />
      </div>

      <img class="white-cover"
           v-if="overflowHidden"
           src="https://educator.oss-cn-hangzhou.aliyuncs.com/static/share-white-cover.png"/>
      <img class="share-more"
           v-if="overflowHidden"
           src="https://educator.oss-cn-hangzhou.aliyuncs.com/static/share-more.png"
           @click="downloadApk" />

      <div :class="['bottom-open-app', overflowHidden ? 'jump-btn' : 'jump-btn-ext']" @click="downloadApk">打开光明教育家APP听完整专辑</div>
    </div>
    <div class="shadow" v-if="showShadow" @click="showShadow = false">
      <img class="hint" src="http://educator.oss-cn-hangzhou.aliyuncs.com/static/open-browser.png"/>
    </div>
    <fixed-bottom @download="downloadApk" />
  </div>
</template>

<script>

  import {formatSeconds, jump} from "@/utils/render";
  import api from "@/assets/api/api_xmly";
  import {AuthMixin} from "@/assets/utils/mixin";
  import FixedBottom from '@/components/share/fixedBottom'

  export default {
    mixins: [AuthMixin],
    name: 'ShareAlbum',
    components: {FixedBottom},
    data() {
      return {
        itemId: null,
        detail: {},
        audioList: [],
        showShadow: false,
        overflowHidden: true,
        expand: false
      }
    },
    methods: {
      expandIntro() {
        this.expand = !this.expand
      },
      downloadApk() {
        let param = {
          itemId: this.itemId,
        }
        jump(this, 'AudioBookDetailVerXmly', param, () => {
          this.showShadow = true
        })
      },
      getDetail() {
        let param = {
          ids: this.itemId
        }
        api.getAlbumsInfo(param).then(res => {
          if (res.errcode !== 0) {
            alert(res.errmsg)
            return
          }

          let albumDetail = res.data[0]
          this.detail = albumDetail
          document.title = albumDetail.album_title

          this.initWxConfig({
            itemName: albumDetail.album_title,
            pic: 'https://educator.oss-cn-hangzhou.aliyuncs.com/static/app-icon.png',
            link: `share-album?itemId=${albumDetail.itemId}`
          })
        })
      },
      getAudioList() {
        let param = {
          albumId: this.itemId,
          page: 1,
          count: 10,
          sort: 'asc'
        }
        api.getXimalayaAlbumsBrowse(param).then(res => {
          if (res.errcode === 0) {
            let results = res.data.tracks || []

            results.forEach(item => {
              item.duration = formatSeconds(item.duration)
            })
            this.audioList = results
          }
        })
      }
    },
    mounted() {
      this.itemId = this.$route.query.itemId
      this.getDetail()
      this.getAudioList()
    }
  }
</script>

<style scoped>

  .container {
    width: 750px;;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 120px;
  }

  .header {
    width: 750px;
    height: 80px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
  }

  .content-oh {
    width: 750px;
    min-height: calc(100vh - 80px);
    max-height: calc(150vh - 80px);
    overflow: hidden;
    flex-direction: column;
    margin-top: 80px;
    position: relative;
    background-color: #f2f2f2;
  }

  .content {
    width: 750px;
    flex-direction: column;
    margin-top: 80px;
    align-items: center;
    background-color: #f2f2f2;
  }

  .bottom-open-app {
    background: #d8201e;
    width: 575px;
    height: 84px;
    line-height: 84px;
    text-align: center;
    color: white;
    font-size: 28px;
    border-radius: 42px;
    z-index: 999;
    margin-top: 30px;
  }

  .white-cover {
    width: 750px;
    height: 400px;
    position: absolute;
    left: 0;
    bottom: 0;
  }

  .jump-btn {
    position: absolute;
    left: 87.5px;
    bottom: 60px;
  }

  .jump-btn-ext {
    margin-left: 87.5px;
    margin-bottom: 60px;
  }

  .shadow {
    position: fixed;
    z-index: 99999;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(0,0,0,0.3);
  }

  .hint {
    width: 378px;
    height: 236px;
    position: fixed;
    z-index: 999;
    right: 20px;
    top: 0;
  }

  .share-more {
    width: 50px;
    height: 50px;
    position: absolute;
    bottom: 200px;
    left: 350px;
  }

  .album-info-container {
    width: 750px;
    display: flex;
    flex-direction: column;
    background-image: linear-gradient(180deg,
    #5a89a9 0%,
    #2c3e50 100%),
    linear-gradient(
      #213375,
      #213375);
    background-blend-mode: normal,
    normal;
    padding: 0 30px;
    box-sizing: border-box;
  }

  .album-title {
    width: 500px;
    font-size: 36px;
    line-height: 40px;
    height: 80px;
    color: white;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .album-intro {
    width: 690px;
    font-size: 24px;
    line-height: 36px;
    margin-top: 22px;
    color: white;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .album-intro-expand {
    width: 690px;
    font-size: 24px;
    line-height: 36px;
    margin-top: 22px;
    color: white;
  }

  .audio-container {
    width: 690px;
    height: 100px;
    background-color: white;
    margin-top: 20px;
    margin-left: 30px;
    display: flex;
    flex-direction: row;
    border-radius: 4px;
    align-items: center;
  }

  .audio-index {
    width: 60px;
    height: 100px;
    line-height: 100px;
    color: #333333;
    font-size: 28px;
    text-align: center;
  }

  .audio-content-container {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  .audio-title {
    width: 564px;
    font-size: 28px;
    line-height: 34px;
    color: #333;
    font-weight: bold;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .audio-time-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 10px;
  }
</style>

