<template>
  <div id="app">
    <router-view v-slot="{Component}">
      <keep-alive>
        <component :is="Component" v-if="$route.meta.keepAlive" :key="$route.path"></component>
      </keep-alive>
      <component :is="Component" v-if="!$route.meta.keepAlive"></component>
    </router-view>
  </div>
</template>

<script>

export default {
  name: 'App',
  mounted() {

  }
}
</script>

<style>

#app {
  display: flex;
  justify-content: center;
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
</style>
