<template>
  <div class="container">
    <div class="container-second-screen">
      <img
        class="poster-logo"
        src="https://educator.oss-cn-hangzhou.aliyuncs.com/static/event/ev3/poster-logo.png"
      />
      <img
        class="poster-title"
        src="https://educator.oss-cn-hangzhou.aliyuncs.com/static/event/ev3/poster-title.png"
      />
      <img
        class="poster-preview"
        src="https://educator.oss-cn-hangzhou.aliyuncs.com/static/event/ev3/poster-preview.png"
      />
      <img
        class="poster-preview-cover"
        src="https://educator.oss-cn-hangzhou.aliyuncs.com/static/event/ev3/poster-preview-cover.png"
      />
      <div class="poster-download-container" @click="downloadApk">
        <img
          style="width: 40px; height: 36px; margin-right: 10px"
          src="https://educator.oss-cn-hangzhou.aliyuncs.com/static/event/ev3/poster-download.png"
        />
        <div style="color: #333; font-size: 28px">极速下载，让教育更轻松！</div>
      </div>
    </div>

    <div class="shadow" v-if="showShadow" @click="showShadow = false">
      <img class="hint" src="https://educator.oss-cn-hangzhou.aliyuncs.com/static/open-browser.png" />
    </div>
  </div>
</template>

<script>
import { jump } from "../../utils/render";
export default {
  name: "Download",

  data() {
    return {
      showShadow: false,
    };
  },
  methods: {
    downloadApk() {
      jump(this, null, null, () => {
        this.showShadow = true;
      });
    },
  },
  mounted() {},
};
</script>

<style scoped>
.container {
  width: 750px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.container-second-screen {
  width: 750px;
  height: 100vh;
  position: relative;
  background: rgb(255, 94, 36);
}

.shadow {
  position: fixed;
  z-index: 99999;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
}

.hint {
  width: 378px;
  height: 236px;
  position: fixed;
  z-index: 999;
  right: 20px;
  top: 0;
}

.poster-logo {
  width: 194px;
  height: 90px;
  position: absolute;
  left: 278px;
  top: 26px;
}

.poster-title {
  width: 348px;
  height: 108px;
  position: absolute;
  left: 201px;
  top: 148px;
}

.poster-preview {
  width: 376px;
  height: 734px;
  position: absolute;
  left: 187px;
  top: 305px;
}

.poster-download-container {
  width: 450px;
  height: 80px;
  background: white;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 66px;
  left: 150px;
}

.poster-preview-cover {
  width: 440px;
  height: 354px;
  position: absolute;
  left: 155px;
  top: 750px;
}
</style>
