<template>
  <div class="container">
    <div class="header-container">
      <video class="header-video"
             v-if="videoLink"
             width="750"
             height="435"
             :src="videoLink"
             :controls="true"
             :poster="videoPic"
             :preload="true"></video>
      <!--<div class="header-video" v-else>-->
        <!--<img class="header-video" webp="webp" :src="videoPic" />-->
        <!--<img class="play-btn" src="/static/images/play.png" @click="pendingPlay = true" />-->
      <!--</div>-->
      <div class="tab-container">
        <div class="tab" @click="onTabPress(0)">
          <div :class="currentTab === 0 ? 'tab-active' : ''">介绍</div>
          <div class="underline" v-if="currentTab === 0"></div>
        </div>
        <div class="tab" @click="onTabPress(1)">
          <div :class="currentTab === 1 ? 'tab-active' : ''">留言</div>
          <div class="underline" v-if="currentTab === 1"></div>
        </div>
      </div>
    </div>

    <div v-show="currentTab === 1 && commentDetail && commentDetail.length">
      <div id="mescroll" class="mescroll" v-show="commentDetail && commentDetail.length">
        <div style="width: 750px; display: flex; flex-direction: column; align-items: center; overflow: hidden">
          <comment-item v-for="(item, index) in commentDetail" :key="index" :item="item" />
        </div>

      </div>
      <div v-if="loaded && (!commentDetail || !commentDetail.length)">
        <empty-view />
      </div>
    </div>

    <div id="rich-text" class="rich-text" v-if="currentTab === 0 && richTextContent" v-html="richTextContent"></div>

    <div class="bottom-container" v-if="currentTab === 1" @click="commentBox = true">
      <div class="ipt">来发布你的思考吧</div>
      <div class="publish">发布</div>
    </div>

    <div class="comment-box" v-if="commentBox" @click="() => commentBox = false">
      <div class="comment-textarea">
        <p class="comment-text">
          正在评论 <span style="font-weight: bold;">光明教育家</span>
        </p>
        <textarea :fixed="true" name="" id="textarea" :cursor-spacing="180" :auto-focus="true"
                  :show-confirm-bar="false"
                  v-if="commentBox"
                  v-model="content" @click.stop=""></textarea>
        <span class="issue" :style="{'color': content ? '#d8201d' : '#4f5661'}"
              @click.stop.prevent="issueContent">发布</span>
      </div>
    </div>
    <toast ref="toast"></toast>
  </div>
</template>

<script>
  import CommentItem from '../components/commentItem'
  import api from '../assets/api/api_vote'
  import {formatDate, getFullPicUrl, tagAddStyle, timePeriod} from "../utils/render"
  import EmptyView from '../components/emptyView'
  import Toast from '../components/toast'
  import {AuthMixin} from "../assets/utils/mixin";

  export default {
    mixins: [AuthMixin],
    name: 'CourseDetail',
    data() {
      return {
        currentTab: 0,
        isDetailComLastPage: false,
        detailComPageNum: 1,
        commentDetail: [],
        newsInfo : {},
        user: {},
        richTextContent: null,
        likeDiff : 1,
        initLike : null,
        commentBox: false,
        content: '',
        loaded: false,
        pendingPlay: false,
        videoPic: '',
        videoLink: ''
      }
    },
    components: {
      Toast,
      CommentItem,
      EmptyView
    },
    methods: {
      initScroll() {
        this.mescroll = new MeScroll("mescroll", {
          down: {
            use: false
          },
          up: {
            callback: this.getCommentList,
            htmlNodata: '<p class="edu-nodata">我是有底线的</p>'
          }
        });
      },
      onTabPress(index) {
        this.currentTab = index
      },
      issueContent() {
        if (!this.content) {
          this.$refs.toast.toast('内容不能为空')
          return
        }
        if (!this.user || this.user.isAnony) {
          this.$refs.toast.toast('请前往APP发表您的思考')
          return
        }
        let data = {
          detail: this.content,
          itemId: this.itemId
        }
        api.reply(data).then((res) => {
          if (res.errcode === 0) {
            this.$refs.toast.toast('评论成功')

            res.data.hideComment = false
            res.data.Like = [{ isLike: 0 }]
            res.data.ReplyCount = {
              replyCount: 0,
              likeCount: 0
            }
            res.data.User = {
              face: this.user.face,
              nickName: this.user.nickName
            }
            res.data.isBan = Number(res.data.isBan)
            res.data.properties = JSON.parse(res.data.properties)
            res.data.ItemReport = []
            res.data.createTime = new Date().valueOf()
            res.data.time = timePeriod(new Date())
            this.commentDetail.unshift(res.data)

            this.content = ''
            this.commentBox = false
          }
        })
      },
      getDetail() {
        let params = {
          itemId: this.itemId
        }
        api.getItem(params).then(res => {
          let data = res.data
          data.pic = getFullPicUrl(data.properties.pic) || (data.properties.picList ? getFullPicUrl(JSON.parse(data.properties.picList)[0]) : '')
          data.share = getFullPicUrl(data.properties.share)
          data.time = formatDate(new Date(data.createTime))
          if (data.itemTypeId === 2) {
            data.videoPic = getFullPicUrl(data.properties.pic)
            data.videoLink = getFullPicUrl(data.properties.link)
          }
          if (!data.Like) {
            data.Like = []
          }
          if (!data.Like.length) {
            data.Like.push({isLike: 0})
          }
          if (!data.Collect) {
            data.Collect = []
          }
          if (!data.Collect.length) {
            data.Collect.push({isCollect: 0})
          }
          if (!data.ReplyCount) {
            data.ReplyCount = {
              collectCount: 0,
              likeCount: 0,
              replyCount: 0,
              viewCount: 0,
              shareCount: 0
            }
          }
          if (data.UserInfo.User.School) {
            data.UserInfo.User.School.pic = getFullPicUrl(data.UserInfo.User.School.pic)
          }

          let richTextContent = data.properties.content
          let reg = /(font-size\s*:)(\s*\d+\.?\d*)(px|pt)(\s*;?\s*)/g;
          richTextContent = richTextContent.replace(reg,(matched,capture1,capture2,capture3,capture4,S,groups)=>{
            return capture1+Number(capture2)*1.8+capture3+capture4;
          })
          richTextContent = tagAddStyle(richTextContent)
          // data.properties.content = null

          this.newsInfo = data
          this.richTextContent = richTextContent
          this.likeDiff = data.Like[0].isLike ? -1 : 1
          this.initLike = data.Like[0].isLike

          document.getElementsByTagName('title')[0].innerHTML = data.itemName

          this.getItemByIds(data.properties.ids)

          this.initWxConfig({
            itemName: data.itemName,
            pic: data.pic,
            picList: data.picList,
            fromId: 'CourseDetail',
            applyId: data.itemId
          })
        })
      },
      getItemByIds(ids) {
        api.getItemByIds({ids: `[${ids[0]}]`}).then(res => {
          if (res.errcode === 0) {
            let videoList = res.data
            videoList.forEach(it => {
              it.properties.pic = getFullPicUrl(it.properties.pic)
              it.properties.link = getFullPicUrl(it.properties.link)
            })
            this.videoPic = videoList[0].properties.pic
            this.videoLink = videoList[0].properties.link
          }
        })
      },
      getCommentList() {
        if (this.isDetailComLastPage) return
        let params = {
          itemId: this.itemId,
          start: (this.detailComPageNum - 1) * 10,
          length: 10
        }
        api.getItemTopReply(params).then(res => {
          if (res.errcode !== 0) {
            this.commentDetail = []
          } else {
            let results = res.data.rows
            results.forEach(item => {
              // this.$set(item, 'ChildReplyLength', item.ChildReply.length)
              // item.ChildReply = item.ChildReply.slice(0, 2)
              // item.ChildReply.forEach(el => {
              //   el.properties = JSON.parse(el.properties)
              // })
              this.$set(item, 'time', 0)
              if (item.Like && item.Like.length === 0) {
                item.Like.push({
                  isLike: 0
                })
              }
              item.time = timePeriod(item.createTime)
              if (!item.ReplyCount) {
                item.ReplyCount = {likeCount: 0}
              }
              if (item.Like[0].isLike) {
                item.activeLikeCount = item.ReplyCount.likeCount
                item.normalLikeCount = item.ReplyCount.likeCount - 1
              } else {
                item.activeLikeCount = item.ReplyCount.likeCount + 1
                item.normalLikeCount = item.ReplyCount.likeCount
              }
            })
            if (results.length < 10) {
              this.isDetailComLastPage = true
            }
            if (this.detailComPageNum === 1) {
              this.commentDetail = results
            } else {
              this.commentDetail = this.commentDetail.concat(results)
            }
            this.detailComPageNum++

            this.loaded = true
            this.mescroll.endSuccess(results.length, !this.isDetailComLastPage);
          }
        })
      },
      getUserByIdForSession() {
        api.getUserByIdForSession().then(res => {
          if (res.errcode === 0) {
            this.user = res.data
          }
        })
      }
    },
    mounted() {
      this.itemId = this.$route.query.itemId
      this.initScroll()
      this.getDetail()
      // this.getCommentList()
      this.getUserByIdForSession()
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

  .container {
    width: 750px;;
    height: 100vh;
    position: relative;
  }

  .header-container {
    width: 750px;
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    left: 0;
    background-color: white;
  }

  .header-video {
    width: 750px;
    height: 435px;
    position: relative;
  }

  .play-btn {
    width: 88px;
    height: 88px;
    position: absolute;
    left: 331px;
    top: 150px;
  }

  .tab-container {
    width: 750px;
    height: 80px;
    box-shadow: 0 10px 10px 0 rgba(239, 239, 239, 0.5);
    background: white;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .tab {
    font-size: 32px;
    flex: 1;
    height: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #333333;
  }

  .tab-active {
    color: #E71E28;
  }

  .underline {
    width: 30%;
    height: 4px;
    background: #E71E28;
    position: absolute;
    left: 35%;
    bottom: 0;
  }

  .comment-list {
    overflow: scroll;
    height: calc(100vh - 442px - 80px - 80px);
  }

  .rich-text {
    width: 690px;
    overflow: hidden;
    padding-bottom: 80px;
    font-size: 40px !important;
    margin-left: 30px;
    margin-top: 545px;
  }

  .comment-box {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: fixed;
    z-index: 99999;
    background: rgba(0, 0, 0, .5);
  }

  .comment-textarea {
    box-sizing: border-box;
    width: 100%;
    position: fixed;
    bottom: 0;
    padding: 20px 30px 0;
    background: white;
    display: flex;
    flex-direction: column;
  }

  .comment-text {
    font-size: 24px;
    color: #4f5661;
  }

  #textarea {
    width: 100%;
    height: 290px;
    margin: 10px 0 15px;
    border: none;
    font-size: 30px;
    line-height: 40px;
  }

  .issue {
    margin-left: 590px;
    margin-bottom: 10px;
    font-size: 28px;
    padding: 10px;
  }

  .bottom-container {
    width: 750px;
    height: 80px;
    background: #f4f4f4;
    position: fixed;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .ipt {
    width: 600px;
    height: 50px;
    line-height: 50px;
    background: white;
    border-radius: 20px;
    box-sizing: border-box;
    padding-left: 30px;
    font-size: 22px;
    color: #999999;
    margin-left: 30px;
  }

  .publish {
    flex: 1;
    height: 80px;
    line-height: 80px;
    font-size: 28px;
    color: #313332;
    text-align: center;
  }

  .mescroll{
    position: fixed;
    top: 522px;
    bottom: 80px;
    height: auto; /*如设置bottom:50px,则需height:auto才能生效*/
  }
</style>
