<template>
  <div class="container">
    <keep-alive>
      <div v-if="currentTab === 0" class="home-container">
        <img style="width: 750px; height: 458px"
             src="https://educator.oss-cn-hangzhou.aliyuncs.com/static/event/ev4/about-banner.png">
        <img style="width: 750px; height: 1076px; margin-top: -75px"
             src="https://educator.oss-cn-hangzhou.aliyuncs.com/static/event/ev4/about-event.png">
        <!--<video class="about-video"-->
               <!--controls-->
               <!--autoplay-->
               <!--src="https://4gwhnhct1gyzdcqjqgehy.ourdvsss.com/flv1.gmw.cn/gmw/videoroot/2021-01-06/1609901847048.mp4?wsiphost=ipdbme&wshc_tag=0&wsts_tag=60ade876&wsid_tag=7256e30a"></video>-->
        <!--<div class="detail-line" style="margin-top: 40px">-->
          <!--<div class="detail-line-key">报名时间：</div>-->
          <!--<div class="detail-line-value">2021/05/28 - 2021/10/31</div>-->
        <!--</div>-->
        <!--<div class="detail-line" style="margin-top: 16px">-->
          <!--<div class="detail-line-key">活动时间：</div>-->
          <!--<div class="detail-line-value">2021/09/28 - 2021/12/31</div>-->
        <!--</div>-->
        <div class="enter" @click="enter">我要报名</div>
      </div>
      <div v-else-if="currentTab === 1" class="about-container">
        <img style="width: 750px; height: 458px"
             src="https://educator.oss-cn-hangzhou.aliyuncs.com/static/event/ev4/about-banner.png">
        <img style="width: 750px; height: 1899px; margin-top: -75px" src="https://educator.oss-cn-hangzhou.aliyuncs.com/static/event/ev4/about1.png">
        <img style="width: 750px; height: 2654px" src="https://educator.oss-cn-hangzhou.aliyuncs.com/static/event/ev4/about2.png">
        <img style="width: 750px; height: 2909px" src="https://educator.oss-cn-hangzhou.aliyuncs.com/static/event/ev4/about3.png">
      </div>
    </keep-alive>
    <bottom-tab theme-color="#51A5FF"
                :current-tab="currentTab"
                :tabs="tabs"
                @onBottomTabPress="onBottomTabPress" />

    <splash splash-image="http://educator.oss-cn-hangzhou.aliyuncs.com/static/event/ev4/splash.png"
            @onSplashSkip="onSplashSkip"
            v-if="showSplash" />
  </div>
</template>

<script>
  import Splash from './splash'
  import BottomTab from './bottomTab'
  import {AuthMixin} from "../../../assets/utils/mixin"
  /**
   * 最美园长报名
   */
  export default {
    name: 'ZmyzEnter',
    mixins: [AuthMixin],
    components: {
      Splash,
      BottomTab
    },
    data() {
      return {
        showSplash: true,
        currentTab: 0,
        tabs: [
          {
            activeIcon: 'https://educator.oss-cn-hangzhou.aliyuncs.com/static/event/ev4/about-home-active.png',
            normalIcon: 'http://educator.oss-cn-hangzhou.aliyuncs.com/static/event/ev4/about-home-normal.png',
            name: '首页'
          },
          {
            activeIcon: 'http://educator.oss-cn-hangzhou.aliyuncs.com/static/event/ev4/about-detail-active.png',
            normalIcon: 'http://educator.oss-cn-hangzhou.aliyuncs.com/static/event/ev4/about-detail-normal.png',
            name: '详情'
          }
        ]
      }
    },
    methods: {
      onSplashSkip() {
        this.showSplash = false
      },
      onBottomTabPress(index) {
        this.currentTab = index
      },
      enter() {
        this.$router.push({name: 'ZmyzAbout'})
      }
    },
    mounted() {
        this.initWxConfig({
            itemName: '“寻找榜样园长”报名入口',
            pic: 'https://educator.oss-cn-hangzhou.aliyuncs.com/static/event/ev4/about-banner.jpg',
            link: `zmyz-enter`
        })
    }
  }
</script>

<style scoped>

  .container {
    width: 750px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .home-container{
    width: 750px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
  }

  .about-container{
    width: 750px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .about-video {
    width: 622px;
    height: 374px;
    position: absolute;
    left: 64px;
    top: 552px;
  }

  .detail-line {
    width: 750px;
    height: 54px;
    padding: 0 24px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .detail-line-key {
    height: 54px;
    line-height: 54px;
    padding: 0 20px;
    background: white;
    border-radius: 27px;
    font-size: 28px;
    color: #EB002A;
    margin-right: 12px;
  }

  .detail-line-value {
    font-size: 32px;
    color: #575757;
  }

  .enter {
    width: 702px;
    height: 92px;
    background: #FFFFFF;
    border-radius: 45px;
    line-height: 92px;
    text-align: center;
    font-size: 40px;
    color: #EB002A;
    position: absolute;
    left: 24px;
    bottom: 130px;
  }
</style>
