<template>
  <div class="no-data" :style="{height: height}" v-if="showMe">
    <div :style="{width: width, height: '575px', position: 'relative'}" >
      <img webp="webp" :style="{width: width, height: '575px'}" src="/static/images/no-data.png" />
      <div style="font-size: 24px; color: #999999; width: 100%; text-align: center; position: absolute; top: 403px">{{hint}}</div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'myComment',
    props: {
      showMe: {
        type: Boolean,
        default: true
      },
      width: {
        type: String,
        default: '750px'
      },
      height: {
        type: String,
        default: '575px'
      },
      hint: {
        type: String,
        default: '空空如也'
      }
    },
    data () {
      return {
      }
    },
    methods: {
      reply() {
        // this.$emit('reply', this.response)
      }
    }
  }
</script>

<style scoped>
  .no-data {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
</style>
