<template>
  <div class="course-item" @click="toDetail">
    <div class="left-container">
      <div class="title">{{item.itemName}}</div>
      <div class="bottom-container">
        <img class="video" src="/static/images/video.png" />
        <div class="hint" style="margin-left: 5px">{{item.ItemCount ? item.ItemCount.viewCount : 0}}</div>
        <div style="flex: 1"></div>
        <div class="hint">{{item.time}}</div>
      </div>
    </div>
    <div class="cover-container">
      <img class="cover" webp="webp" :src="item.properties.pic" />
      <div class="course-cover"></div>
      <div class="time">{{item.duration}}</div>
    </div>
  </div>
</template>

<script>
  export default {
    props: ['item'],
    data () {
      return {

      }
    },
    methods: {
      toDetail() {
        this.$router.push({name: 'CourseDetail', query: {itemId: this.item.itemId}})
      }
    },
    mounted () {

    }
  }
</script>

<style scoped>

  .course-item {
    width: 690px;
    height: 198px;
    border-bottom: 1px solid #f2f2f2;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .cover-container {
    width: 260px;
    height: 146px;
    border-radius: 8px;
    position: relative;
  }

  .cover {
    width: 260px;
    height: 146px;
    border-radius: 8px;
  }

  .course-cover {
    width: 260px;
    height: 146px;
    position: absolute;
    top: 0;
    left: 0;
    background: linear-gradient(transparent 0%, transparent 78%, #000 100%);
    opacity: 0.2;
    border-radius: 8px 8px 0 0;
  }

  .left-container {
    width: 400px;
    height: 146px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .title {
    width: 400px;
    font-size: 32px;
    line-height: 45px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .bottom-container {
    width: 400px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .video {
    width: 36px;
    height: 36px
  }

  .hint {
    font-size: 24px;
    color: #999999;
  }

  .time {
    color: white;
    font-size: 22px;
    position: absolute;
    right: 10px;
    bottom: 5px;
  }
</style>
