<template>
  <div class="container">
    <div class="dis-scroll">
      <img
        class="header"
        src="https://educator.oss-cn-hangzhou.aliyuncs.com/static/share-top-banner.png"
        @click="downloadApk"
      />

      <div style="width: 750px; height: 422px; position: relative">
        <video
          class="player"
          id="player"
          ref="player"
          v-if="detail.videoLink && displayTime < displayMaxTime"
          :src="detail.videoLink"
          :poster="detail.videoPic"
          preload
          controls
          x5-playsinline=""
          playsinline=""
          webkit-playsinline=""
        ></video>
        <div class="display-end" v-if="displayTime >= displayMaxTime" @touchmove.prevent @click="downloadApk">
          <img class="player" :src="detail.videoPic" />
          <div class="display-end-hint">
            <div style="font-size: 32px; color: white">试看结束,打开APP观看完整视频</div>
          </div>
        </div>
      </div>
      <div class="open-app" @click="downloadApk">试看3分钟,打开APP观看完整视频</div>
      <div class="author-container">
        <div class="courseName">{{ detail.courseName }}</div>
        <div style="display: flex; flex-direction: row; flex: 1; margin-top: 4px">
          <div class="desc">{{ detail.schoolName }}</div>
          <div class="desc" style="margin-left: 10px">{{ formatDate(new Date(detail.createTime)) }}</div>
        </div>
      </div>
    </div>
    <div id="richText" class="rich-text" v-html="detail.introduce"></div>

    <div class="shadow" v-if="showShadow" @click="showShadow = false">
      <img class="hint" src="http://educator.oss-cn-hangzhou.aliyuncs.com/static/open-browser2.png" />
    </div>
    <fixed-bottom @download="downloadApk" />
  </div>
</template>

<script>
import { formatDate, jump } from "../../utils/render";
import api from "../../assets/api/api_share";
import { AuthMixin } from "@/assets/utils/mixin";
import FixedBottom from "@/components/share/fixedBottom";

export default {
  mixins: [AuthMixin],
  name: "ShareFamousTeacherCourse",
  components: { FixedBottom },
  data() {
    return {
      courseId: null,
      detail: {},
      showShadow: false,
      displayTime: 0,
      displayMaxTime: 180000,
    };
  },
  methods: {
    formatDate,
    downloadApk() {
      let param = {
        itemId: this.itemId,
      };
      jump(this, "FamousTeacherVideoDetail", param, () => {
        this.showShadow = true;
      });
    },
    initPlayer() {
      this.$nextTick(() => {
        let player = document.getElementById("player");
        player.addEventListener("play", () => {
          clearInterval(this.timer);
          this.timer = setInterval(() => {
            this.displayTime += 1000;
            if (this.displayTime > this.displayMaxTime) {
              clearInterval(this.timer);
              this.exitFullScreen();
              player.pause();
            }
          }, 1000);
        });
        player.addEventListener("pause", () => {
          clearInterval(this.timer);
        });
      });
    },
    exitFullScreen() {
      let doc = document;
      if (doc.webkitCancelFullScree) {
        doc.webkitCancelFullScreen();
      } else if (doc.mozCancelFullScreen) {
        doc.mozCancelFullScreen();
      } else if (doc.exitFullscreen) {
        doc.exitFullscreen();
      }
    },
  },
  mounted() {
    this.courseId = this.$route.query.courseId;
    api.getShareCCCourse({ courseId: this.courseId }).then((res) => {
      this.detail = res.data;
      document.title = this.detail.courseName;

      this.initPlayer();

      this.initWxConfig({
        itemName: this.detail.courseName,
        pic: "https://educator.oss-cn-hangzhou.aliyuncs.com/static/app-icon.png",
        link: `share-cc-course?courseId=${this.detail.id}`,
      });
    });
  },
};
</script>

<style scoped>
.container {
  width: 750px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.dis-scroll {
  width: 750px;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  left: 0;
  top: 0;
}

.display-end {
  width: 750px;
  height: 422px;
  position: absolute;
  left: 0;
  top: 0;
}

.display-end-hint {
  width: 750px;
  height: 422px;
  position: absolute;
  left: 0;
  top: 0;
  background-color: rgba(46, 46, 46, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
}

.header {
  width: 750px;
  height: 80px;
}

.player {
  width: 750px;
  height: 422px;
}

.shadow {
  position: fixed;
  z-index: 99999;
  left: 0;
  right: 0;
  top: 80px;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
}

.hint {
  width: 750px;
  height: 80px;
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
}

.open-app {
  width: 650px;
  height: 80px;
  line-height: 80px;
  color: white;
  font-size: 30px;
  text-align: center;
  border-radius: 4px;
  background-color: #7dcdf2;
  margin-top: 30px;
}

.rich-text {
  width: 650px;
  overflow: hidden;
  padding-bottom: 204px;
  font-size: 32px;
  line-height: 60px;
  margin-top: 737px;
}

.author-container {
  width: 650px;
  margin-top: 20px;
}
.courseName {
  font-size: 28px;
  color: #313332;
  font-weight: bold;
}
.desc {
  font-size: 20px;
  color: #b1b1b1;
  margin-top: 10px;
}
</style>
