<template>
  <div style="position: relative; width: 750px">
    <div class="container">
      <img class="header"
           src="https://educator.oss-cn-hangzhou.aliyuncs.com/static/share-top-banner.png"
           @click="downloadApk"/>
      <div class="circle-container">
        <img class="circle-cover" :src="circle.cover"/>
        <div class="circle-right-container">
          <div class="circle-title">{{circle.circleName}}</div>
          <div class="circle-desc">{{circle.properties.content}}</div>
          <div class="circle-bottom-container" @click="downloadApk">
            <img style="width: 40px; height: 40px"
                 src="https://educator.oss-cn-hangzhou.aliyuncs.com/static/share/view-count.png">
            <div class="circle-count">{{circle.CircleCount.circleViewCount}}</div>
            <img style="width: 40px; height: 40px"
                 src="https://educator.oss-cn-hangzhou.aliyuncs.com/static/share/like.png">
            <div class="circle-count">{{circle.CircleCount.circleFollowCount}}</div>
            <div style="flex: 1"></div>
            <div class="join">加入</div>
          </div>
        </div>
      </div>
      <div class="circle-divider"></div>
      <div class="indicator-container">
        <div class="indicator-title">讨论</div>
        <div class="indicator-order" @click="downloadApk">按热度</div>
        <img style="width: 40px; height: 40px; margin-left: 8px"
             src="https://educator.oss-cn-hangzhou.aliyuncs.com/static/share/hot.png"
             @click="downloadApk">
      </div>

      <div class="dynamic-container" v-for="(item, index) in dynamicList" :key="index">
        <div style="display: flex; flex-direction: row; align-items: center">
          <img style="width: 80px; height: 80px; border-radius: 40px" :src="item.User.face">
          <div class="dynamic-user-container">
            <div class="dynamic-nickname">{{item.User.nickName}}</div>
            <div class="dynamic-desc">{{item.desc}}</div>
          </div>
        </div>
        <div class="dynamic-content">{{item.properties.detail}}</div>
        <div class="dynamic-pic-list">
          <img class="dynamic-pic"
               v-for="(p, pIdx) in item.picList" :key="pIdx"
               @click="previewImage(item.picList)"
               :src="p">
        </div>

        <div class="dynamic-bottom-container" @click="downloadApk">
          <img style="width: 48px; height: 48px" src="https://educator.oss-cn-hangzhou.aliyuncs.com/static/share/thumb.png">
          <div class="dynamic-count">点赞</div>
          <img style="width: 48px; height: 48px" src="https://educator.oss-cn-hangzhou.aliyuncs.com/static/share/comment.png">
          <div class="dynamic-count">评论</div>
          <img style="width: 48px; height: 48px" src="https://educator.oss-cn-hangzhou.aliyuncs.com/static/share/share.png">
          <div class="dynamic-count">分享</div>
        </div>

        <div style="width: 710px; height: 2px; background: #F5F5F5; margin-top: 44px"></div>
      </div>
      <div class="download" @click="downloadApk">打开App查看更多帖子</div>

      <div class="shadow" v-if="showShadow" @click="showShadow = false">
        <img class="hint" src="https://educator.oss-cn-hangzhou.aliyuncs.com/static/open-browser.png"/>
      </div>
    </div>
    <fixed-bottom @download="downloadApk" />
  </div>
</template>

<script>

  import {jump} from "@/utils/render";
  import apiShare from "@/assets/api/api_share";
  import {getFullPicUrl, getPicList} from "../../utils/render";
  import FixedBottom from '@/components/share/fixedBottom'

  /**
   * 分享圈子
   * @since 4.0
   */
  export default {
    name: "share-circle",
    components: {FixedBottom},
    data() {
      return {
        showShadow: false,
        circle: {properties: {}, CircleCount: {}},
        dynamicCount: 0,
        dynamicList: [],
        circleId: null
      }
    },
    methods: {
      downloadApk() {
        let param = {
          circleId: this.circleId,
        }
        jump(this, 'CircleDetail', param, () => {
          this.showShadow = true
        })
      },
      getShareCircleDynamic() {
        let circleId = this.$route.query.circleId
        let params = {
          circleId,
          start: 0,
          length: 1
        }
        apiShare.getShareCircleDynamic(params).then(res => {
          if (res.errcode !== 0) {
            return
          }
          this.dynamicCount = res.data.count
          let result = res.data.rows ? res.data.rows : []
          result.forEach(it => {
            it.picList = getPicList(it.picList)
            // let user = it.User || {}
            let desc = ''
            // if (user.School) {
            //   desc = user.School.name
            // } else if (!user.schoolId) {
            //   desc = user.isPersonalAuth
            //     ? '入驻用户'
            //     : user.vipLevel
            //       ? '年费会员'
            //       : '普通用户';
            // }
            it.desc = desc
          })
          this.dynamicList = result
        })
      },
      previewImage(picList) {
        this.$viewerApi({
          images: picList,
        })
      }
    },
    mounted() {
      let circleId = this.$route.query.circleId
      let params = {circleId}
      apiShare.getShareCircleInfo(params).then(res => {
        if (res.errcode !== 0) {
          return
        }
        let result = res.data
        if (!result.properties) {
          result.properties = {}
        }
        result.cover = result.properties.picList && result.properties.picList.length ? result.properties.picList[0] : ''
        result.cover = getFullPicUrl(result.cover)

        this.circle = result
        this.initWxConfig({
          itemName: result.circleName,
          pic: 'https://educator.oss-cn-hangzhou.aliyuncs.com/static/app-icon.png',
          link: `share-circle?circleId=${circleId}`
        })
      })
      this.circleId = circleId
      this.getShareCircleDynamic()
    }
  }
</script>

<style scoped>

  .container {
    padding-bottom: 120px;
    width: 750px;
    min-height: 100vh;
    max-height: 200vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    position: relative;
  }

  .header {
    width: 750px;
    height: 80px;
  }

  .shadow {
    position: fixed;
    z-index: 99999;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.3);
  }

  .hint {
    width: 378px;
    height: 236px;
    position: fixed;
    z-index: 999;
    right: 20px;
    top: 0;
  }

  .circle-container {
    width: 750px;
    display: flex;
    flex-direction: row;
    padding: 20px 20px 40px 20px;
    background: white;
    box-sizing: border-box;
  }

  .circle-cover {
    width: 208px;
    height: 208px;
    background: #f5f5f5;
    border-radius: 16px;
    margin-right: 24px;
  }

  .circle-right-container {
    width: 478px;
    display: flex;
    flex-direction: column;
  }

  .circle-title {
    width: 476px;
    height: 44px;
    font-size: 32px;
    color: #222222;
    line-height: 44px;
  }

  .circle-desc {
    width: 476px;
    height: 80px;
    font-size: 28px;
    color: #989898;
    line-height: 40px;
    margin-top: 8px;
  }

  .circle-bottom-container {
    width: 476px;
    height: 64px;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    margin-top: 12px;
  }

  .circle-count {
    height: 40px;
    font-size: 24px;
    color: #989898;
    line-height: 40px;
    margin-right: 20px;
  }

  .join {
    width: 124px;
    height: 56px;
    background: #EB002A;
    border-radius: 12px;
    line-height: 56px;
    text-align: center;
    color: white;
    font-size: 28px;
  }

  .circle-divider {
    width: 750px;
    height: 20px;
    background: #F5F5F5;
  }

  .indicator-container {
    width: 750px;
    height: 130px;
    background: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 20px;
    box-sizing: border-box;
  }

  .indicator-title {
    width: 118px;
    height: 50px;
    font-size: 36px;
    font-weight: bold;
    color: #222222;
    line-height: 50px;
    flex: 1;
  }

  .indicator-order {
    width: 84px;
    height: 40px;
    font-size: 28px;
    color: #989898;
    line-height: 40px;
  }

  .dynamic-container {
    width: 750px;
    display: flex;
    flex-direction: column;
    background: white;
    padding: 0 20px;
    box-sizing: border-box;
  }

  .dynamic-user-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-left: 16px;
  }

  .dynamic-nickname {
    width: 500px;
    height: 44px;
    font-size: 32px;
    font-weight: bold;
    color: #222222;
    line-height: 44px;
  }

  .dynamic-desc {
    width: 168px;
    height: 34px;
    font-size: 24px;
    color: #989898;
    line-height: 34px;
    margin-top: 2px;
  }

  .dynamic-content {
    width: 710px;
    font-size: 32px;
    color: #222222;
    line-height: 42px;
    letter-spacing: 1px;
    margin-top: 16px;
  }

  .dynamic-pic-list {
    width: 710px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .dynamic-pic {
    width: 228px;
    height: 228px;
    margin-top: 16px;
  }

  .dynamic-bottom-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 32px;
  }

  .dynamic-count {
    width: 88px;
    height: 40px;
    font-size: 28px;
    color: #222222;
    line-height: 40px;
    margin-left: 8px;
  }

  .download {
    width: 480px;
    height: 72px;
    background: #EB002A;
    border-radius: 16px;
    line-height: 72px;
    text-align: center;
    color: white;
    font-size: 28px;
    margin-top: 60px;
    margin-bottom: 112px;
  }
</style>
