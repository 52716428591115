<template>
  <div class="rs-introduce-container">
    <div class="content">
      <div class="card">
        <div class="title">“最美红色故事发声人”短视频 、征文展示</div>
        <div class="title-2">活动背景：</div>
        <div class="p">
          为深入贯彻落实中共中央《关于认真学习宣传贯彻党的二十大精神的决定》、共青团中央《关于全团认真学习宣传贯彻党的二十大精神的通知》
          (中青发[2022]19
          号)文件精神，重庆市委宣传部、市文明办、市教委、市生态环境局、市体育局、团市委、重庆警备区政治工作局、市委党史研究室、重庆日报报业集团、红岩联线管理中心、市校外联决定联合举办“学习二十大、永远跟党走、奋进新征程”系列实践活动。现将“最美红色故事发声人”短视频
          、征文展示有关事宜通知如下。
        </div>
        <div class="title-2">一、活动主题</div>
        <div class="p">学习二十大 永远跟党走 奋进新征程</div>
        <div class="title-2">二、活动时间</div>
        <div class="p">2023年3月至2024年2月20日</div>
        <div class="title-2">三、活动对象</div>
        <div class="p">全市青少年</div>
        <div class="title-2">四、组织机构</div>
        <div class="p">
          主办单位：重庆市委宣传部、市文明办、市教委、市生态环境局、市体育局、团市委、重庆警备区政治工作局、市委党史研究室、重庆日报报业集团、红岩联线管理中心、市校外联
        </div>
        <div class="p">
          承办单位：重庆市青少年社会教育协会、市青少年素质教育领导小组办公室、重庆日报教育事业部、光明教育家重庆事业部
        </div>
        <div class="title-2">五、活动内容</div>
        <div class="p">
          举办“最美红色故事发声人”短视频、征文展示。围绕“讲好新时代中国故事、中国共产党故事、革命故事、英雄故事”主题，组织青少年参加“红色之旅”研学实践活动、阅读爱国主义教育书籍和革命故事书籍、观看红色电影及剧目，录制短视频，撰写主题征文，抒发所学、所思、所获、所感，努力争当“最美红色故事发声人”。
        </div>
        <div class="title-2">六、奖项设置</div>
        <div class="p">
          (一)短视频和征文优秀作品奖分别为：一等奖20个，二等奖50个，三等奖80个，优秀奖若干；
        </div>
        <div class="p">(二)优秀指导老师奖、优秀组织奖若干。</div>
        <div class="title-2">七、作品要求</div>
        <div class="p">(一)视频内容：与史实相符，内容积极向上；</div>
        <div class="p">
          (二)视频格式：视频格式：上传的视频格式必须为MP4，横屏，大小不超过100M，时长不超过5分钟（凡是格式不符合要求的一律不予以审核）；
        </div>
        <div class="p">
          (三)征文要求：自选题材，字迹工整，脉络清晰，逻辑通顺，联系实际，切忌空谈，使用活动统一格式用纸，作者自行下载电子版并打印（凡是格式不符合要求的一律不予以审核）；（统一格式用纸截图保存下方二维码,微信扫描下载）
        </div>
        <img
          style="width: 50%"
          src="../../../../public/static/images/activity/red-story/QRCode3.png"
          alt=""
        />
        <div class="p">
          (四)小学生不低于200字，初中生不低于400字左右，高中生不低于600字；
        </div>
        <div class="p">
          (五)版权要求：短视频必须为原创作品，突出创意性，拥有独立完整的著作权，凡涉及著作权、肖像权、名誉权、隐私权等在内的任何法律责任均由参与者本人承担；活动作品知识产权归活动主办单位、承办单位和参加展示者共同所有，作品可无偿用于公益事业宣传；
        </div>
        <div class="title-2">八、作品上传展示说明</div>
        <div class="p">(一)作品上传时间</div>
        <div class="p">2023年7月1日至2023年12月25日</div>
        <div class="p">(二)作品上传展示平台</div>
        <div class="p">光明教育家APP</div>
        <div class="p">(三)作品上传步骤</div>
        <div class="p">
          第一步：下载并安装光明教育家APP（方式一：扫描下载；方式二：安卓用户通过应用市场，ios用户通过App
          Store直接搜索“光明教育家”下载）
        </div>
        <img
          src="../../../../public/static/images/activity/red-story/QRCode.png"
          alt=""
        />
        <div class="p">第二步：</div>
        <div class="p">
          打开光明教育家APP，新用户需使用手机验证码登录并选择身份，完成注册；老用户直接登录
        </div>
        <img
          style="width: 50%"
          src="../../../../public/static/images/activity/red-story/tip1.png"
          alt=""
        />
        <img
          style="width: 50%"
          src="../../../../public/static/images/activity/red-story/tip2.png"
          alt=""
        />
        <div class="p">第三步：</div>
        <div class="p">
          打开光明教育家APP首页—精彩推荐—点击轮播图—进入“最美红色故事发声人”专题—点击作品上传—按照提示进行操作上传
        </div>
        <img
          style="width: 50%"
          src="../../../../public/static/images/activity/red-story/tip3.png"
          alt=""
        />
        <div class="title-2">九、作品审核</div>
        <div class="p">
          作品上传成功后，光明教育家会进行作品内容和格式审核。作品审核通过后，将会通过注册时的联系方式进行短信通知。
        </div>
        <div class="title-2">十、作品展示</div>
        <div class="p">
          审核成功的作品，将会进行专题展示，点开光明教育家APP首页—精彩推荐——点击轮播图——进入“最美红色故事发声人”专题即可观看。
        </div>
        <div class="title-2">十一、咨询方式</div>
        <div class="p">
          如果在上传过程中，遇到不清楚问题，请拨打咨询电话曾老师
          <a href="tel://13609420830">13609420830</a>
          ，蔡老师。
          <a href="tel://18580623231">18580623231 </a>。
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { AuthMixin } from "@/assets/utils/mixin";
import Clipboard from "clipboard";
import { Notify } from "vant";
export default {
  mixins: [AuthMixin],
  name: "RSIntroduce",
  data() {
    return {};
  },
  created() {
    const clipboard = new Clipboard("#downloadUrl");
    clipboard.on("success", () => {
      Notify({ type: "success", message: "地址复制成功" });
    });
  },
  mounted() {
    this.initWxConfig({
      itemName: "“最美红色故事发声人”活动介绍",
      desc: "“最美红色故事发声人”活动介绍",
      pic: "https://image2.cqcb.com/d/file/image/default/2021-05-17/7f0a5358236913593b031b3fdbdd31f8.jpeg",
      link: `activity/read-story/introduce`,
    });
  },
  methods: {
    downloadTemplate() {
      window.location.href = "http://47.99.57.208:9528/template.xlsx";
    },
  },
};
</script>

<style lang="less" scoped>
.rs-introduce-container {
  .content {
    margin-top: -4px;
    background-color: #e41926;
    padding: 20px;

    .card {
      background-color: white;
      border-radius: 10px;
      padding: 20px;
      .title {
        width: 100%;
        text-align: center;
        font-size: 28px;
        line-height: 48px;
        font-weight: bold;
      }
      .title-2 {
        margin: 5px 0 0 0;
        font-size: 20px;
        line-height: 48px;
        font-weight: bold;
      }
      .p {
        font-size: 18px;
        line-height: 32px;
        text-indent: 2em;
        text-align: justify;
      }
    }
  }
}
</style>
