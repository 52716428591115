<template>
  <div class="container">
    <img class="img" alt="" src="../../../../public/static/images/activity/little-reporter/banner1.jpg" />
    <div class="text">
      <p class="p">
        <span class="bold">项目介绍：</span>
        “光明小记者”是光明日报《教育家》杂志社强力打造的少年儿童综合素质提升平台，旨在通过采访、写作、播报、主持等一系列记者职业体验及考察探究、调查访谈、科学探索、劳动体验、社会服务等综合实践活动，密切学生与自然、与社会、与生活、与科技的联系，开阔眼界，增长见识，拓宽成长空间，培养科学精神，全面提高学生综合素质。
      </p>
      <p class="p">
        “光明小记者”选拔与培养活动立足优秀中华传统文化，践行社会主义核心价值观，坚持立德树人根本任务，倡导文化育人、实践育人、协同育人理念，重视政治启蒙、价值观塑造和科学精神培养，引导和帮助学生树立远大志向，坚定理想信念，坚定不移听党话、跟党走。
      </p>
      <p class="p">
        <span class="bold">核心理念：</span
        >以课堂学习为源头，以小记者活动为活水，致力打造校内好学生，校外好记者。
      </p>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.container {
  box-sizing: border-box;
  width: 750px;
  padding: 0 32px;
  .img {
    margin-top: 20px;
    width: 100%;
    height: 236px;
    border-radius: 16px;
    background-color: #f5f5f5;
  }
  .text {
    color: #333333;
    font-size: 28px;
    line-height: 50px;
    text-align: justify;
    .p {
      margin-top: 20px;
      .bold {
        font-weight: bold;
      }
    }
  }
}
</style>