var CryptoJS = require("crypto-js");
import sha256 from 'crypto-js/sha256';

let nonce = 'C9m;l'
let strongKey = ''

export function encodeStr(str) {
  if (!str) {
    return str
  }
  let key = getKey()
  let sign = CryptoJS.AES.encrypt(`${str}`, key).toString()
  sign = CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(sign))
  let ori = CryptoJS.AES.encrypt(`${str}_${sign}`, key).toString()
  return CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(ori))
}

export function decodeStr(str, key) {
  if (!str) {
    return str
  }

  str = CryptoJS.enc.Base64.parse(str).toString(CryptoJS.enc.Utf8)
  let bytes  = CryptoJS.AES.decrypt(str, key)
  return bytes.toString(CryptoJS.enc.Utf8)
}

export function validSecureStr(str, key) {
  let dStr = decodeStr(str, key)
  if (!dStr) {
    return false
  }

  let strArr = dStr.split('_')
  if (!strArr || strArr.length !== 2) {
    return false
  }
  if (!strArr[0] || strArr[0] == 'undefined' || strArr[0] == 'null') {
    return false
  }
  let signDecode = decodeStr(strArr[1], key)
  // signDecode = decodeURIComponent(signDecode)
  return strArr[0] === signDecode
}

function getNowFormatDate() {
  let date = new Date();
  let seperator1 = "-";
  let year = date.getFullYear();
  let month = date.getMonth() + 1;
  let strDate = date.getDate();
  if (month >= 1 && month <= 9) {
    month = "0" + month;
  }
  if (strDate >= 0 && strDate <= 9) {
    strDate = "0" + strDate;
  }
  return year + seperator1 + month + seperator1 + strDate;
}

export function setStrongKey(key) {
  strongKey = key
}

function getKey() {
  return strongKey;
}

export function getWeakKey() {
  let dateStr = getNowFormatDate()
  return sha256(dateStr + nonce).toString()
}

export function sha256As(str) {
  return sha256(str + 'sha256as').toString()
}

export function getTextCodeTable() {
  return [
    '旅', '家', '瑕', '教', '管', '醒', '象', '想', '藏', '意',
    '微', '消', '题', '值', '算', '阑', '雕', '蓦', '教', '馨',
    '若', '磐', '康', '鸿', '屋', '基', '冀', '凰', '读', '育'
  ]
}
