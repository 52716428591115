<template>
  <div class="container">
    <!-- <img class="img" src="../../../../public/static/images/activity/gm-read/gm-read.png" alt="" /> -->
    <div class="form">
      <van-form @submit="submit" v-model="form" label-width="120px">
        <van-field v-model="form.name" label="姓名" placeholder="请输入姓名" :rules="rules.name" />
        <van-field name="radio" label="性别" :rules="rules.sex">
          <template #input>
            <van-radio-group v-model="form.sex" direction="horizontal">
              <van-radio name="男" icon-size="32px">男</van-radio>
              <van-radio name="女" icon-size="32px">女</van-radio>
            </van-radio-group>
          </template>
        </van-field>
        <van-field
          v-model="form.birthday"
          label="出生日期"
          placeholder="请选择出生日期"
          clickable
          readonly
          :rules="rules.birthday"
          @click="datePickerVisible = true"
        />
        <van-field v-model="form.sclass" label="班级" placeholder="请输入班级" :rules="rules.sclass" />
        <van-field
          v-model="form.mobile"
          label="联系电话"
          placeholder="请输入联系电话"
          :rules="rules.mobile"
        />
        <van-field v-model="form.email" label="电子邮箱" placeholder="请输入电子邮箱" :rules="rules.email" />
        <div class="form-item form-border">
          <div class="form-text-normal">自我介绍</div>
          <van-field
            v-model="form.introduction"
            autosize
            type="textarea"
            rows="1"
            placeholder="请输入自我介绍"
            :maxlength="100"
            show-word-limit
            :rules="rules.introduction"
          />
        </div>
        <div class="form-item form-border">
          <div class="form-text-normal">最爱童书（名称+感受）</div>
          <van-field
            v-model="form.likeBook"
            autosize
            type="textarea"
            rows="1"
            placeholder="请输入最爱童书（名称+感受）"
            :maxlength="200"
            show-word-limit
            :rules="rules.likeBook"
          />
        </div>
        <div class="form-item form-border">
          <div class="form-text-normal">与这本童书的合影（jpg、png格式）</div>
          <van-uploader
            style="margin-top: 20px"
            v-model="imageList"
            :max-count="1"
            :after-read="afterImageRead"
          />
        </div>
        <div class="form-item form-border" style="margin-bottom: 20px">
          <div class="form-text-normal">报名理由</div>
          <van-field
            v-model="form.registration"
            autosize
            type="textarea"
            rows="1"
            placeholder="请输入报名理由"
            :rules="rules.registration"
          />
        </div>
        <van-button round block type="primary" native-type="submit">提 交</van-button>
      </van-form>
    </div>
    <van-popup v-model:show="datePickerVisible" round position="bottom">
      <van-datetime-picker
        type="year-month"
        title="出生日期"
        :item-height="60"
        :min-date="new Date(1949, 0, 1)"
        :max-date="new Date(2022, 3, 1)"
        :formatter="formatter"
        @cancel="datePickerVisible = false"
        @confirm="onDatePickerConfirm"
      />
    </van-popup>
  </div>
</template>

<script>
import apiActivity from "@/assets/api/api_activity";
import { Notify, Dialog } from "vant";
import apiCommon from "@/assets/api/api_common";
export default {
  data() {
    return {
      form: {},
      rules: {
        name: [{ required: true, message: "姓名不能为空" }],
        sex: [{ required: true, message: "请选择性别" }],
        birthday: [{ required: true, message: "请选择出生日期" }],
        sclass: [{ required: true, message: "班级不能为空" }],
        mobile: [
          {
            pattern: /^1(3\d|4[5-9]|5[0-35-9]|6[567]|7[0-8]|8\d|9[0-35-9])\d{8}$/,
            message: "手机号格式错误",
          },
        ],
        email: [
          {
            pattern: new RegExp("^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\\.[a-zA-Z0-9_-]+)+$"),
            message: "电子邮箱格式错误",
          },
        ],
        introduction: [{ required: true, message: "自我介绍不能为空" }],
        likeBook: [{ required: true, message: "最爱童书不能为空" }],
        groupPhoto: [{ required: true, message: "童书合影不能为空" }],
        registration: [{ required: true, message: "报名理由不能为空" }],
      },
      datePickerVisible: false,
      imageList: [],
    };
  },
  methods: {
    async submit() {
      if (this.imageList.length === 0) {
        Notify({ type: "danger", message: "请上传童书合影照片" });
        return;
      }
      const imageList = this.imageList.map((item) => {
        return item.url;
      });
      const params = Object.assign({}, this.form, { groupPhoto: JSON.stringify(imageList) });
      const res = await apiActivity.submitBookBoy(params);
      if (res.errcode !== 0) {
        Notify({ type: "danger", message: res.errmsg });
        return;
      }
      Dialog.alert({
        title: "提示",
        message: "您的申请已提交",
      }).then(() => {
        this.form = {};
        this.imageList = [];
      });
    },
    onDatePickerConfirm(date) {
      this.form.birthday = `${date.getFullYear()}年${date.getMonth() + 1}月`;
      this.datePickerVisible = false;
    },
    // 图片改变
    afterImageRead(file) {
      file.status = "uploading";
      file.message = "上传中...";
      apiCommon.uploadImage(file.file).then((res) => {
        if (res.errcode === 0) {
          file.status = "success";
          file.message = "上传成功";
          file.url = res.url;
          this.picList = this.imageList.map((item) => ({ url: item.url }));
        } else {
          file.status = "failed";
          file.message = "上传失败";
          file.url = "";
        }
      });
    },

    // 年月格式化
    formatter(type, val) {
      if (type === "year") {
        return `${val}年`;
      } else if (type === "month") {
        return `${val}月`;
      }
      return val;
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  box-sizing: border-box;
  width: 750px;
  .img {
    width: 100%;
  }
  .form {
    padding: 24px;
  }
}
.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  &-p {
    padding: 14px 0;
  }
  &-between {
    justify-content: space-between;
  }
}
.form-border {
  border-bottom: 1px solid #ebedf0;
}
.form-text-normal {
  font-size: 28px;
  color: #646566;
}
.form-item {
  padding-top: 20px;
}
.form-label {
  font-size: 28px;
  font-weight: bold;
  line-height: 30px;
  padding: 20px 0px;
}
</style>