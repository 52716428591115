import * as API from './'

export default {

  /**
   * 带娃心累测试-获取结果
   */
  getDetectionResult: params => {
    return API.POST('/user/getDetectionResult', params)
  }
}
