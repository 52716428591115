<template>
  <div class="toast" v-show="toastShow">
    {{toastText}}
  </div>
</template>

<script>
  export default {
    name: 'toast',
    data () {
      return {
        toastShow: false,
        toastText: ''
      }
    },
    methods: {
      toast (e, noTimeout) {
        let self = this
        self.toastText = e
        self.toastShow = true
        if (noTimeout) {
          // ...
        } else {
          setTimeout(function(){
            self.toastShow = false
          }, 1500)
        }
      },
      hide () {
        this.toastShow = false
      }
    }
  }
</script>

<style scoped>
  .toast{
    position: fixed;
    z-index: 100000;
    left: 50%;
    top:45%;
    transition:all .5s;
    -webkit-transform: translateX(-50%) translateY(-50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    -ms-transform: translateX(-50%) translateY(-50%);
    -o-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    text-align: center;
    border-radius: 5px;
    color:#FFF;
    background: rgba(17, 17, 17, 0.7);
    height: 55px;
    line-height: 55px;
    padding: 0 15px;
    max-width: 300px;
    font-size: 30px;
  }
</style>
