<template>
  <div class="container">
    <swiper
        style="overflow: hidden; height: 100vh;"
        :autoHeight="true"
        :allowTouchMove="allowTouchMove"
        direction="vertical"
        @swiper="onSwiper"
        @slideChange="onSlideChange">
      <swiper-slide class="slide-page-wrapper" v-for="(item, index) in pages" :key="index">
        <slide-page :content="item"
                    :playMusic="playMusic"
                    @next="next"
                    @toResult="toResult" />
      </swiper-slide>
    </swiper>

    <audio preload id="music" hidden loop>
      <source src="https://educator.oss-cn-hangzhou.aliyuncs.com/static/event/ev3/bgm.mp3" type="audio/mp3">
    </audio>

    <img class="play-music"
         :src="playMusic ? 'https://educator.oss-cn-hangzhou.aliyuncs.com/static/event/ev3/music-on.png' : 'https://educator.oss-cn-hangzhou.aliyuncs.com/static/event/ev3/music-off.png'"
         @click="togglePlay"/>
  </div>
</template>

<script>
  import {Swiper, SwiperSlide} from 'swiper/vue';
  import SlidePage from './slidePage'
  import apiCommon from "@/assets/api/api_common";
  import {AuthMixin} from "@/assets/utils/mixin";
  export default {
    name: 'EV3Testing',
    mixins: [AuthMixin],
    data() {
      return {
        allowTouchMove: true,
        playMusic: true,
        pages: [
          {
            background: 'https://educator.oss-cn-hangzhou.aliyuncs.com/static/event/ev3/bg0.png',
            images: [],
            showStart: true
          },
          {
            background: 'https://educator.oss-cn-hangzhou.aliyuncs.com/static/event/ev3/bg1.png',
            images: [
              {
                left: 486,
                top: 934,
                width: 88,
                height: 46,
                src: 'https://educator.oss-cn-hangzhou.aliyuncs.com/static/event/ev3/dialog-small.png'
              },
              {
                left: 13,
                top: 957,
                width: 522,
                height: 240,
                src: 'https://educator.oss-cn-hangzhou.aliyuncs.com/static/event/ev3/dialog-large.png'
              },
              {
                left: 75,
                top: 1050,
                width: 398,
                height: 88,
                src: 'https://educator.oss-cn-hangzhou.aliyuncs.com/static/event/ev3/content1.png'
              }
            ],
            showSlideHint: true
          },
          {
            background: 'https://educator.oss-cn-hangzhou.aliyuncs.com/static/event/ev3/bg2.png',
            images: [
              {
                left: 143,
                top: 88,
                width: 488,
                height: 228,
                src: 'https://educator.oss-cn-hangzhou.aliyuncs.com/static/event/ev3/dialog-large2.png'
              },
              {
                left: 484,
                top: 336,
                width: 88,
                height: 46,
                src: 'https://educator.oss-cn-hangzhou.aliyuncs.com/static/event/ev3/dialog-small.png'
              },
              {
                left: 185,
                top: 127,
                width: 392,
                height: 136,
                src: 'https://educator.oss-cn-hangzhou.aliyuncs.com/static/event/ev3/content2.png'
              }
            ],
            choices: [
              {text: '收拾心情,温和的孩子沟通'},
              {text: '无奈的默默收拾,对孩子不理睬'},
              {text: '烦躁发火,指责孩子太调皮'}
            ],
            scene: '● 学龄前'
          },
          {
            background: 'https://educator.oss-cn-hangzhou.aliyuncs.com/static/event/ev3/bg3.png',
            images: [
              {
                left: 143,
                top: 106,
                width: 488,
                height: 228,
                src: 'https://educator.oss-cn-hangzhou.aliyuncs.com/static/event/ev3/dialog-large2.png'
              },
              {
                left: 548,
                top: 359,
                width: 88,
                height: 46,
                src: 'https://educator.oss-cn-hangzhou.aliyuncs.com/static/event/ev3/dialog-small2.png'
              },
              {
                left: 176,
                top: 158,
                width: 410,
                height: 138,
                src: 'https://educator.oss-cn-hangzhou.aliyuncs.com/static/event/ev3/content3.png'
              }
            ],
            choices: [
              {text: '夸奖他,奖励他喜欢的物品'},
              {text: '敷衍的回应,不再过多关心'},
              {text: '强调没有拿到满分,觉得他可以做得更好'}
            ],
            scene: '● 小学'
          },
          {
            background: 'https://educator.oss-cn-hangzhou.aliyuncs.com/static/event/ev3/bg4.png',
            images: [
              {
                left: 143,
                top: 88,
                width: 488,
                height: 228,
                src: 'https://educator.oss-cn-hangzhou.aliyuncs.com/static/event/ev3/dialog-large2.png'
              },
              {
                left: 201,
                top: 147,
                width: 364,
                height: 115,
                src: 'https://educator.oss-cn-hangzhou.aliyuncs.com/static/event/ev3/content4.png'
              }
            ],
            choices: [
              {text: '不看日记,引导孩子主动和自己沟通'},
              {text: '翻看日记,但不告诉他'},
              {text: '翻看日记,并在他回来后询问日记的内容'}
            ],
            scene: '● 初中'
          },
          {
            background: 'https://educator.oss-cn-hangzhou.aliyuncs.com/static/event/ev3/bg5.png',
            images: [
              {
                left: 143,
                top: 88,
                width: 488,
                height: 228,
                src: 'https://educator.oss-cn-hangzhou.aliyuncs.com/static/event/ev3/dialog-large2.png'
              },
              {
                left: 524,
                top: 345,
                width: 88,
                height: 46,
                src: 'https://educator.oss-cn-hangzhou.aliyuncs.com/static/event/ev3/dialog-small2.png'
              },
              {
                left: 171,
                top: 147,
                width: 424,
                height: 108,
                src: 'https://educator.oss-cn-hangzhou.aliyuncs.com/static/event/ev3/content5.png'
              }
            ],
            choices: [
              {text: '同意,并鼓励他和朋友自己去规划行程'},
              {text: '要求必须有自己或其他家长的陪同'},
              {text: '不同意,要求在家好好准备未来高考'}
            ],
            scene: '● 高中'
          },
          {
            background: 'https://educator.oss-cn-hangzhou.aliyuncs.com/static/event/ev3/bg6.png',
            images: [
              {
                left: 143,
                top: 88,
                width: 488,
                height: 228,
                src: 'https://educator.oss-cn-hangzhou.aliyuncs.com/static/event/ev3/dialog-large2.png'
              },
              {
                left: 574,
                top: 337,
                width: 88,
                height: 46,
                src: 'https://educator.oss-cn-hangzhou.aliyuncs.com/static/event/ev3/dialog-small2.png'
              },
              {
                left: 176,
                top: 116,
                width: 394,
                height: 190,
                src: 'https://educator.oss-cn-hangzhou.aliyuncs.com/static/event/ev3/content6.png'
              }
            ],
            choices: [
              {text: '给予自己的建议,共同分析'},
              {text: '完全听孩子自己的选择'},
              {text: '要求孩子按照自己的想法报名'}
            ],
            scene: '● 高中结束'
          },
          {
            background: 'https://educator.oss-cn-hangzhou.aliyuncs.com/static/event/ev3/bg7.png',
            images: [
              {
                left: 18,
                top: 91,
                width: 488,
                height: 228,
                src: 'https://educator.oss-cn-hangzhou.aliyuncs.com/static/event/ev3/dialog-large2.png'
              },
              {
                left: 400,
                top: 350,
                width: 42,
                height: 34,
                src: 'https://educator.oss-cn-hangzhou.aliyuncs.com/static/event/ev3/dialog-small3.png'
              },
              {
                left: 58,
                top: 149,
                width: 402,
                height: 109,
                src: 'https://educator.oss-cn-hangzhou.aliyuncs.com/static/event/ev3/content7.png'
              }
            ],
            choices: [
              {text: '商量每周或定期联系,了解近况'},
              {text: '不过分关注,想起来再联系'},
              {text: '每天打电话,询问情况'}
            ],
            scene: '● 大学'
          },
          {
            background: 'https://educator.oss-cn-hangzhou.aliyuncs.com/static/event/ev3/bg8.png',
            images: [
              {
                left: 220,
                top: 14,
                width: 474,
                height: 240,
                src: 'https://educator.oss-cn-hangzhou.aliyuncs.com/static/event/ev3/dialog-large3.png'
              },
              {
                left: 549,
                top: 276,
                width: 78,
                height: 48,
                src: 'https://educator.oss-cn-hangzhou.aliyuncs.com/static/event/ev3/dialog-small2.png'
              },
              {
                left: 239,
                top: 71,
                width: 410,
                height: 138,
                src: 'https://educator.oss-cn-hangzhou.aliyuncs.com/static/event/ev3/content8.png'
              }
            ],
            choices: [],
            showResult: true,
            scene: '● 工作'
          }
        ],
        results: []
      }
    },
    components: {
      Swiper,
      SwiperSlide,
      SlidePage
    },
    methods: {
      onSwiperInit() {

      },
      onSwiper(swiper) {
        this.swiper = swiper
      },
      onSlideChange(swiper) {
        this.swiper.allowTouchMove = swiper.realIndex < 2
      },
      next(index) {
        if (this.swiper) {
          this.swiper.slideNext()
        }
        if (!isNaN(index)) {
          this.results.push(index)
        }
      },
      togglePlay() {
        this.playMusic = !this.playMusic
        let audio = document.getElementById('music')
        if (this.playMusic) {
          audio.play()
        } else {
          audio.pause()
        }
      },
      toResult() {
        let form = encodeURIComponent(JSON.stringify(this.results))
        this.$router.push({name: 'EV3Result', query: {form}})
      }
    },
    mounted() {
      let query = this.$route.query
      let play = query.m
      play = play && Number(play) === 1
      if (play) {
        let audio = document.getElementById('music')
        audio.play()
      }
      this.playMusic = play

      apiCommon.traceReport({
        eventName: 'clickElement',
        reportData: {
          function_module: ''
        },
      })

      this.initWxConfig({
        desc: `你原来是这样的父母...点击开始测试`,
        itemName: `带娃的幸福与辛酸——测测你是哪种类型的父母`,
        pic: 'https://educator.oss-cn-hangzhou.aliyuncs.com/static/app-icon.png',
        link: `?fromId=EV3Testing`
      })
    }
  }
</script>

<style scoped>

  .container {
    width: 750px;;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .slide-page-wrapper {
    width: 100vw;
    height: 100vh;
  }

  .play-music {
    width: 40px;
    height: 40px;
    position: absolute;
    right: 25px;
    top: 25px;
  }
</style>
