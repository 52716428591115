<template>
  <div class="container">
    <div class="banner">
        <img
          class="banner"
          src="https://educator.oss-cn-hangzhou.aliyuncs.com/hxzvote/af3e832b-41c0-45c2-92d2-55e39723efa5.png"
        />
      </div>
      <div class="tabs">
        <div class="tab" v-for="(item, index1) in tabs" :key="index1">
          <div class="tab-wrapper" v-if="currentIndex === index1">
            <div class="tab-inner-active">{{ item }}</div>
          </div>
          <div v-else class="tab-inner-normal" @click="onTabClick(index1)">
            {{ item }}
          </div>
        </div>
      </div>
      <ev1-tab-one
        ref="t1"
        v-if="currentIndex === 0"
        @onItemShare="onItemShare"
        />
      <ev1-tab-two ref="t2" v-if="currentIndex === 1" />
      <ev1-tab-three ref="t3" v-if="currentIndex === 2" />
  </div>
</template>

<script>
import Ev1TabOne from "./components/ev1TabOne";
import Ev1TabTwo from "./components/ev1TabTwo";
import Ev1TabThree from "./components/ev1TabThree";
import { isWeixin } from '@/assets/utils/utils';

export default {
  name: 'book',
  components: {
    Ev1TabOne,
    Ev1TabTwo,
    Ev1TabThree,
  },
  data() {
    return {
      tabs: ["投票", "规则介绍", "排行榜"],
      currentIndex: 0,
    };
  },
  methods: {
    onItemShare(item) {
      if (isWeixin()) {
        this.$toast('点击右上角...分享');
        return
      }
      let param = {
        action: "share",
        title: `“百部新书一起读”投票活动开启`,
        imageUrl: item.pic + "?x-oss-process=image/resize,w_150,h_150,m_fill",
        webpageUrl: `https://xst.olomobi.com/#/activity/100cb/book-detail?bookId=${item.bookId}`,
        description: item.bookName,
      };
      window.ReactNativeWebView.postMessage(JSON.stringify(param));
    },
    onTabClick(index1) {
      this.currentIndex = index1;
    },
  },
};
</script>


<style scoped>
.container {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
}
.banner {
  width: 100%;
  height: 249px;
}

.tabs {
  padding-top: 10px;
  width: 750px;
  height: 90px;
  display: flex;
  flex-direction: row;
  align-items: center;
  z-index: 9999;
  background-color: white;
}

.tab {
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.tab-wrapper {
  height: 72px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 9px;
  box-sizing: border-box;
  border: solid 2px #486d43;
  border-radius: 36px;
}

.tab-inner-active {
  height: 54px;
  line-height: 54px;
  background-color: #486d43;
  box-shadow: 0px 2px 7px 0px rgba(140, 185, 134, 0.49);
  border-radius: 27px;
  padding-left: 46px;
  padding-right: 46px;
  font-size: 24px;
  color: white;
}

.tab-inner-normal {
  height: 54px;
  line-height: 54px;
  font-size: 26px;
  color: #313332;
  padding-left: 46px;
  padding-right: 46px;
}
</style>
