<template>
  <div class="form-item">
    <div class="header" v-if="item.header">{{ item.header }}</div>
    <div class="form-item-container" v-if="item.type !== 3">
      <div class="line-single" v-if="item.type === 0">
        <span class="form-star">{{ item.noCheck ? " " : "*" }}</span
        ><span class="form-name">{{ item.name }}</span>
        <input class="form-input" placeholder="请输入" v-model="inputContent" @blur="onInputBlur" />
      </div>
      <div class="line-multi" v-if="item.type === 1">
        <span class="form-star" style="margin-top: 32px">{{ item.noCheck ? " " : "*" }}</span
        ><span class="form-name" style="margin-top: 32px">{{ item.name }}</span>
        <textarea
          class="form-textarea"
          :placeholder="item.hint || '请输入'"
          v-model="inputContent"
          @blur="onInputBlur"
        ></textarea>
      </div>
      <div class="line-single" v-if="item.type === 2">
        <span class="form-star">{{ item.noCheck ? " " : "*" }}</span
        ><span class="form-name">{{ item.name }}</span>
        <div
          class="form-select"
          :style="{ color: inputContent ? '#222222' : '#989898' }"
          @click="showPicker = true"
        >
          {{ inputContent || "请选择" }}
        </div>
        <img
          src="https://educator.oss-cn-hangzhou.aliyuncs.com/static/event/ev4/show-picker.png"
          @click="showPicker = true"
        />
      </div>
    </div>
    <div v-else>
      <div class="line-single">
        <span class="form-star">{{ item.noCheck ? " " : "*" }}</span
        ><span class="form-name">{{ item.name }}</span>
        <div class="form-select">{{ item.uploadHint }}</div>
      </div>
      <van-uploader
        style="width: 662px; margin-bottom: 20px"
        v-model="fileList"
        :max-count="item.maxCount"
        preview-size="192px"
        :before-delete="beforeDelete"
        :after-read="afterRead"
      ></van-uploader>
    </div>

    <van-popup v-model:show="showPicker" position="bottom" :style="{ height: '60%' }">
      <van-picker
        v-if="item.picker === 'sex' || item.picker === 'education'"
        title="请选择"
        show-toolbar
        :columns="pickerColumn"
        @cancel="showPicker = false"
        @confirm="onTextPickerConfirm"
      ></van-picker>
      <van-area
        v-else-if="item.picker === 'area'"
        title="请选择"
        :area-list="areaList"
        result-type="file"
        @cancel="showPicker = false"
        @confirm="onAreaPickerConfirm"
      ></van-area>
      <van-datetime-picker
        v-else-if="item.picker === 'date'"
        v-model="currentDate"
        type="year-month"
        title="请选择"
        :min-date="minDate"
        :max-date="maxDate"
        @cancel="showPicker = false"
        @confirm="onDatePickerConfirm"
      ></van-datetime-picker>
    </van-popup>

    <div class="divider"></div>
  </div>
</template>

<script>
import { areaList } from "@vant/area-data";
import apiCommon from "../../../assets/api/api_common";

export default {
  props: ["item", "formIndex"],
  data() {
    return {
      fileList: [],
      showPicker: false,
      inputContent: null,
      sex: ["女", "男"],
      education: ["博士", "硕士", "本科", "大专", "高中", "初中", "小学"],
      areaList: areaList,
      currentDate: null,
      minDate: new Date(1920, 0, 1),
      maxDate: new Date(2021, 1, 1),
    };
  },
  computed: {
    pickerColumn() {
      if (this.item.picker === "sex") {
        return this.sex;
      } else if (this.item.picker === "education") {
        return this.education;
      } else {
        return [];
      }
    },
  },
  mounted() {},
  methods: {
    onInputBlur() {
      this.$emit("onInput", this.inputContent, this.formIndex);
    },
    onTextPickerConfirm(value) {
      this.showPicker = false;
      let uploadResult = null;
      if (this.item.picker === "sex") {
        uploadResult = value === "女" ? 0 : 1;
      } else {
        uploadResult = value;
      }
      this.inputContent = value;
      this.$emit("onInput", uploadResult, this.formIndex);
    },
    onAreaPickerConfirm(result) {
      let r = "";
      result.forEach((it) => {
        r += it.name;
      });
      this.showPicker = false;
      this.inputContent = r;
      this.$emit("onInput", this.inputContent, this.formIndex);
    },
    onDatePickerConfirm(result) {
      this.showPicker = false;
      let date = new Date(result);
      this.inputContent = date.getFullYear() + "-" + (date.getMonth() + 1);
      this.$emit("onInput", this.inputContent, this.formIndex);
    },
    afterRead(file) {
      file.status = "uploading";
      file.message = "上传中...";
      apiCommon.uploadImage(file.file).then((res) => {
        if (res.errcode === 0) {
          file.status = "success";
          file.message = "上传成功";
          file.url = res.url;
          this.inputContent = JSON.stringify(this.fileList.map((it) => ({ url: it.url })));
          this.$emit("onInput", this.inputContent, this.formIndex);
        } else {
          file.status = "failed";
          file.message = "上传失败";
          file.url = "";
        }
      });
    },
    beforeDelete() {
      setTimeout(() => {
        this.inputContent = JSON.stringify(this.fileList.map((it) => ({ url: it.url })));
        console.log("!!", this.inputContent);
      }, 800);
      return true;
    },
  },
};
</script>

<style scoped>
::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #989898;
}

::-moz-placeholder {
  /* Firefox 19+ */
  color: #989898;
}

:-ms-input-placeholder {
  /* IE 10+ */
  color: #989898;
}

:-moz-placeholder {
  /* Firefox 18- */
  color: #989898;
}

.form-item {
  width: 750px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.form-item-container {
  width: 710px;
  min-height: 108px;
  display: flex;
  flex-direction: row;
}

.divider {
  width: 710px;
  height: 1px;
  background: #f2f2f2;
}

.line-single {
  width: 710px;
  height: 88px;
  padding: 10px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.line-multi {
  width: 710px;
  height: 264px;
  padding: 10px 0;
  display: flex;
  flex-direction: row;
}

.form-star {
  color: #be0000;
  font-size: 28px;
  margin-right: 28px;
}

.form-name {
  width: 240px;
  font-size: 28px;
  color: #222222;
}

.form-input {
  flex: 1;
  height: 88px;
  line-height: 88px;
  font-size: 28px;
  border: none;
}

.form-select {
  height: 88px;
  line-height: 88px;
  font-size: 28px;
  border: none;
  color: #989898;
  padding-right: 10px;
}

.form-textarea {
  flex: 1;
  height: 264px;
  line-height: 36px;
  font-size: 28px;
  border: none;
  padding-top: 26px;
  box-sizing: border-box;
}

.show-picker {
  width: 40px;
  height: 40px;
}

.header {
  width: 750px;
  font-size: 36px;
  font-weight: bold;
  margin-top: 60px;
  margin-bottom: 10px;
  color: #222222;
  padding: 0 20px;
  box-sizing: border-box;
}
</style>

