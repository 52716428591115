import * as API from './'
import {sha256As} from "../../utils/secure";

export default {

  seminarApply: params => {
    return API.POST('/user/seminarApply', params)
  },

  getWebInfo: params => {
    return API.POST('/item/getWebInfo', params)
  },

  getUserSession: params => {
    return API.POST('/login/getUserSession', params)
  },

  getTopicById: params => {
    return API.GET('/item/getTopicById', params)
  },

  getTopicItem: params => {
    return API.GET('/item/getTopicItem', params)
  },

  getItem: params => {
    return API.GET('/item/getItem', params)
  },

  getItemTopReply: params => {
    return API.GET('/item/getItemTopReply', params)
  },

  reply: params => {
    return API.POST('/user/reply', params)
  },

  getUserById: params => {
    return API.GET('/user/getUserById', params)
  },

  getUserByIdForSession: params => {
    return API.GET('/user/getUserByIdForSession', params)
  },

  getVoteHomePage: (params, source) => {
    return API.POST(source === 'app' ? '/vote/getVoteHomePage' : '/vote/getVoteHomePageWeb', params)
  },

  getPrincipalVoteRank: params => {
    return API.GET('/vote/getPrincipalVoteRank', params)
  },

  getGroupRule: params => {
    return API.GET('/vote/getGroupRule', params)
  },

  getItemByIds: params => {
    return API.GET('/item/getItemByIds', params)
  },

  getPrincipalInfoById: params => {
    return API.GET('/vote/getPrincipalInfoById', params)
  },

  principalVote: params => {
    if (params.principalId) {
      params.sign = sha256As('hxz_vote_' + params.principalId)
    }
    return API.POST('/vote/principalVote', params)
  },

  principalUnVote: params => {
    return API.POST('/vote/principalUnVote', params)
  },

  getShowPic: params => {
    return API.GET('/vote/getShowPic', params)
  },

  getVerifyCode: params => {
    if (params.nonce) {
      params.nonce = null
    }
    return API.POST('/vote/getVerifyCode', params)
  },

  uploadApplyInfo: params => {
    return API.POST_HXZ('/votes/upload_apply_info', params)
  }
}
