import * as API from "./"

/**
 * 获取banner
 * @returns
 */
export function getBanner() {
    return API.GET("/art/banner/list")
}
/**
 * 获取团队信息
 * @param {Number} teamId 团队Id
 * @returns
 */
export function getTeamInfo(teamId) {
    return API.GET("/art/team/info", { teamId })
}
/**
 * 获取作品列表
 * @param {Number} teamId 团队Id
 * @returns
 */
export function getItemList(teamId) {
    return API.GET("/art/category/item/list", { teamId })
}
/**
 * 获取作品信息
 * @param {Number} artItemId
 * @returns
 */
export function getItemInfo(artItemId) {
    return API.GET("/art/item/info", { artItemId })
}
/**
 * 点赞团队
 * @param {Number} teamId
 * @returns
 */
export function likeTeam(teamId) {
    return API.POST("/art/like", { teamId })
}
