<template>
    <div class="rs-video-detail-container">
        <div class="content">
            <button class="back-button" @click="handleBack">
                <img src="../../../../public/static/images/activity/red-story/back_icon.png" alt="" />
                <span>返回上一级</span>
            </button>
            <div class="card">
                <div style="margin-top: 0; position: relative">
                    <img :src="img" alt="" v-for="(img, index) in detail.worksUrl" :key="index" />
                    <div class="no-warp">
                        <span>{{ detail.id }}</span>
                    </div>
                </div>
                <div class="title">{{ detail.worksName }}</div>
                <div class="desc dashed">{{ detail.worksDetail }}</div>
                <div class="desc">作者：{{ detail.author }}</div>
                <div class="desc">班级：{{ detail.sclass || "--" }}</div>
                <div class="desc">指导老师：{{ detail.teacher || "--" }}</div>
                <div class="desc">推荐单位：{{ detail.recommendCompany }}</div>
                <div class="count-warp">
                    <img class="icon" src="../../../../public/static/images/like2.png" alt="" />
                    <span class="count">{{ detail.score }}</span>
                </div>
                <div class="button-warp">
                    <van-button class="button" type="primary" @click="toVote">为TA点赞</van-button>
                </div>
                <div class="footer">
                    <button class="button" @click="handlePre">上一条</button>
                    <button class="button" @click="handleNext">下一条</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import api_activity from "@/assets/api/api_activity"
import { AuthMixin } from "@/assets/utils/mixin"
import { Notify, Dialog } from "vant"
import HOST from "../../../assets/api"
import { AppId } from "../../../assets/utils/utils"
import { objectToQueryString } from "@/utils/formatter"
export default {
    mixins: [AuthMixin],
    name: "RS2024ArticleDetail",
    data() {
        return {
            query: { ...this.$route.query, index: +this.$route.query.index },
            detail: {},
            total: 0,
        }
    },
    methods: {
        // 获取作品详情
        getDetail() {
            const params = { start: this.query.index, length: 1, ...this.query }
            delete params.index
            api_activity.getReadStoryWorks(params).then((res) => {
                if (res.errcode !== 0) {
                    Notify({ type: "danger", message: res.errmsg })
                    return
                }
                if (res.data.rows.length === 0) {
                    return
                }
                const detail = res.data.rows[0]
                this.total = res.data?.count || 0
                detail.worksUrl = JSON.parse(detail.worksUrl)
                this.detail = detail
                this.initWxConfig({
                    itemName: `我是“红色故事发声人”${detail.author}`,
                    desc: detail.worksDetail,
                    pic: this.detail.authorPhoto || "https://educator.oss-cn-hangzhou.aliyuncs.com/static/app-icon.png",
                    link: `activity/read-story-2024/article-detail?${objectToQueryString(this.query)}`,
                })
            })
        },
        // 投票
        toVote() {
            const params = { id: this.detail.id }
            api_activity.voteReadStoryWork(params).then((res) => {
                if (res.errcode === 2) {
                    this.authLogin()
                    return
                }
                if (res.errcode !== 0) {
                    Dialog.alert({
                        title: "提示",
                        message: res.errmsg,
                    }).then(() => {
                        this.form1 = {}
                        this.form2 = {}
                    })
                    return
                }
                this.$toast.success("投票成功")
                this.getDetail()
            })
        },
        // 授权登录
        authLogin() {
            const fromId = `&fromId=activity/read-story-2024/article-detail`
            const params = `&params=${btoa(JSON.stringify(this.query))}`
            let redirectUrl = encodeURIComponent(`${HOST}/login/wxLoginV3?${params}${fromId}`)
            let url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${AppId}&redirect_uri=${redirectUrl}&response_type=code&scope=snsapi_userinfo&state=STATE&connect_redirect=1#wechat_redirect`
            window.location.href = url
        },

        // 返回
        handleBack() {
            this.$router.push({ path: "/activity/read-story-2024/display" })
        },
        // 上一条
        handlePre() {
            if (this.query.index === 0) {
                Notify({ type: "danger", message: "前边没有了~" })
                return
            }

            this.query.index -= 1
            const query = { ...this.query }
            this.$router.replace({ query })
            this.getDetail()
            window.scrollTo(0, 0)
        },
        // 下一条
        handleNext() {
            if (this.query.index === this.total - 1) {
                Notify({ type: "danger", message: "后边没有了~" })
                return
            }

            this.query.index += 1
            const query = { ...this.query }
            this.$router.replace({ query })
            this.getDetail()
            window.scrollTo(0, 0)
        },
    },
    mounted() {
        this.getDetail()
    },
}
</script>

<style lang="less" scoped>
.rs-video-detail-container {
    .content {
        margin-top: -4px;
        background-color: #e41926;
        padding: 20px;

        .back-button {
            all: unset;
            cursor: pointer;
            display: flex;
            align-items: center;

            img {
                width: 40px;
                height: 40px;
            }

            span {
                margin-left: 8px;
                color: white;
                font-size: 28px;
            }
        }

        .card {
            margin-top: 20px;
            background-color: white;
            border-radius: 10px;
            padding: 20px;

            .title {
                color: black;
                font-size: 28px;
                font-weight: bold;
                text-align: center;
            }

            .desc {
                color: black;
                font-size: 26px;
                line-height: 1.4;
            }

            .dashed {
                padding-bottom: 10px;
                border-bottom: 1px dashed #000;
            }

            .count-warp {
                display: flex;
                flex-direction: row;
                align-items: flex-end;
                justify-content: center;

                .icon {
                    width: 28px;
                    height: 28px;
                }

                .count {
                    margin-left: 10px;
                    color: red;
                    font-size: 24px;
                    font-weight: bold;
                }
            }

            .button-warp {
                width: 100%;
                margin-top: 20px;
                display: flex;
                flex-direction: row;
                justify-content: center;

                .button {
                    width: 200px;
                    height: 60px;
                    border-radius: 10px;
                    background-color: #e41926;
                    border-color: #e41926;
                }
            }

            .no-warp {
                box-sizing: border-box;
                position: absolute;
                top: 0;
                background-color: #fbe29b;
                color: #e41926;
                font-size: 24px;
                font-weight: bold;
                padding: 5px 10px;
                min-width: 60px;
                text-align: center;
            }

            .footer {
                margin-top: 20px;
                display: flex;
                flex-direction: row;
                justify-content: space-between;

                .button {
                    all: unset;
                    cursor: pointer;
                    color: #e41926;
                    font-size: 28px;
                }
            }
        }

        .card > div {
            margin-top: 20px;
            width: 100%;
        }
    }
}
</style>
