import * as API from './'

/**
 * 发送短信验证码
 * @param {object} params 请求参数
 * @param {string} params.mobile 手机号
 * @param {'MOBILE_FORM_VERIFY' | ''} params.type 验证码类型
 * @returns 
 */
export function sendMsgCode(params) {
    return API.GET('/login/verify/code', params);
}