<template>
    <div v-if="items.length" class="fixed-bottom-container">
        <van-swipe class="swipe" :autoplay="5000" :show-indicators="false">
            <van-swipe-item v-for="(item, index) in items" :key="index" @click="handleClick(item)">
                <div class="item">
                    <img v-if="item.image" class="image" :src="item.image" alt="" />
                    <div class="title">{{ item.itemName }}</div>
                </div>
            </van-swipe-item>
        </van-swipe>
        <van-button class="button" round color="#d8201e" size="small" @click="download">下载客户端</van-button>
    </div>
</template>

<script>
const itemIds = ["46511", "46485", "46477"]

import api from "../../assets/api/api_share"
import { getFullPicUrl } from "@/utils/render"
export default {
    data() {
        return {
            items: [],
        }
    },
    mounted() {
        // for (const index in itemIds) {
        //     const itemId = itemIds[index]
        //     this.getItemDetail(itemId)
        // }
        this.getList()
    },
    methods: {
        // 获取头条文章列表
        getList() {
            api.getHeadItemList().then((res) => {
                const list = res.data
                list.forEach((item) => {
                    const itemRes = item.ItemInfo
                    const properties = JSON.parse(itemRes.properties)
                    const picList = JSON.parse(properties.picList)
                    if (picList.length > 0) {
                        const image = getFullPicUrl(picList[0])
                        Object.assign(itemRes, { image })
                    }
                    this.items.push(itemRes)
                })
            })
        },
        // 获取文章详情
        getItemDetail(itemId) {
            const params = { itemId }
            api.getItem(params).then((res) => {
                const item = res.data
                const properties = JSON.parse(item.properties)
                const picList = JSON.parse(properties.picList)
                const image = getFullPicUrl(picList[0])
                Object.assign(item, { image })
                this.items.push(item)
            })
        },
        // 点击
        handleClick(item) {
            this.$router.push({ name: "ShareArticle", query: { itemId: item.itemId } })
        },
        // 下载
        download() {
            this.$emit("download")
        },
    },
}
</script>

<style lang="less" scoped>
.fixed-bottom-container {
    padding-left: 30px;
    padding-right: 30px;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 120px;
    z-index: 999;
    background: white;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid #dddddd;
    box-sizing: border-box;
    .swipe {
        margin-right: 40px;
        flex: 1;
        height: 80px;
    }
    .item {
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    .image {
        margin-right: 20px;
        width: 80px;
        height: 80px;
        background: #f5f5f5;
        border-radius: 10px;
        object-fit: cover;
    }
    .title {
        color: black;
        font-size: 24px;
        line-height: 30px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }
    .button {
        width: 160px;
    }
}
</style>
