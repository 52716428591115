<template>
  <div class="container">
    <div class="card">
      <div class="avatar-wrapper">
        <img :src="info.avatar" alt="" />
        <div class="name-wrapper">
          <p class="name">
            {{ info.name }}
          </p>
          <p class="desc">{{ info.introduction }}</p>
        </div>
      </div>

      <div class="line"></div>
      <div class="data-wrapper">
        <div class="data-item">
          <img src="../../../public/static/images/share/icon-like.png" alt="" />
          <p>{{ `获赞（${likeCount}）` }}</p>
        </div>
        <div style="width: 80px"></div>
        <div class="data-item">
          <img src="../../../public/static/images/share/icon-fans.png" alt="" />
          <p>{{ `粉丝（${fansCount}）` }}</p>
        </div>
      </div>
    </div>
    <div class="tabs-card">
      <van-tabs v-model:active="active">
        <van-tab title="介绍">
          <div class="introduce">{{ info.detail }}</div>
        </van-tab>
        <van-tab :title="`文章（${articleCount}）`">
          <div class="article-list">
            <article-item v-for="(article, articleIdx) in articleList" :key="articleIdx" :item="article" />
          </div>
        </van-tab>
      </van-tabs>
    </div>
    <fixed-bottom @download="downloadApk" />
  </div>
</template>

<script setup>
import ArticleItem from "@/components/share/articleItem";
import FixedBottom from "@/components/share/fixedBottom";
import { ref } from "@vue/reactivity";
import api_institution from "@/assets/api/api_institution";
import { useRoute } from "vue-router";
import { getFullPicUrl, jump } from "@/utils/render";

const route = useRoute();
// 机构id
const id = route.query.id;

// 机构详情
const info = ref({});
// 机构获赞数
const likeCount = ref(0);
// 机构粉丝数
const fansCount = ref(0);
// 文章总数
const articleCount = ref(0);
// 文章列表
const articleList = ref([]);

// 获取机构详情
async function getInfo() {
  const params = { schoolId: id };
  try {
    const res = await api_institution.getInfo(params);
    info.value = Object.assign({}, res.data, { avatar: getFullPicUrl(res.data.pic) });
  } catch (error) {
    console.log(error);
  }
}
// 获取机构获赞数
async function getLikeCount() {
  const params = { schoolId: id };
  try {
    const res = await api_institution.getLikeCount(params);
    likeCount.value = res.data;
  } catch (error) {
    console.log(error);
  }
}
// 获取机构粉丝数
async function getFansCount() {
  const params = { schoolId: id };
  try {
    const res = await api_institution.getFansCount(params);
    fansCount.value = res.data;
  } catch (error) {
    console.log(error);
  }
}
// 获取机构文章列表
async function getArticleList() {
  const params = { schoolId: id };
  try {
    const res = await api_institution.getArticleList(params);
    articleList.value = res.data.rows || [];
    articleList.value = articleList.value.map((article) => {
      const img = getFullPicUrl(article.properties.pic) || "";
      return Object.assign({}, article, { img });
    });
    articleCount.value = res.data.count || 0;
  } catch (error) {
    console.log(error);
  }
}

// 下载app
function downloadApk() {
  const param = { schoolId: id };
  const page = "InstitutionDetail";
  jump({ $route: route }, page, param, () => {});
}

getInfo();
getLikeCount();
getFansCount();
getArticleList();
</script>

<style scoped>
.container {
  width: 100vw;
  height: 100vh;
  background-image: url("../../../public/static/images/share/institution-bg.png");
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
}
.card {
  margin: 100px 32px 0 32px;
  padding: 32px;
  background-color: white;
  border-radius: 16px;
}
.avatar-wrapper {
  display: flex;
  flex-direction: row;
}
.avatar-wrapper img {
  width: 80px;
  height: 80px;
  border-radius: 40px;
  background-color: #999999;
}
.name-wrapper {
  margin-left: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.name {
  color: #333333;
  font-size: 32px;
  line-height: 40px;
  margin-bottom: 20px;
}

.desc {
  color: #666666;
  font-size: 20px;
}
.line {
  margin-top: 20px;
  width: 100%;
  height: 1px;
  background-color: #99999950;
}
.data-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 20px 0;
}
.data-wrapper .data-item {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.data-wrapper .data-item img {
  width: 40px;
  height: 40px;
}
.data-wrapper .data-item p {
  margin-top: 10px;
  color: #666666;
  font-size: 24px;
}
.tabs-card {
  margin-top: -20px;
  flex: 1;
  border-radius: 16px 16px 0 0;
  background-color: white;
  box-shadow: 0 -4px 10px 2px #00000010;
  overflow: hidden;
}
.introduce {
  padding: 20px 32px;
  color: #666666;
  font-size: 30px;
  line-height: 50px;
  text-align: justify;
}
.article-list {
  padding: 20px 32px;
}
</style>