<template>
  <div class="create-order-container">
    <div class="card course-wrapper">
      <span class="type">课程</span>
      <div class="course-info">
        <img :src="info.cover" alt="" />
        <div class="course-name">{{ info.name }}</div>
      </div>
      <div class="tip-item">
        <div class="name">课程描述</div>
        <div class="tip">{{ info.describe }}</div>
      </div>
      <div class="tip-item">
        <div class="name">分类描述</div>
        <div class="tip">{{ info.courseType }}</div>
      </div>
    </div>
    <div class="card form-wrapper">
      <div class="form-item">
        <van-field
          v-model="form.gardenName"
          input-align="right"
          label="所在园所名称"
          placeholder="填写园所完整名称"
        />
        <van-field v-model="form.userName" required input-align="right" label="姓名" placeholder="填写姓名" />
        <van-field
          v-model="form.userMobile"
          required
          input-align="right"
          label="联系方式"
          placeholder="填写手机号"
        />
      </div>
    </div>
    <div class="card order-wrapper">
      <div class="order-item">
        <div class="name">课程金额</div>
        <div class="price-wrapper">
          <span class="symbol">¥</span>
          <span class="price">{{ parseInt(info.price / 100, 10) }}</span>
          <span class="decimal">.{{ formatNumber(parseInt(info.price, 10) % 100) }}</span>
        </div>
      </div>
      <div class="order-item">
        <div class="name">支付方式</div>
        <div class="channel">微信支付</div>
      </div>
    </div>
    <div class="button-wrapper">
      <van-button style="margin-bottom: 20px" block round @click="handleQueryOrder">
        已购买，查询订单
      </van-button>
      <van-button :loading="paying" color="#19b157" type="primary" block round @click="handlePay">
        确认支付
      </van-button>
    </div>
    <van-dialog
      v-model:show="show"
      title="使用微信扫描二维码支付"
      show-confirm-button
      show-cancel-button
      cancel-button-text="未支付"
      confirm-button-text="已支付"
      @opened="handleQRCodeDialogOpen"
      @confirm="paySuccess"
    >
      <div class="qrcode-wrapper">
        <canvas id="qrcode"></canvas>
      </div>
    </van-dialog>
    <van-dialog
      v-model:show="formOpen"
      title="查询订单"
      confirm-button-text="查询"
      show-cancel-button
      :before-close="beforeFormDialogClose"
      @confirm="queryOrder"
    >
      <van-form ref="queryFormRef" class="form-dialog" label-width="4.2em">
        <van-field label="手机号" name="mobile" placeholder="请输入手机号" v-model="queryOrderForm.mobile" />
        <van-field
          label="验证码"
          name="verifyCode"
          placeholder="请输入短信验证码"
          v-model="queryOrderForm.verifyCode"
        >
          <template #button>
            <van-button
              style="width: 200px"
              size="small"
              type="primary"
              color="#19b157"
              :disabled="interval < 60"
              @click="sendCode"
            >
              {{ interval !== 60 ? `${interval}s后重新发送` : "发送验证码" }}
            </van-button>
          </template>
        </van-field>
      </van-form>
    </van-dialog>
  </div>
</template>

<script>
import { Notify } from "vant";
import { createOrder, getTrainingCampGoodsDetail, queryOrderByMobile } from "@/assets/api/api_shop";
import { sendMsgCode } from "@/assets/api/api_auth";
import QRCode from "qrcode";
import pattern from "@/utils/pattern";
import { formatNumber } from "@/utils/formatter";
import { AppId } from "@/assets/utils/utils";
export default {
  name: "TrainingCampCreateOrder",
  components: {},
  data() {
    return {
      // 用户微信授权code
      userCode: null,
      // 商品Id
      goodsId: 1,
      // 提交的表单
      form: {},
      // 商品详情
      info: {},
      // 二维码弹窗是否显示
      show: false,
      // 二维码url
      qrcodeUrl: "",
      // 订单编号
      orderTradeNo: "",
      // 支付中
      paying: false,
      // 显示form表单弹窗
      formOpen: false,
      // 查询订单表单
      queryOrderForm: {},
      // 正在查询订单
      querying: false,

      // 定时器
      timer: null,
      // 间隔
      interval: 60,
      // 正在发送验证码
      sending: false,
    };
  },
  watch: {},
  created() {},
  mounted() {
    this.anth();
    this.getDetail();
  },
  beforeCreate() {},
  beforeMount() {},
  beforeUpdate() {},
  updated() {},
  activated() {},
  methods: {
    formatNumber,
    // 查询订单按钮点击
    handleQueryOrder() {
      this.queryOrderForm = {};
      this.formOpen = true;
    },
    // 支付按钮点击
    async handlePay() {
      const params = { goodsId: this.goodsId, ...this.form };
      if (!params.userName) {
        Notify({ type: "danger", message: "请填写姓名" });
        return;
      }
      if (!params.userMobile) {
        Notify({ type: "danger", message: "请填写手机号" });
        return;
      }
      if (!pattern.mobile.test(params.userMobile)) {
        Notify({ type: "danger", message: "请填写正确的手机号" });
        return;
      }
      try {
        this.paying = true;
        const res = await createOrder(params);
        this.paying = false;
        if (res.errcode !== 0) {
          Notify({ type: "danger", message: res.errmsg });
          return;
        }
        this.qrcodeUrl = res.data.code_url;
        this.orderTradeNo = res.data.orderTradeNo;
        this.show = true;
      } catch (error) {
        this.paying = false;
        Notify({ type: "danger", message: JSON.stringify(error) });
      }
    },

    // 关闭前
    beforeFormDialogClose(action) {
      return action === "cancel";
    },

    // 获取商品详情
    async getDetail() {
      const params = { id: this.goodsId };
      const res = await getTrainingCampGoodsDetail(params);
      if (res.errcode !== 0) {
        Notify({ type: "danger", message: res.errmsg });
        return;
      }
      this.info = res.data;
    },
    // 生成二维码
    handleQRCodeDialogOpen() {
      const options = { width: 400 };
      QRCode.toCanvas(document.querySelector("#qrcode"), this.qrcodeUrl, options, (error) => {
        if (error) {
          console.log(error);
          Notify({ type: "danger", message: "支付二维码生成失败" });
          return;
        }
      });
    },
    // 支付成功
    paySuccess() {
      this.$router.push({
        path: "/shop/training-camp/order-success",
        query: { orderTradeNo: this.orderTradeNo },
      });
    },
    // 查询订单
    async queryOrder() {
      const params = { ...this.queryOrderForm };
      if (!params.mobile) {
        Notify({ type: "danger", message: "请输入手机号" });
        return;
      }
      if (!pattern.mobile.test(this.queryOrderForm.mobile)) {
        Notify({ type: "danger", message: "请填写正确的手机号" });
        return;
      }
      if (!params.verifyCode) {
        Notify({ type: "danger", message: "请输入验证码" });
        return;
      }
      try {
        this.querying = true;
        const res = await queryOrderByMobile(params);
        this.querying = false;
        if (res.errcode !== 0) {
          Notify({ type: "danger", message: res.errmsg });
          return;
        }
        const orderTradeNo = res.data.orderTradeNo;
        if (orderTradeNo) {
          this.orderTradeNo = orderTradeNo;
          this.paySuccess();
        }
      } catch (error) {
        this.querying = false;
        Notify({ type: "danger", message: JSON.stringify(error) });
      }
    },
    // 发送验证码
    async sendCode() {
      const params = { mobile: this.queryOrderForm.mobile };
      if (!params.mobile) {
        Notify({ type: "danger", message: "请输入手机号" });
        return;
      }
      if (!pattern.mobile.test(this.queryOrderForm.mobile)) {
        Notify({ type: "danger", message: "请填写正确的手机号" });
        return;
      }
      try {
        this.sending = true;
        const res = await sendMsgCode(params);
        this.sending = false;
        if (res.errcode !== 0) {
          Notify({ type: "danger", message: res.errmsg });
          return;
        }
        this.countdown();
      } catch (error) {
        this.sending = false;
        Notify({ type: "danger", message: JSON.stringify(error) });
      }
    },
    // 倒计时
    countdown() {
      setTimeout(() => {
        if (this.interval === 1) {
          this.interval = 60;
          return;
        }
        this.interval -= 1;
        this.countdown();
      }, 1000);
    },
    // 微信授权
    anth() {
      const REDIRECT_URI = encodeURIComponent("https://xst.olomobi.com/#/shop/training-camp/createOrder");
      const SCOPE = "snsapi_base";
      window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${AppId}&redirect_uri=${REDIRECT_URI}&response_type=code&scope=${SCOPE}#wechat_redirect`;
    },
  },
};
</script>
<style lang='less' scoped>
.create-order-container {
  --theme-color: #19b157;
  box-sizing: border-box;
  background-color: #99999910;
  width: 100vw;
  height: 100vh;
  padding: 32px;
  font-size: 24px;
  .card {
    background-color: white;
    padding: 24px;
    border-radius: 12px;
  }
  .course-wrapper {
    .type {
      color: #a6a6a6;
    }
    .course-info {
      margin-top: 16px;
      display: flex;
      img {
        width: 200px;
        height: 200px;
        background-color: #989898;
        border-radius: 4px;
      }
      .course-name {
        flex: 1;
        margin-left: 30px;
        color: black;
        font-size: 28px;
        line-height: 1.5;
        font-weight: bold;
      }
    }
    .tip-item {
      margin-top: 36px;
      display: flex;
      justify-content: space-between;
      .name {
        color: #a6a6a6;
      }
      .tip {
        color: black;
      }
    }
  }
  .form-wrapper {
    margin-top: 24px;
    .form-item {
      margin-bottom: 32px;
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
  .order-wrapper {
    margin-top: 24px;
    padding: 40px 24px;
    .order-item {
      margin-bottom: 40px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      line-height: 40px;
      color: #a6a6a6;
      &:last-of-type {
        margin-bottom: 0;
      }
      .price-wrapper {
        display: flex;
        flex-direction: row;
        align-items: baseline;
        .symbol {
          color: var(--theme-color);
          font-size: 24px;
          font-weight: bold;
        }
        .price {
          margin-left: 10px;
          color: var(--theme-color);
          font-size: 40px;
          font-weight: bold;
          font-family: "Avenir-Heavy, PingFang SC, Helvetica Neue, Arial, sans-serif";
        }
        .decimal {
          color: var(--theme-color);
          font-size: 26px;
          font-weight: bold;
          font-family: "Avenir-Heavy, PingFang SC, Helvetica Neue, Arial, sans-serif";
        }
      }
      .channel {
        color: black;
      }
    }
  }
  .button-wrapper {
    position: fixed;
    bottom: 20px;
    left: 100px;
    right: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
</style>

<style lang="less">
.create-order-container {
  .form-item {
    input:focus {
      border: none;
    }
  }
  .qrcode-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .form-dialog {
    padding: 0 32px;
  }
}
</style>