<template>
    <div class="trends-detail-container">
        <div class="card">
            <div class="title-wrapper">
                <h1 class="title">{{ info.title }}</h1>
            </div>
            <div class="content" v-html="info.properties" />
        </div>
    </div>
</template>

<script>
import { getReadStoryTrendsDetail } from "@/assets/api/api_activity"

export default {
    name: "RSTrendsDetail",
    components: {},
    data() {
        return {
            id: this.$route.query.id,
            info: {},
        }
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {
        this.getInfo()
    },
    methods: {
        async getInfo() {
            const res = await getReadStoryTrendsDetail(this.id)
            this.info = res.data
        },
    },
}
</script>
<style lang="less" scoped>
.trends-detail-container {
    margin-top: -4px;
    background-color: #e41926;
    padding: 20px;

    .card {
        background-color: white;
        border-radius: 10px;
        padding: 20px;
        padding-top: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .title-wrapper {
        margin-top: 40px;

        .title {
            font-weight: bold;
            font-size: 32px;
            color: #333333;
            line-height: 44px;
        }
    }

    .content {
        margin-top: 16px;
        width: 100%;
        font-size: 28px;
        line-height: 44px;
        color: #333333;
    }
}
</style>
