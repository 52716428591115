<template>
  <div style="position: relative; width: 750px">
    <div class="container">
      <img
        class="header"
        src="https://educator.oss-cn-hangzhou.aliyuncs.com/static/share-top-banner.png"
        @click="downloadApk"
      />
      <dynamic-item :item="reply" />
      <div style="height: 200px"></div>
      <!--<img class="white-cover" src="https://educator.oss-cn-hangzhou.aliyuncs.com/static/share-white-cover.png"/>-->
      <!--<img class="share-more"-->
      <!--src="https://educator.oss-cn-hangzhou.aliyuncs.com/static/share-more.png"-->
      <!--@click="downloadApk" />-->

      <div class="bottom-open-app" @click="downloadApk">打开光明教育家APP参与话题讨论</div>

      <div class="shadow" v-if="showShadow" @click="showShadow = false">
        <img class="hint" src="https://educator.oss-cn-hangzhou.aliyuncs.com/static/open-browser.png" />
      </div>
      <fixed-bottom @download="downloadApk" />
    </div>
  </div>
</template>

<script>
import api from "../../assets/api/api_share";
import { getPicList, jump, timePeriod } from "../../utils/render";
import DynamicItem from "@/components/share/dynamicItem";
import { AuthMixin } from "@/assets/utils/mixin";
import FixedBottom from "@/components/share/fixedBottom";

/**
 * 帖子分享
 * @since 4.0
 */
export default {
  mixins: [AuthMixin],
  name: "ShareDynamic",
  components: { DynamicItem, FixedBottom },
  data() {
    return {
      itemId: null,
      reply: {
        User: {},
        properties: {},
        Circle: {},
      },
      showShadow: false,
    };
  },
  methods: {
    downloadApk() {
      let param = {
        dynamicId: this.dynamicId,
      };
      jump(this, "DynamicDetail", param, () => {
        this.showShadow = true;
      });
    },
  },
  mounted() {
    this.dynamicId = this.$route.query.dynamicId;
    api.getShareDynamicInfo({ dynamicId: this.dynamicId }).then((res) => {
      if (res.errcode === 0) {
        let result = res.data;
        if (typeof result.properties === "string") {
          result.properties = JSON.parse(result.properties);
        }
        result.time = timePeriod(result.createTime);
        if (typeof result.picList === "string") {
          result.picList = JSON.parse(result.picList);
        }
        if (typeof result.videoUrl === "string") {
          result.videoUrl = JSON.parse(result.videoUrl);
        }
        let desc = "";
        // let user = result.User || {}
        // if (user.School) {
        //   desc = user.School.name
        // } else if (!user.schoolId) {
        //   desc = user.isPersonalAuth
        //     ? '入驻用户'
        //     : user.vipLevel
        //       ? '年费会员'
        //       : '普通用户';
        // }
        result.desc = desc;
        result.picList = getPicList(result.picList);
        this.reply = result;
        this.initWxConfig({
          itemName: result.title || "",
          pic: "https://educator.oss-cn-hangzhou.aliyuncs.com/static/app-icon.png",
          link: `share-dynamic?dynamicId=${this.dynamicId}`,
        });
        document.title = "光明教育家";
      }
    });
  },
};
</script>

<style scoped>
.container {
  padding-bottom: 120px;
  width: 750px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  position: relative;
}

.bottom-open-app {
  background: #d8201e;
  width: 575px;
  height: 84px;
  line-height: 84px;
  text-align: center;
  color: white;
  font-size: 28px;
  border-radius: 42px;
  position: absolute;
  left: 88px;
  bottom: 180px;
}

.shadow {
  position: fixed;
  z-index: 99999;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
}

.hint {
  width: 378px;
  height: 236px;
  position: fixed;
  z-index: 999;
  right: 20px;
  top: 0;
}

.header {
  width: 750px;
  height: 80px;
  border-bottom: 1px solid #f2f2f2;
}

.white-cover {
  width: 750px;
  height: 400px;
  position: absolute;
  left: 0;
  bottom: 0;
}

.share-more {
  width: 50px;
  height: 50px;
  position: absolute;
  bottom: 200px;
  left: 350px;
}
</style>
<style>
img {
  width: 100%;
}
</style>
