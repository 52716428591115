<template>
    <div class="ql-editor" v-if="html" v-html="html">
    </div>
    <div v-else>
        {{ item }}
    </div>
</template>
<script>
export default {
  name: "rich-content",
  props: ["item","html"]
};
</script>

<style>
@import '../../assets/css/quill.css';
.ql-editor .ql-align-center strong {
    font-weight: bold;
}
.rich-text {
    font-family:none;
}
</style>
  
  