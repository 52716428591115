<template>
  <div class="album-intro" v-if="showMe">
    <div class="shadow to-show" @click.stop="hide" @touchmove.prevent></div>
    <div class="content-container bottom-in">
      <div class="title-wrapper">
        <div class="title">{{title}}</div>
      </div>
      <div id="album-intro" class="mescroll">
        <div class="content-list" v-if="intro">
          <div class="content" v-html="intro"></div>
          <div class="footer">
            <div style="width: 80px; height: 1px; background-color: #999999"></div>
            <div style="color: #999999; font-size: 24px; margin: 0 30px">以上内容来自喜马拉雅APP</div>
            <div style="width: 80px; height: 1px; background-color: #999999"></div>
          </div>
        </div>
      </div>
      <div class="close" @click.stop="hide">关闭</div>
    </div>
  </div>
</template>

<script>

  export default {
    name: 'albumIntro',
    props: ['title', 'intro'],
    data () {
      return {
        showMe: false
      }
    },
    methods: {
      hide() {
        this.showMe = false
      },
      show() {
        this.showMe = true
      }

    },
    mounted() {

    }
  }
</script>

<style scoped>
  .album-intro{
    position: fixed;
    top: 0;
    left: 0;
    width: 750px;
    height: 100vh;
    z-index: 999;
  }

  .shadow {
    position: fixed;
    top: 0;
    left: 0;
    width: 750px;
    height: 100vh;
    background-color: rgba(0,0,0,0.3);
  }

  .content-container {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 750px;
    height: 880px;
    background-color: white;
  }

  .mescroll{
    width: 750px;
    height: 665px;
    background-color: #f5f5f5;
  }

  .content-list {
    width: 750px;
    display: flex;
    flex-direction: column;
    align-items: center;
    line-height: 40px;
  }

  .title-wrapper {
    width: 750px;
    height: 117px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .content {
    width: 690px;
    box-sizing: border-box;
    padding: 30px 0;
    font-size: 28px !important;
    line-height: 40px !important;
    color: #333333;
  }

  .title {
    width: 690px;
    font-size: 33px;
    color: #333333;
    text-align: center;
  }

  .close {
    width: 750px;
    height: 98px;
    line-height: 98px;
    font-size: 30px;
    color: #333333;
    text-align: center;
  }

  @keyframes bottom-in {
    0% {
      transform: translate(0, 100%);
    }
    100% {
      transform: translate(0, 0);
    }
  }

  .bottom-in {
    animation: bottom-in 0.2s;
  }

  @keyframes to-show {
    0% {opacity: 0;}
    100% {opacity: 1;}
  }


  .to-show {
    animation: to-show .5s;
    animation-fill-mode: both !important;
    -webkit-animation-fill-mode: both !important;
  }

  .footer {
    width: 750px;
    height: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
</style>
