<template>
  <div class="container" v-if="detail">
    <img
      style="width: 750px; height: 249px"
      src="https://educator.oss-cn-hangzhou.aliyuncs.com/hxzvote/af3e832b-41c0-45c2-92d2-55e39723efa5.png"
    />
    <div class="header" @click="toHome">
      <img style="width: 42px; height: 36px" :src="images.home" />
      <div class="home">投票首页</div>
    </div>
    <div class="title">{{ detail.bookName }}</div>
    <img class="divider" :src="images.divider" />
    <img class="cover" :src="detail.pic" />
    <img class="divider" :src="images.divider" />
    <div
      style="
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 60px;
      "
    >
      <div class="action-btn action-btn-center-text" @click.stop="toggleLike()">
        <img
          class="vote"
          :src="detail.isVote === 1 ? images.likeActive : images.like"
        />
        <div
          class="vote-count"
          :style="{ color: detail.isVote ? '#486d43' : 'b1b1b1' }"
        >
          {{
            detail.initVote === detail.isVote
              ? detail.bookCount
              : detail.bookToggleCount
          }}
        </div>
      </div>

      <!-- <div class="action-btn" style="margin-left: 25px" @click="onShare">
        <img
          style="width: 31px; height: 31px; margin-left: 8px"
          :src="images.share"
        />
        <div class="vote-count" style="margin-left: 10px">分享去拉票</div>
      </div> -->
    </div>
  </div>
</template>

<script>
import api_activity from "@/assets/api/api_activity";
import HOST from "@/assets/api";
import { AppId } from "@/assets/utils/utils";
import { AuthMixin } from "@/assets/utils/mixin";

export default {
  mixins: [AuthMixin],
  components: {},
  data() {
    return {
      bookId: null,
      detail: null,
      images: {
        divider:
          "http://educator.oss-cn-hangzhou.aliyuncs.com/static/event/ev1/ev1-divider.png",
        like: "http://educator.oss-cn-hangzhou.aliyuncs.com/static/event/ev1/like.png",
        likeActive:
          "http://educator.oss-cn-hangzhou.aliyuncs.com/static/event/ev1/like-active.png",
        share:
          "http://educator.oss-cn-hangzhou.aliyuncs.com/static/event/ev1/share.png",
        home: "http://educator.oss-cn-hangzhou.aliyuncs.com/static/event/ev1/home.png",
      },
      showHint: false,
      user: null,
    };
  },
  created() {
    this.bookId = this.$route.query.bookId;
    this.getData();
  },
  methods: {
    async getData() {
      const bookId = this.bookId;
      const params = { bookId };
      const res = await api_activity.getBookById(params);
      if (res.errcode === 2) {
        this.authLogin();
        return;
      }
      if (res.errcode !== 0) {
        this.$toast.fail(res.errmsg);
        return;
      }
      const item = res.data;
      if (!item.BookVoteCount) {
        item.BookVoteCount = {};
      }
      if (!Object.keys(item.BookVoteCount).length) {
        item.BookVoteCount = { bookCount: 0 };
      }
      if (!item.BookVote) {
        item.BookVote = {};
      }
      if (!Object.keys(item.BookVote).length) {
        item.BookVote = { isVote: 0 };
      }
      item.isVote = item.BookVote.isVote;

      item.bookToggleCount =
        (item.isVote ? -1 : 1) + item.BookVoteCount.bookCount;
      item.bookCount = item.BookVoteCount.bookCount;
      item.initVote = item.BookVote.isVote;
      item.pic = "http://educator.oss-cn-hangzhou.aliyuncs.com/" + item.pic;

      this.detail = item;
      this.initWxConfig({
        itemName: '“百部新书一起读”投票活动开启',
        pic: item.pic,
        link: `activity/100cb/book-detail?bookId=${item.bookId}`,
        desc: item.bookName
      });
    },
    // 投票
    async toggleLike() {
      const item = this.detail;
      const bookId = item.bookId;
      const params = { bookId };
      let res;
      let message = "";
      if (item.BookVote.isVote === 1) {
        res = await api_activity.cancelVote(params);
        message = "已取消投票";
      } else {
        res = await api_activity.vote(params);
        message = "感谢您的参与";
      }
      if (res.errcode !== 0) {
        this.$toast.fail(res.errmsg);
        return;
      }
      this.$toast.success(message);
      this.getData();
    },
    toHome() {
      this.$router.replace({ name: "ChildrenBook" });
    },
    // 授权登录
    authLogin() {
      const fromId = `&fromId=activity/100cb/book-detail`;
      const applyId = `&applyId=${this.bookId}`;
      let redirectUrl = encodeURIComponent(
        `${HOST}/login/wxLoginV3?${applyId}${fromId}`
      );
      let url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${AppId}&redirect_uri=${redirectUrl}&response_type=code&scope=snsapi_userinfo&state=STATE&connect_redirect=1#wechat_redirect`;
      window.location.href = url;
    },
  },
};
</script>


<style scoped>
.container {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header {
  height: 98px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 28px;
  align-self: flex-start;
}

.home {
  font-size: 26px;
  color: #486d43;
  margin-left: 7px;
  font-weight: bold;
}

.title {
  margin-top: 28px;
  font-size: 30px;
  color: #486d43;
  width: 750px;
  text-align: center;
}

.divider {
  width: 447px;
  height: 35px;
  margin-top: 30px;
}

.cover {
  width: 332px;
  height: 348px;
  background: #f2f2f2;
  margin-top: 42px;
}

.action-btn {
  min-width: 140px;
  padding: 0 20px;
  height: 50px;
  box-sizing: border-box;
  background-color: #ffffff;
  box-shadow: 0 2px 20px 0 rgba(81, 151, 72, 0.2);
  border-radius: 25px;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
}

.action-btn-center-text {
  width: 140px;
}

.action-cover-btn {
  width: 140px;
  height: 50px;
  background: transparent;
  border: none;
  position: absolute;
  left: 0;
  top: 0;
}

.vote-count {
  color: #b1b1b1;
  font-size: 24px;
  flex: 1;
  text-align: center;
}

.vote {
  width: 36px;
  height: 36px;
}

.shadow {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
}
</style>
