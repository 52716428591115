<template>
    <div class='notice-container'>
        <img v-for="i in 64" :key="i"
            :src="`https://educator.oss-cn-hangzhou.aliyuncs.com/red_story/notice_${(i - 1) < 10 ? '0' + (i - 1) : i - 1}.png`"
            alt="">
    </div>
</template>

<script>

export default {
    name: 'RSNotice',
    components: {},
    data() {
        return {

        };
    },
    computed: {},
    watch: {},
    created() { },
    mounted() { },
    methods: {},
}
</script>
<style lang='less' scoped>
.notice-container {
    margin-top: -4px;
    background-color: #e41926;
    padding: 20px;
}
</style>