<template>
    <div class="container">
        <div class="header" @click="downloadApk">
            <img class="logo" src="../../../public/static/images/share/logo.jpg" />
        </div>
        <div class="content">
            <div class="title">{{ detail.itemName }}</div>
            <div class="author-container">
                <img
                    id="avatarUser"
                    webp="webp"
                    style="width: 64px; height: 64px; border-radius: 32px; background: #f2f2f2"
                    :src="detail.face"
                />
                <div style="flex-direction: column; margin-left: 16px; flex: 1">
                    <div id="nickName" style="font-size: 26px; color: #313332">{{ detail.nickName }}</div>
                    <div style="margin-top: 10px; display: flex; flex-direction: row; align-items: center">
                        <div id="time" style="font-size: 26px; color: #b1b1b1">{{ detail.time }}</div>
                        <img
                            src="../../../public/static/images/view-count.png"
                            style="width: 32px; height: 32px; margin-left: 16px"
                            alt="浏览量"
                        />
                        <div id="viewCount" style="font-size: 26px; color: #b1b1b1">{{ detail.viewCount }}</div>
                    </div>
                </div>
            </div>
            <video
                style="width: 690px; height: 388px; margin-bottom: 30px"
                v-if="detail.videoLink"
                :src="detail.videoLink"
                :poster="detail.videoPic"
                controls
            ></video>
            <div class="rich-text" v-html="detail.content" @click="imageChange($event)"></div>
            <div v-if="isStory" class="thumb-container">
                <div class="thumb-box" @click="assistanceTap">
                    <img
                        v-if="assistCount > 0"
                        class="thumb-icon"
                        src="../../../public/static/images/have-thumb.png"
                        alt=""
                    />
                    <img v-else class="thumb-icon" src="../../../public/static/images/thumb.png" alt="" />
                </div>
                <span class="thumb-text">喜欢TA的事迹{{ assistCountDesc }}</span>
            </div>

            <div class="bottom-open-app" @click="downloadApk">打开光明教育家APP浏览更多内容</div>
        </div>
        <div class="shadow" v-if="showShadow" @click="showShadow = false">
            <img class="hint" src="http://educator.oss-cn-hangzhou.aliyuncs.com/static/open-browser.png" />
        </div>
        <hint-box
            content="是否打开光明教育家APP阅读原文?"
            ref="hb"
            @onCancel="overflowHidden = false"
            @onConfirm="downloadApk"
        />

        <recommend-article v-if="item && item.User" :user-id="item.User.userId" :item-id="item.itemId" />
        <fixed-bottom @download="downloadApk" />
    </div>
</template>

<script>
import { DEFAULT_AVATAR, formatDate, getFullPicUrl, jump, tagAddStyle } from "../../utils/render"
import api from "../../assets/api/api_share"
import HintBox from "../../components/hintBox"
import { AuthMixin } from "../../assets/utils/mixin"
import { ImagePreview } from "vant"
import RecommendArticle from "@/components/share/recommendArticle"
import FixedBottom from "@/components/share/fixedBottom"
import HOST from "../../assets/api"
import { AppId } from "../../assets/utils/utils"
import apiActivity from "../../assets/api/api_activity"
import { Notify } from "vant"
export default {
    mixins: [AuthMixin],
    components: { HintBox, RecommendArticle, FixedBottom },
    name: "ShareArticle",

    data() {
        return {
            itemId: null,
            detail: {},
            showShadow: false,
            overflowHidden: true,
            item: null,
            assistCount: 0,
        }
    },
    watch: {
        $route: function (a, b) {
            if (a.query.id) {
                this.tokenId = a.query.id
            }
            if (a.query.ietmId !== b.query.itemId) {
                this.getItemDetail(a.query.itemId)
            }
        },
    },
    computed: {
        // 是否是园长事迹
        isStory() {
            return this.detail.isVote
        },
        // 助力次数描述
        assistCountDesc() {
            return this.assistCount > 0 ? ` +${this.assistCount}` : ""
        },
    },
    methods: {
        showHintBox() {
            this.$refs.hb.show()
        },
        downloadApk() {
            let param = {
                itemId: this.itemId,
            }
            let item = this.detail
            let page = ""
            if (item.itemTypeId === 1) {
                page = "NewsDetail"
            }
            if (item.itemTypeId === 2) {
                page = "NewsDetail"
            } else if (item.itemTypeId === 3) {
                page = "CourseDetail"
            } else if (item.itemTypeId === 6) {
                page = "MagazineDetail"
            }
            if (!page) {
                return
            }
            jump(this, page, param, () => {
                this.showShadow = true
            })
        },
        imageChange(e) {
            if (e.target.nodeName === "IMG") {
                ImagePreview({
                    images: [e.target.currentSrc],
                    showIndex: false,
                })
            }
        },
        // 格式化阅读量
        formatViewCount(item) {
            let viewCount = item.ItemCount ? item.ItemCount.viewCount : 0
            if (viewCount < 10000) {
                return viewCount
            }
            if (viewCount >= 100000000) {
                return (viewCount / 100000000).toFixed(1) + "亿"
            }

            return (viewCount / 10000).toFixed(1) + "万"
        },
        // 获取文章详情
        getItemDetail(itemId) {
            api.getItem({ itemId }).then((res) => {
                let item = res.data
                this.item = item
                let itemName = item.itemName
                let face = item.User.School?.pic
                    ? getFullPicUrl(item.User.School?.pic)
                    : item.User.face
                    ? item.User.face
                    : DEFAULT_AVATAR
                let nickName = item.User.School ? item.User.School.name : item.User.nickName
                let time = formatDate(new Date(item.createTime))
                if (res.data.properties) {
                    res.data.properties = JSON.parse(res.data.properties)
                }
                if (item.properties.picList) {
                    item.properties.picList = JSON.parse(item.properties.picList)
                }
                let content = tagAddStyle(res.data.properties.content)
                let reg = /(font-size\s*:)(\s*\d+\.?\d*)(px|pt)(\s*;?\s*)/g
                content = content.replace(reg, (matched, capture1, capture2, capture3, capture4) => {
                    return capture1 + capture2 * 1.8 + capture3 + capture4
                })
                let videoPic = null
                let videoLink = null
                if (item.itemTypeId === 2) {
                    videoPic = getFullPicUrl(res.data.properties.pic)
                    videoLink = getFullPicUrl(res.data.properties.link)
                }
                const viewCount = this.formatViewCount(item)
                this.detail = {
                    itemName,
                    face,
                    nickName,
                    time,
                    content,
                    videoPic,
                    videoLink,
                    itemTypeId: item.itemTypeId,
                    viewCount,
                    isVote: item.isVote,
                }
                document.title = res.data.itemName

                this.initWxConfig({
                    itemName: res.data.itemName,
                    pic: "https://educator.oss-cn-hangzhou.aliyuncs.com/static/app-icon.png",
                    link: `share-article?itemId=${item.itemId}`,
                })
            })
        },
        // 获取我对该园长的投票数据
        getVoteCount() {
            const params = { itemId: this.itemId, tokenId: this.tokenId }
            apiActivity.getPrincipalItem(params).then((res) => {
                if (res.errcode !== 0) {
                    return
                }
                this.assistCount = res.data.likeCount
            })
        },
        // 助力点击
        assistanceTap() {
            const params = { itemId: this.itemId, tokenId: this.tokenId }
            apiActivity.assistance(params).then((res) => {
                if (res.errcode === 2) {
                    Notify({ type: "danger", message: res.errmsg })
                    setTimeout(() => {
                        this.authLogin()
                    }, 1000)
                    return
                }
                if (res.errcode !== 0) {
                    Notify({ type: "danger", message: res.errmsg })
                    return
                }
                this.getVoteCount()
            })
        },
        // 授权登录
        authLogin() {
            const fromId = `&fromId=share-article`
            const applyId = `&applyId=${this.itemId}`
            let redirectUrl = encodeURIComponent(`${HOST}/login/wxLoginV3?${applyId}${fromId}`)
            let url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${AppId}&redirect_uri=${redirectUrl}&response_type=code&scope=snsapi_userinfo&state=STATE&connect_redirect=1#wechat_redirect`
            window.location.href = url
        },
        getCookie(name) {
            var arr,
                reg = new RegExp("(^| )" + name + "=([^;]*)(;|$)")
            if (arr === document.cookie.match(reg)) return unescape(arr[2])
            else return null
        },
    },
    mounted() {
        if (this.$route.query.id) {
            this.tokenId = this.$route.query.id
        }
        this.itemId = this.$route.query.itemId
        this.getItemDetail(this.itemId)
    },
}
</script>

<style scoped>
@import "../../fonts/fonts.css";
.container {
    width: 750px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 120px;
    font-family: "FZYaSong";
}

.header {
    width: 750px;
    height: 80px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    display: flex;
    justify-content: center;
    background-color: white;
}

.header .logo {
    height: 80px;
    object-fit: contain;
}

.rich-text {
    width: 690px;
    overflow: hidden;
    padding-bottom: 40px;
    line-height: 60px;
    letter-spacing: 2px;
}

.app-icon {
    width: 58px;
    height: 58px;
    border-radius: 29px;
    background: #f2f2f2;
}

.open-app {
    background: #d8201e;
    width: 120px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    color: white;
    font-size: 20px;
    border-radius: 20px;
}

.content {
    width: 690px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    margin-top: 80px;
}

.title {
    width: 690px;
    font-size: 40px;
    line-height: 54px;
    color: #313332;
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: center;
    font-weight: bold;
}

.divider {
    width: 690px;
    height: 2px;
    background: #f2f2f2;
}

.author-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 125px;
}

.follow {
    background: #d8201e;
    width: 105px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    color: white;
    font-size: 22px;
    border-radius: 20px;
}

.bottom-open-app {
    background: #d8201e;
    width: 575px;
    height: 84px;
    line-height: 84px;
    text-align: center;
    color: white;
    font-size: 28px;
    border-radius: 42px;
    align-self: center;
}

.white-cover {
    width: 750px;
    height: 400px;
    position: absolute;
    left: 0;
    bottom: 0;
}

.shadow {
    position: fixed;
    z-index: 99999;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.3);
}

.hint {
    width: 378px;
    height: 236px;
    position: fixed;
    z-index: 999;
    right: 20px;
    top: 0;
}

.share-more {
    width: 50px;
    height: 50px;
    position: absolute;
    bottom: 200px;
    left: 320px;
}

.thumb-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.thumb-box {
    width: 100px;
    height: 100px;
    border-radius: 50px;
    background: #f6f6f6;
    display: flex;
    justify-content: center;
    align-items: center;
}
.thumb-icon {
    width: 50px;
    height: 50px;
}
.thumb-text {
    color: #b1b1b1;
    font-size: 24px;
    line-height: 80px;
}
</style>
