<template>
  <div class="comment-item">
    <img class="avatar" :src="item.User ? item.User.face : DEFAULT_AVATAR" />
    <div class="content-container">
      <div class="head-container">
        <div class="nickname">{{item.User ? item.User.nickName : '匿名用户'}}</div>
        <img class="like" :src="item.Like[0].isLike ? '/static/images/like-active.png' : '/static/images/like.png'" @click="toggleLike" />
        <div class="like-count">{{item.Like[0].isLike ? item.activeLikeCount : item.normalLikeCount}}</div>
      </div>
      <div class="content">{{item.ItemReport.length || item.isBan || item.isDelete ? '该评论已删除' : item.properties.detail}}</div>
      <div class="time">{{item.time}}</div>
    </div>
  </div>
</template>

<script>
  // eslint-disable-next-line
  import {DEFAULT_AVATAR} from '@/utils/render'
  import * as API from "../assets/api";

  export default {
    props: ['item'],
    data () {
      return {

      }
    },
    methods: {
      toggleLike() {
        let item = this.item
        let params = {replyId: item.replyId, authorId: item.userId}
        let isLike = item.Like[0].isLike
        let path = isLike ? 'unLike' : 'like'
        API.POST(`/user/${path}`, params).then(() => {
          item.Like = [{isLike: !item.Like[0].isLike}]
          this.$forceUpdate()
        })
      }
    },
    mounted () {

    }
  }
</script>

<style scoped>

  .comment-item {
    width: 690px;
    border-bottom: 1px solid #f2f2f2;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .avatar {
    width: 64px;
    height: 64px;
    border-radius: 32px;
    background: #f2f2f2;
    margin-right: 30px;
    margin-top: 36px;
  }

  .content-container {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  .head-container {
    width: 100%;
    height: 88px;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 36px;
  }

  .nickname {
    font-size: 30px;
    color: #313332;
    flex: 1;
    text-align: left;
  }

  .like {
    width: 34px;
    height: 34px;
  }

  .like-count {
    font-size: 24px;
    color: #313332;
    margin-left: 5px;
    margin-top: 2px;
  }

  .content {
    width: 520px;
    font-size: 28px;
    color: #313332;
    text-align: left;
    line-height: 40px;
  }

  .time {
    font-size: 24px;
    color: #999999;
    text-align: left;
    line-height: 50px;
    margin-bottom: 10px;
  }
</style>
