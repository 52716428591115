import * as API from './'

/**
 * 获取训练营商品详情
 * @param {object} params 请求参数
 * @param {number} params.id 商品Id
 * @returns 
 */
export function getTrainingCampGoodsDetail(params) {
    return API.GET('/camp/goods/info', params);
}
/**
 * 创建订单
 * @param {object} params 请求参数
 * @param {number} params.goodsId 商品Id
 * @param {string} params.userMobile 用户手机号
 * @param {string} params.userName 用户名
 * @param {string} params.gardenName 园所名称
 * @param {string} params.clientIp 用户ip地址
 * @returns 
 */
export function createOrder(params) {
    return API.POST('/order/camp/create', params);
}
/**
 * 查询订单详情
 * @param {object} params 请求参数
 * @param {string} params.orderTradeNo 订单号
 * @returns 
 */
export function getOrderInfo(params) {
    return API.GET('/order/camp/query/info', params);
}
/**
 * 根据手机号查询订单
 * @param {object} params 请求参数
 * @param {string} params.mobile 手机号
 * @param {string} params.verifyCode 验证码
 * @returns 
 */
export function queryOrderByMobile(params) {
    return API.POST('/login/inspect/code', params);
}

