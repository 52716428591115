<template>
  <div class="container">
      <img src="../../../../public/static/images/activity/course-collection/page1.png" alt="" class="container-image">
      <div class="container-bottom">
          <div data-clipboard-text="https://gm-mgr.olomobi.com/" class="container-bottom-button button1" @click="handleLinkTap">报名链接</div>
          <div class="container-bottom-button button2" @click="handleRuleTap">活动规则</div>
      </div>
  </div>
</template>

<script>
import Clipboard from 'clipboard'
import {Notify} from 'vant'
export default {
    created() {
        const clipboard = new Clipboard('.button1')
        clipboard.on('success', () => {
            Notify({type: 'success', message: '链接复制成功,请在电脑端打开该链接'});
        })
    },
    methods: {
        handleLinkTap() {
        },
        handleRuleTap() {
            this.$router.push('/activity/course-collection/rule')
        }
    }
}
</script>

<style lang="less" scoped>
    .container {
        &-image {
            width: 100%;
            flex: 1;
            padding-bottom: 178px;
        }
        &-bottom {
            background-image: url('../../../../public/static/images/activity/course-collection/bottom.png');
            position: fixed;
            left: 0;
            bottom: 0;
            height: 200px;
            width: 100%;
            display: flex;
            justify-content: space-around;
            align-items: center;
            z-index: 999;
            &-button {
                width: 40vw;
                height: 70px;
                font-size: 24px;
            }
        }
    }
    .button1 {
        color: white;
        background: rgb(79, 94, 119);
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .button2 {
        color: rgb(79, 94, 119);
        display: flex;
        justify-content: center;
        align-items: center;
        border: 3px solid rgb(79, 94, 119);
        box-sizing: border-box;
    }
</style>