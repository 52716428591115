<template>
  <div class="rules-container">
    <div class="title"># 活动规则 #</div>
    <div class="line">
      共品第一缕书香，萃取新文化精华，成就未来型人才。2021“百部新书一起读”候选书单发布（排名不分前后），邀请热爱儿童阅读的你为童书助力，投上宝贵的一票！
    </div>
    <div class="line">&nbsp;</div>
    <div class="line">指导单位：</div>
    <div class="line">
      国家新闻出版署出版业用户行为大数据分析与应用重点实验室
    </div>
    <div class="line">&nbsp;</div>
    <div class="line">发起单位：</div>
    <div class="line">北京师范大学少儿阅读与童书评价研究中心</div>
    <div class="line">光明日报《教育家》杂志</div>
    <div class="line">光明网教育家频道</div>
    <div class="line">&nbsp;</div>
    <div class="line">投票日期：</div>
    <div class="line">2021年12月31日——2022年1月10日</div>
    <div class="line">&nbsp;</div>
    <div class="line">投票规则：</div>
    <div class="line">
      点击“心形”按钮，即可完成投票。每个ID单日最多能为8部童书投票，同一作品单日最多能投1票。
    </div>
    <div class="line">本次活动禁止任何刷票行为，否则将取消参与资格。</div>
  </div>
</template>

<script>
export default {
  name: "ev1TabTwo",
  props: {},
  data() {
    return {};
  },
  methods: {},
};
</script>

<style scoped>
.rules-container {
  flex: 1;
  overflow: scroll;
  width: 750px;
  padding: 30px;
  box-sizing: border-box;
}

.title {
  width: 690px;
  font-size: 36px;
  line-height: 80px;
  font-weight: bold;
  color: #313332;
  text-align: center;
}

.line {
  width: 690px;
  line-height: 44px;
  font-size: 28px;
  color: #313332;
}
</style>
