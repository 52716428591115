<template>
  <div class="container">
    <img
      class="poster"
      src="https://pckapp.oss-cn-hangzhou.aliyuncs.com/app/static/download-jyj.png"
      @click="downloadApk"
    />

    <div class="shadow" v-if="showShadow" @click="showShadow = false">
      <img class="hint" src="http://educator.oss-cn-hangzhou.aliyuncs.com/static/open-browser.png" />
    </div>
  </div>
</template>

<script>
import { jump } from "../../utils/render";
export default {
  name: "EduDownload",

  data() {
    return {
      showShadow: false,
    };
  },
  methods: {
    downloadApk() {
      jump(this, null, null, () => {
        this.showShadow = true;
      });
    },
  },
  mounted() {},
};
</script>

<style scoped>
.container {
  width: 750px;
  height: 100vh;
  flex-direction: column;
  align-items: center;
}

.poster {
  width: 750px;
  height: 100vh;
  object-fit: cover;
}

.shadow {
  position: fixed;
  z-index: 99999;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
}

.hint {
  width: 378px;
  height: 236px;
  position: fixed;
  z-index: 999;
  right: 20px;
  top: 0;
}
</style>
