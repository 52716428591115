<template>
  <div class="container">
    <img class="img" src="../../../../public/static/images/activity/gm-read/gm-read.png" alt="" />
    <div class="form">
      <van-form @submit="submit" v-model="form" label-width="120px">
        <van-field v-model="form.name" label="姓名" placeholder="请输入姓名" :rules="rules.name" />
        <van-field name="radio" label="性别" :rules="rules.gender">
          <template #input>
            <van-radio-group v-model="form.gender" direction="horizontal">
              <van-radio name="男" icon-size="32px">男</van-radio>
              <van-radio name="女" icon-size="32px">女</van-radio>
            </van-radio-group>
          </template>
        </van-field>
        <van-field
          v-model="form.birthday"
          label="出生日期"
          placeholder="请选择出生日期"
          clickable
          readonly
          :rules="rules.birthday"
          @click="datePickerVisible = true"
        />
        <van-field
          v-model="form.mobile"
          label="联系电话"
          placeholder="请输入联系电话"
          :rules="rules.mobile"
        />
        <van-field v-model="form.email" label="电子邮箱" placeholder="请输入电子邮箱" :rules="rules.email" />
        <van-field
          v-model="form.education"
          label="学历"
          placeholder="请选择最高学历"
          clickable
          readonly
          :rules="rules.education"
          @click="pickerVisible = true"
        />
        <van-field
          v-model="form.workUnit"
          label="工作单位"
          placeholder="请输入工作单位"
          :rules="rules.workUnit"
        />
        <van-field v-model="form.post" label="职务" placeholder="请输入职务" :rules="rules.post" />
        <van-field
          v-model="form.address"
          label="邮寄地址"
          placeholder="填写邮寄地址,组委会将统一为您寄送一本书"
          :rules="rules.address"
        />
        <div class="form-item form-border">
          <div class="form-text-normal">近读儿童成长系列</div>
          <van-field
            v-model="form.childRead"
            autosize
            type="textarea"
            rows="1"
            placeholder="请输入近读儿童成长系列"
            :rules="rules.childRead"
          />
        </div>
        <div class="form-item form-border">
          <div class="form-text-normal">近读教师成长系列</div>
          <van-field
            v-model="form.teacherRead"
            autosize
            type="textarea"
            rows="1"
            placeholder="请输入近读教师成长系列"
            :rules="rules.teacherRead"
          />
        </div>
        <div class="form-item form-border" style="margin-bottom: 20px">
          <div class="form-text-normal">报名理由及期望</div>
          <van-field
            v-model="form.reason"
            autosize
            type="textarea"
            rows="1"
            placeholder="请输入报名理由及期望"
            :rules="rules.reason"
          />
        </div>
        <van-button round block type="primary" native-type="submit">提 交</van-button>
      </van-form>
    </div>
    <van-popup v-model:show="datePickerVisible" round position="bottom">
      <van-datetime-picker
        type="year-month"
        title="出生日期"
        :item-height="60"
        :min-date="new Date(1949, 0, 1)"
        :max-date="new Date(2022, 3, 1)"
        :formatter="formatter"
        @cancel="datePickerVisible = false"
        @confirm="onDatePickerConfirm"
      />
    </van-popup>
    <van-popup v-model:show="pickerVisible" round position="bottom">
      <van-picker
        show-toolbar
        title="学历"
        :item-height="60"
        :columns="['高中', '中专', '大专', '本科', '研究生（硕士、博士）']"
        @cancel="pickerVisible = false"
        @confirm="onPickerConfirm"
      />
    </van-popup>
  </div>
</template>

<script>
import apiActivity from "@/assets/api/api_activity";
import { Notify } from "vant";
export default {
  data() {
    return {
      form: {},
      rules: {
        name: [{ required: true, message: "姓名不能为空" }],
        gender: [{ required: true, message: "请选择性别" }],
        birthday: [{ required: true, message: "请选择出生日期" }],
        mobile: [
          {
            pattern: /^1(3\d|4[5-9]|5[0-35-9]|6[567]|7[0-8]|8\d|9[0-35-9])\d{8}$/,
            message: "手机号格式错误",
          },
        ],
        email: [
          {
            pattern: new RegExp("^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\\.[a-zA-Z0-9_-]+)+$"),
            message: "电子邮箱格式错误",
          },
        ],
        education: [{ required: true, message: "请选择最高学历" }],
        workUnit: [{ required: true, message: "工作单位不能为空" }],
        post: [{ required: true, message: "职务不能为空" }],
        address: [{ required: true, message: "邮寄地址不能为空" }],
        childRead: [{ required: true, message: "近读儿童成长系列不能为空" }],
        teacherRead: [{ required: true, message: "近读教师成长系列不能为空" }],
        reason: [{ required: true, message: "报名理由及期望不能为空" }],
      },
      datePickerVisible: false,
      pickerVisible: false,
    };
  },
  methods: {
    async submit() {
      const res = await apiActivity.submitGMReadForm(this.form);
      if (res.errcode !== 0) {
        Notify({ type: "danger", message: res.errmsg });
        return;
      }
      this.$router.push("/activity/dgls/success");
    },
    onDatePickerConfirm(date) {
      this.form.birthday = `${date.getFullYear()}年${date.getMonth() + 1}月`;
      this.datePickerVisible = false;
    },
    onPickerConfirm(selected) {
      this.form.education = selected;
      this.pickerVisible = false;
    },

    // 年月格式化
    formatter(type, val) {
      if (type === "year") {
        return `${val}年`;
      } else if (type === "month") {
        return `${val}月`;
      }
      return val;
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  .img {
    width: 100%;
  }
  .form {
    padding: 24px;
  }
}
.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  &-p {
    padding: 14px 0;
  }
  &-between {
    justify-content: space-between;
  }
}
.form-border {
  border-bottom: 1px solid #ebedf0;
}
.form-text-normal {
  font-size: 28px;
  color: #646566;
}
.form-item {
  padding-top: 20px;
}
.form-label {
  font-size: 28px;
  font-weight: bold;
  line-height: 30px;
  padding: 20px 0px;
}
</style>