<template>
    <div class="player-progress-bar"
         @touchstart.stop="onTouchStart"
         @touchmove.stop="onTouchMove"
         @touchend.stop="onTouchEnd">
      <div class="bar-bg"></div>
      <div class="bar-active" :style="{width: (positionX - 75) + 'px'}"></div>
      <div class="bar-button" :style="{left: (positionX - 75) + 'px'}">{{nowDuration}}/{{totalDuration}}</div>
    </div>
</template>

<script>
  import {formatSeconds} from "../utils/render";

  export default {
    name: "player-progress-bar",
    computed: {
      nowDuration() {
        return formatSeconds(this.now)
      },
      totalDuration() {
        return formatSeconds(this.total)
      }
    },
    data() {
      return {
        minPosition: 75,
        maxPosition: 540 - 75,
        clientDiff: -105,
        positionX: 75, // x偏移量
        startX: 0,
        startY: 0,
        lastX: -1,
        lastY: -1,
        enableUpdateDuration: true,
        total: 0,
        now: 0
      }
    },
    methods: {
      updateDuration(time) {
        if (!this.enableUpdateDuration) {
          return
        }
        this.now = Math.floor(time)
        this.positionX = Math.floor((this.now / this.total) * (this.maxPosition - this.minPosition) + this.minPosition)
      },
      resetDuration(total) {
        this.total = total
        this.positionX = this.minPosition
      },
      onTouchStart(e) {
        this.startX = e.changedTouches[0].clientX
        this.startY = e.changedTouches[0].clientY
        this.lastX = -1
        this.lastY = -1
        let nextPosition = this.startX + this.clientDiff
        if (nextPosition > this.maxPosition) {
          nextPosition = this.maxPosition
        }
        if (nextPosition < 75) {
          nextPosition = 75
        }
        this.positionX = nextPosition
        this.enableUpdateDuration = false
      },
      onTouchMove(e) {
        let currentX = e.changedTouches[0].clientX
        let nextPosition = currentX + this.clientDiff
        if (nextPosition > this.maxPosition) {
          nextPosition = this.maxPosition
        }
        if (nextPosition < 75) {
          nextPosition = 75
        }
        this.positionX = nextPosition
        this.lastX = e.changedTouches[0].clientX
        this.lastY = e.changedTouches[0].clientY
      },
      onTouchEnd() {
        this.onSeek()
        this.enableUpdateDuration = true
      },
      onSeek() {
        let time = Math.floor((this.positionX - this.minPosition) / (this.maxPosition - this.minPosition) * this.total)
        this.$emit('onSeek', time)
      }
    }
  }
</script>

<style scoped>

  .player-progress-bar {
    width: 540px;
    height: 42px;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .bar-bg {
    width: 540px;
    height: 8px;
    border-radius: 4px;
    background-color: #e4e4e4;
  }

  .bar-active {
    width: 0;
    height: 8px;
    border-radius: 4px 0 0 4px;
    background-color: #e71e28;
    position: absolute;
    left: 0;
    top: 17px;
  }

  .bar-button {
    width: 150px;
    height: 42px;
    line-height: 42px;
    background-color: #ffffff;
    border-radius: 21px;
    border: solid 1px #999999;
    font-size: 22px;
    color: #999999;
    text-align: center;
    position: absolute;
    left: 0;
    top: 0;
  }
</style>
