import * as API from "./"

export default {
    getReplyById: (params) => {
        return API.GET("/item/getReplyByIdUnLogin", params)
    },

    getItem: (params) => {
        return API.GET("/item/getItemUnLogin", params)
    },

    getItemList: (params) => {
        return API.GET("/item/getItemUnLogin/list", params)
    },

    getItemByIds: (params) => {
        return API.GET("/item/getItemByIdsunLogin", params)
    },

    getGambitTopReplyHeat: (params) => {
        return API.GET("/item/getItemTopReplyHeatUnLogin", params)
    },

    getShareDynamicInfo: (params) => {
        return API.GET("/item/getShareDynamicInfo", params)
    },

    getGambitInfo: (params) => {
        return API.GET("/gambit/getGambitInfo", params)
    },

    getShareGambitReply: (params) => {
        return API.GET("/gambit/getShareGamibtReply", params)
    },

    getShareCircleInfo: (params) => {
        return API.GET("/item/getShareCircleInfo", params)
    },

    getShareCircleDynamic: (params) => {
        return API.GET("/item/getShareCircleDynamic", params)
    },
    // 获取课程征集课程
    getShareCCCourse: (params) => {
        return API.GET("/eduactive/courseInfo", params)
    },
    // 获取学生作业信息
    getStudentTask: (params) => {
        return API.GET("/camp/works/share/member/works", params)
    },
    // 获取打卡详情
    getStudentClockin: (params) => {
        return API.GET("/camp/course/clock/share/info", params)
    },
    // 获取中小学研修班学员作业详情
    getWorkshopsMemberTask: (params) => {
        return API.GET("/study/works/share/works", params)
    },
    // 获取头条文章列表
    getHeadItemList: () => {
        return API.GET("/item/sharHeadItem")
    },
}
