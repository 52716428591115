export function GetRequest() {
  let url = location.search //获取url中"?"符后的字串
  let theRequest = new Object()
  if (url.indexOf('?') != -1) {
    let str = url.substr(1)
    let strs = str.split('&')
    for (let i = 0; i < strs.length; i++) {
      theRequest[strs[i].split('=')[0]] = unescape(strs[i].split('=')[1])
    }
  }
  console.log(theRequest)
  return theRequest
}

export function getHashParameters() {
  const arr = (location.hash || '').replace(/^#/, '').split('&');
  const params = {};
  for (let i = 0; i < arr.length; i++) {
    let data = '';
    if (arr[i].indexOf('?') !== -1) {
      data = arr[i].split('?')[1].split('=')
    } else {
      data = arr[i].split('=')
    }
    if (data.length === 2) {
      params[data[0]] = data[1]
    }
  }
  return params
}

function formatNumber(n) {
  const str = n.toString()
  return str[1] ? str : `0${str}`
}


export function formatTime(dateTime) {
  if (!dateTime) {
    return ''
  }
  const date = new Date(dateTime * 1000)
  const year = date.getFullYear()
  const month = date.getMonth() + 1
  const day = date.getDate()

  const hour = date.getHours()
  const minute = date.getMinutes()
  const second = date.getSeconds()

  const t1 = [year, month, day].map(formatNumber).join('-')
  const t2 = [hour, minute, second].map(formatNumber).join(':')

  return `${t1} ${t2}`
}

export function formatDateTime(dateTime) {
  if (!dateTime) {
    return ''
  }
  const date = new Date(dateTime * 1000)
  const year = date.getFullYear()
  const month = date.getMonth() + 1
  const day = date.getDate()

  const hour = date.getHours().toString().length == 1 ? '0' + date.getHours() : date.getHours()
  const minute = date.getMinutes().toString().length == 1 ? '0' + date.getMinutes() : date.getMinutes()

  const t1 = [year, month, day].join('/')
  const t2 = [hour, minute].join(':')

  return `${t1} ${t2}`
}

export function formatDate(dateTime) {
  if (!dateTime) {
    return ''
  }
  const date = new Date(dateTime * 1000)
  const year = date.getFullYear()
  const month = date.getMonth() + 1
  const day = date.getDate()
  const t1 = [year, month, day].join('.')
  return `${t1}`
}


export function isWeixin() {
  var ua = navigator.userAgent.toLowerCase() //判断浏览器的类型
  if (ua.match(/MicroMessenger/i) == 'micromessenger') {
    return true
  } else {
    return false
  }
}

export function scrollToButtom() {
  setTimeout(() => {
    const scrollHeight = document.documentElement.scrollTop || document.body.scrollTop || 0
    window.scrollTo(0, Math.max(scrollHeight - 1, 0))
  }, 100)
}


export function isNull(data) {
  return (
    data === '' ||
    data === undefined ||
    data === null ||
    data === 'null' ||
    data === 'undefined'
  )
}


export function checkMobile(mobile) {
  let reg1 = /^(0\d{2}-|\s)?\d{8}$/ // 座机加横线- 3位区号
  let reg2 = /^(0\d{2}|\s)?\d{11}$/ // 座机不加横线- 3位区号
  let reg3 = /^(0\d{3}-|\s)?\d{7}$/ // 座机加横线- 4位区号
  let reg4 = /^(0\d{3}|\s)?\d{11}$/ // 座机不加横线- 4位区号
  let reg5 = /^1[345789]\d{9}$/ // 手机号码
  if (
    reg1.test(mobile) ||
    reg2.test(mobile) ||
    reg3.test(mobile) ||
    reg4.test(mobile) ||
    reg5.test(mobile)
  ) {
    return true
  }
  return false
}

export function checkBirth(birth) {
  let reg = /^\d{4}-((0([1-9]))|(1(0|1|2)))$/
  return reg.test(birth)
}


// export const AppId = 'wxe0164a8557e6b4aa' // 自己

// official
export const AppId = 'wxf102f81f8f4f2e1b'

// test
// export const AppId = 'wx23aaccca023ca6c2'

export const webId = 'aaac9140f96511e98287d3628bb4085a'

export const imageHeader = 'https://weixinfactory.di1game.com'
