import * as API from './'

export default {

  traceReport: params => {
    return API.POST('/item/traceReport', params)
  },

  getAppVersion: params => {
    return API.GET('/version/getAppVersion', params)
  },

  getPCKAppVersion: params => {
    return API.GET_PCK('/version/getAppVersion', params)
  },

  uploadImage: params => {
    return API.UPLOAD('/upload/img', params)
  },
  /**
 * OVD视频上传前准备
 * @param {CateId,FileName} data 
 * @returns 
 */
  createUploadVideo: params => {
    return API.GET('/video/createUploadVideo', params)
  }
}
