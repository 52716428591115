<template>
  <div class="container">
    <div class="title">“现代学校治理研讨会暨第三届‘中国好校长’盛典”活动</div>
    <div class="ipt-container">
      <div class="ipt-desc"><span style="color: red">*</span>姓名</div>
      <input class="ipt" :value="upload.name" @input="upload.name = $event.target.value" />
    </div>
    <div class="ipt-container">
      <div class="ipt-desc"><span style="color: red">*</span>性别</div>
      <input class="ipt" :value="upload.sex" @input="upload.sex = $event.target.value" />
    </div>
    <div class="ipt-container">
      <div class="ipt-desc">随行人员</div>
      <input class="ipt" :value="upload.entourage" @input="upload.entourage = $event.target.value" placeholder="示例格式：李四（女），张三（男），..." />
    </div>
    <div class="ipt-container">
      <div class="ipt-desc"><span style="color: red">*</span>单位名称</div>
      <input class="ipt" :value="upload.companyName" @input="upload.companyName = $event.target.value" />
    </div>
    <div class="ipt-container">
      <div class="ipt-desc"><span style="color: red">*</span>统一社会信用代码（开票税号）</div>
      <input class="ipt" :value="upload.creditCode" @input="upload.creditCode = $event.target.value" />
    </div>
    <div class="ipt-container">
      <div class="ipt-desc"><span style="color: red">*</span>参会人数</div>
      <input class="ipt" :value="upload.participantsNum" @input="upload.participantsNum = $event.target.value" />
    </div>
    <div class="ipt-container">
      <div class="ipt-desc"><span style="color: red">*</span>职务</div>
      <input class="ipt" :value="upload.position" @input="upload.position = $event.target.value" />
    </div>
    <div class="ipt-container">
      <div class="ipt-desc"><span style="color: red">*</span>部门</div>
      <input class="ipt" :value="upload.department" @input="upload.department = $event.target.value" />
    </div>
    <div class="ipt-container">
      <div class="ipt-desc"><span style="color: red">*</span>手机号码</div>
      <input class="ipt" :value="upload.mobile" @input="upload.mobile = $event.target.value" />
    </div>
    <div class="ipt-container">
      <div class="ipt-desc"><span style="color: red">*</span>邮箱</div>
      <input class="ipt" :value="upload.email" @input="upload.email = $event.target.value" />
    </div>
    <div class="ipt-container">
      <div class="ipt-desc"><span style="color: red">*</span>联系地址</div>
      <input class="ipt" :value="upload.contactAdress" @input="upload.contactAdress = $event.target.value" />
    </div>

    <div class="submit" @click="submit">提交</div>
    <toast ref="toast"/>
  </div>
</template>

<script>
  import api from "../../../assets/api/api_vote";
  import Toast from '../../../components/toast'
  export default {
    name: 'CeremonySignUp',
    components: {
      Toast
    },
    data() {
      return {
        upload: {
          name: null,
          sex: null,
          entourage: null,
          companyName: null,
          creditCode: null,
          participantsNum: null,
          position: null,
          department: null,
          mobile: null,
          email: null,
          contactAdress: null,
        }
      }
    },
    methods: {
      submit() {
        if (!this.upload.name
          || !this.upload.sex
          || !this.upload.companyName
          || !this.upload.creditCode
          || !this.upload.participantsNum
          || !this.upload.position
          || !this.upload.department
          || !this.upload.mobile
          || !this.upload.email
          || !this.upload.contactAdress) {
          this.$refs.toast.toast('有必填项未填写')
          return
        }
        api.seminarApply(this.upload).then(res => {
          if (res.errcode === 0) {
            this.$refs.toast.toast('提交成功')
          } else {
            this.$refs.toast.toast(res.errmsg)
          }
        })
      },
      valid(str) {

      }
    },
    mounted() {

    }
  }
</script>

<style scoped>

  .container {
    width: 750px;;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .title {
    width: 590px;
    font-size: 32px;
    line-height: 50px;
    text-align: center;
    margin-top: 30px;
  }

  .ipt-container {
    width: 690px;
    height: 80px;
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .ipt-desc {
    width: 190px;
    font-size: 24px;
    color: #666666;
  }

  .ipt {
    width: 500px;
    height: 80px;
    font-size: 24px;
    color: #666666;
    border: 1px solid #f2f2f2;
    border-radius: 8px;
    padding: 0 30px;
    box-sizing: border-box;
  }

  .submit {
    width: 690px;
    height: 80px;
    border-radius: 8px;
    text-align: center;
    line-height: 80px;
    font-size: 30px;
    color: white;
    background-color: #e71e28;
    margin-top: 60px;
  }
</style>
