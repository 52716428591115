/**
 * 保留n位有效数字
 * @param {string | number} digit 要格式化的数字
 * @param {number} length 要保留的位数
 */
export function formatNumber(digit, length = 2) {
    let number = digit
    if (typeof number !== "string" && typeof number !== "number") {
        return ""
    }
    if (typeof number === "number") {
        number = number + ""
    }
    for (let len = number.length; len < length; len = number.length) {
        number = "0" + number
    }
    return number
}

export function objectToQueryString(obj) {
    const params = []
    for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
            const value = obj[key]
            const param = encodeURIComponent(key) + "=" + encodeURIComponent(value)
            params.push(param)
        }
    }
    return params.join("&")
}
