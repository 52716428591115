<template>
  <div class="container">
      <img src="../../../../public/static/images/activity/course-collection/page2.png" alt="" class="container-image">
  </div>
</template>

<script>
export default {

}
</script>

<style lang="less" scoped>
    .container {
        &-image {
            width: 100%;
        }
    }
</style>