<template>
  <div class="container">
    <img class="poster" src="https://educator.oss-cn-hangzhou.aliyuncs.com/static/join-wx-group.png" @click="downloadApk">
    <div class="shadow" v-if="showShadow" @click="showShadow = false">
      <img class="hint" src="https://educator.oss-cn-hangzhou.aliyuncs.com/static/open-browser.png"/>
    </div>
  </div>
</template>

<script>

  import {jump} from "../../utils/render";
  export default {
    name: 'JoinWxGroup',

    data() {
      return {
        showShadow: false
      }
    },
    methods: {
      downloadApk() {
        jump(this, null, null, () => {
          this.showShadow = true
        })
      }
    },
    mounted() {
    }
  }
</script>

<style scoped>

  .container {
    width: 750px;
    height: 100vh;
    flex-direction: column;
    align-items: center;
  }

  .poster {
    width: 750px;
    height: 100vh;
    object-fit: cover;
  }
</style>
