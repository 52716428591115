<template>
  <div class="container">
    <van-form class="form" @submit="submit">
      <div class="form-item">
        <div class="form-label">申报方式</div>
        <van-radio-group style="padding: 10px 0" v-model="applyType" direction="horizontal">
          <van-radio :name="1" icon-size="32px"><span class="form-text-normal">个人自荐</span></van-radio>
          <van-radio :name="2" icon-size="32px"><span class="form-text-normal">单位推荐</span></van-radio>
          <van-radio :name="3" icon-size="32px"
            ><span class="form-text-normal">专家委员会提名</span></van-radio
          >
        </van-radio-group>
      </div>
      <div class="form-item">
        <div class="form-label">申报人基本信息</div>
        <van-field v-model="name" label="姓名" placeholder="请输入姓名" />
        <div class="row row-p form-border">
          <span class="form-text-normal" style="width: 7.1em">性别</span>
          <van-radio-group style="padding: 10px 0" v-model="gender" direction="horizontal">
            <van-radio :name="1" icon-size="32px"><span class="form-text-normal">男</span></van-radio>
            <van-radio :name="2" icon-size="32px"><span class="form-text-normal">女</span></van-radio>
          </van-radio-group>
        </div>
        <van-field v-model="age" label="年龄" placeholder="请输入年龄" type="digit" />
        <van-field
          v-model="nation"
          label="民族"
          placeholder="请选择民族"
          clickable
          readonly
          @click="nationTap"
        />
        <van-field
          v-model="education"
          label="最高学历"
          placeholder="请选择最高学历"
          clickable
          readonly
          @click="educationTap"
        />
        <van-field
          v-model="graduationDate"
          label="毕业时间"
          placeholder="请选择毕业时间"
          clickable
          readonly
          @click="graduationDateTap"
        />
        <van-field v-model="email" label="电子邮箱" placeholder="请输入电子邮箱" />
        <div class="form-border" style="padding: 20px 0 0 0">
          <span class="form-text-normal">政治面貌</span>
          <van-radio-group style="padding: 20px 0 0 0" v-model="politicCountenance" direction="horizontal">
            <van-radio :name="1" icon-size="32px"><span class="form-text-normal">群众</span></van-radio>
            <van-radio :name="2" icon-size="32px"><span class="form-text-normal">共青团员</span></van-radio>
            <van-radio :name="3" icon-size="32px"><span class="form-text-normal">中共党员</span></van-radio>
            <van-radio :name="4" icon-size="32px"><span class="form-text-normal">其他</span></van-radio>
          </van-radio-group>
          <van-field
            v-if="politicCountenance === 4"
            v-model="otherPoliticCountenance"
            label=""
            placeholder="填写其他政治面貌"
          />
          <van-field
            v-if="politicCountenance === 3 || politicCountenance === 4"
            v-model="joinDate"
            label="加入时间"
            placeholder="请选择加入政治团体的时间"
            clickable
            readonly
            @click="joinDateTap"
          />
        </div>
        <van-field v-model="organization" label="工作单位" placeholder="请输入工作单位" />
        <van-field v-model="title" label="职别职称" placeholder="请输入职别职称" />
        <van-field v-model="address" label="通信地址" placeholder="请输入通信地址" />
        <van-field v-model="phone" label="联系电话" placeholder="请输入联系电话" type="tel" />
      </div>
      <div class="form-item" v-for="(item, index) in workExperience" :key="index">
        <div class="row row-between">
          <div class="form-label">工作经历{{ index + 1 }}</div>
          <img
            v-if="workExperience.length > 1"
            class="delete-icon"
            src="../../../../public/static/images/delete.png"
            alt=""
            @click="deleteWorkExperience(index)"
          />
        </div>
        <van-field v-model="item.organization" label="工作单位" placeholder="请输入工作单位" />
        <van-field v-model="item.position" label="职位" placeholder="请输入职位" />
        <van-field v-model="item.duty" label="主要职责" placeholder="请输入主要职责" />
        <van-field v-model="item.certifier" label="证明人" placeholder="请输入证明人" />
        <van-field
          v-model="item.certifierPhone"
          label="证明人电话"
          placeholder="请输入证明人电话"
          type="tel"
        />
      </div>
      <div style="margin: 16px 80px" v-if="workExperience.length < 3">
        <van-button block type="default" size="small" @click="addWorkExperience">添加工作经历</van-button>
      </div>
      <div class="form-item">
        <div class="form-label">教学、教管、教研方面取得的成果，获得的荣誉、表彰（近五年来）</div>
        <van-field v-model="content1" autosize type="textarea" placeholder="请输入..." />
      </div>
      <div class="form-item">
        <div class="form-label">引领、辅导后辈或与同行开展主题分享交流经历（近五年来）</div>
        <van-field v-model="content2" autosize type="textarea" placeholder="请输入..." />
      </div>
      <div class="form-item">
        <div class="form-label">在工作、生活中，您最感自豪的五项成绩/成就是什么？请简要说明（100-120字）</div>
        <van-field
          v-model="content3"
          autosize
          type="textarea"
          maxlength="120"
          show-word-limit
          placeholder="请输入..."
        />
      </div>
      <div class="form-item">
        <div class="form-label">
          请举例说明：您在教育教学中曾经遇到一个极端困难的工作/任务，但通过努力最终达成。当时的情况如何、最大的难点是什么、您是如何解决的、结果如何？（100-120字）
        </div>
        <van-field
          v-model="content4"
          autosize
          type="textarea"
          maxlength="120"
          show-word-limit
          placeholder="请输入..."
        />
      </div>
      <div class="form-item">
        <div class="form-label">在您看来，成为“大国良师”最该具备的五项能力是什么？（100-120字）</div>
        <van-field
          v-model="content5"
          autosize
          type="textarea"
          maxlength="120"
          show-word-limit
          placeholder="请输入..."
        />
      </div>
      <div class="form-item">
        <div class="form-label">您期望自己3-5年后成为什么样的教师？或者达成哪些职业成就？（80-100字）</div>
        <van-field
          v-model="content6"
          autosize
          type="textarea"
          maxlength="100"
          show-word-limit
          placeholder="请输入..."
        />
      </div>
      <div class="form-item">
        <div class="form-label">推荐单位（专家）意见-自荐忽略此栏</div>
        <van-field v-model="content7" autosize type="textarea" placeholder="请输入..." />
      </div>
      <div class="form-item">
        <div class="form-label">大国良师的成长故事（1000字左右）</div>
        <van-field
          v-model="story"
          autosize
          type="textarea"
          maxlength="1200"
          show-word-limit
          placeholder="大国良师的成长故事..."
        />
      </div>
      <div v-if="applyType === 2 || applyType === 3" class="form-item">
        <div class="form-label">申报表扫描件（需盖章）</div>
        <van-uploader
          v-model="imageList"
          :max-count="3"
          preview-size="192px"
          :before-delete="beforeDelete"
          :after-read="afterRead"
        />
      </div>
      <div style="margin: 16px">
        <van-button round block type="primary" :disabled="!canRequest" native-type="submit">提交</van-button>
      </div>
    </van-form>
    <van-popup v-model:show="pickerVisible" round position="bottom">
      <van-picker
        show-toolbar
        :title="pickerData.title"
        :item-height="60"
        :columns="pickerData.columns"
        @cancel="pickerVisible = false"
        @confirm="onPickerConfirm"
      />
    </van-popup>
    <van-popup v-model:show="datePickerVisible" round position="bottom">
      <van-datetime-picker
        type="year-month"
        :title="datePickerData.title"
        :item-height="60"
        :min-date="datePickerData.minDate"
        :max-date="datePickerData.maxDate"
        :formatter="formatter"
        @cancel="datePickerVisible = false"
        @confirm="onDatePickerConfirm"
      />
    </van-popup>
  </div>
</template>

<script>
/**
 *  大国良师申报
 */
import nation from "@/assets/json/nation";
import education from "@/assets/json/education";
import validator from "validator";
import apiActivity from "@/assets/api/api_activity";
import { Notify } from "vant";
import apiCommon from "@/assets/api/api_common";
export default {
  data() {
    return {
      pickerData: {
        title: "",
        columns: [],
      },
      datePickerData: {
        title: "",
        columns: [],
      },
      pickerVisible: false,
      datePickerVisible: false,
      // 是否可以发送网络请求
      canRequest: true,

      applyType: 0, // 申报方式 1: 个人自荐 2: 单位推荐 3: 专家委员会提名
      name: "", // 姓名
      gender: 0, // 性别 1: 男 2: 女
      age: "", // 年龄
      nation: "", // 民族
      education: "", // 最高学历
      graduationDate: "", // 毕业时间
      email: "", // 电子邮箱
      politicCountenance: 0, // 政治面貌 1: 群众 2: 共青团员 3: 中共党员
      otherPoliticCountenance: "", // 其他政治面貌
      joinDate: "", // 加入时间
      organization: "", // 工作单位
      title: "", // 职别职称
      address: "", // 通信地址
      phone: "", // 联系电话
      workExperience: [{ organization: "", position: "", duty: "", certifier: "", certifierPhone: "" }], // 工作经历
      content1: "",
      content2: "",
      content3: "",
      content4: "",
      content5: "",
      content6: "",
      content7: "",
      story: "",
      picList: [],
      imageList: [],
    };
  },
  computed: {
    canSubmit() {
      const applyTypeEnable = this.applyType > 0;
      const nameEnable = this.name.length > 0;
      const genderEnable = this.gender > 0;
      const ageEnable = this.age.length > 0;
      const nationEnable = this.nation.length > 0;
      const educationEnable = this.education.length > 0;
      const graduationDateEnable = this.graduationDate.length > 0;
      const emailEnable = validator.isEmail(this.email);
      const politicCountenanceEnable = this.politicCountenance > 0;
      const joinDateEnable = this.politicCountenance === 3 ? this.joinDate.length > 0 : true;
      const organizationEnable = this.organization.length > 0;
      const titleEnable = this.title.length > 0;
      const addressEnable = this.address.length > 0;
      const phoneEnable = validator.isMobilePhone(this.phone, ["zh-CN"]);
      const workExperienceEnable = this.workExperience.length > 0;
      const content1Enable = this.content1.length > 0;
      const content2Enable = this.content2.length > 0;
      const content3Enable = this.content3.length >= 10 && this.content3.length <= 120;
      const content4Enable = this.content4.length >= 10 && this.content4.length <= 120;
      const content5Enable = this.content5.length >= 10 && this.content5.length <= 120;
      const content6Enable = this.content6.length >= 10 && this.content6.length <= 100;
      const content7Enable = true;
      return (
        applyTypeEnable &&
        nameEnable &&
        genderEnable &&
        ageEnable &&
        nationEnable &&
        educationEnable &&
        graduationDateEnable &&
        emailEnable &&
        politicCountenanceEnable &&
        joinDateEnable &&
        organizationEnable &&
        titleEnable &&
        addressEnable &&
        phoneEnable &&
        workExperienceEnable &&
        content1Enable &&
        content2Enable &&
        content3Enable &&
        content4Enable &&
        content5Enable &&
        content6Enable &&
        content7Enable
      );
    },
  },
  methods: {
    // 民族选择
    nationTap() {
      this.pickerData = { title: "民族", columns: nation };
      this.pickerVisible = true;
    },
    // 学历选择
    educationTap() {
      this.pickerData = { title: "最高学历", columns: education };
      this.pickerVisible = true;
    },
    // 毕业时间选择
    graduationDateTap() {
      this.datePickerData = {
        title: "毕业时间",
        minDate: new Date(1981, 0, 1),
        maxDate: new Date(2021, 9, 1),
      };
      this.datePickerVisible = true;
    },
    // 加入时间选择
    joinDateTap() {
      this.datePickerData = {
        title: "加入时间",
        minDate: new Date(1949, 0, 1),
        maxDate: new Date(2021, 11, 31),
      };
      this.datePickerVisible = true;
    },
    // 年月格式化
    formatter(type, val) {
      if (type === "year") {
        return `${val}年`;
      } else if (type === "month") {
        return `${val}月`;
      }
      return val;
    },
    onPickerConfirm(selected) {
      if (this.pickerData.title === "民族") {
        this.nation = selected;
      } else if (this.pickerData.title === "最高学历") {
        this.education = selected;
      }
      this.pickerVisible = false;
    },
    onDatePickerConfirm(date) {
      if (this.datePickerData.title === "毕业时间") {
        this.graduationDate = `${date.getFullYear()}年${date.getMonth() + 1}月`;
      } else if (this.datePickerData.title === "加入时间") {
        this.joinDate = `${date.getFullYear()}年${date.getMonth() + 1}月`;
      }
      this.datePickerVisible = false;
    },
    // 添加工作经历
    addWorkExperience() {
      this.workExperience.push({
        organization: "",
        position: "",
        duty: "",
        certifier: "",
        certifierPhone: "",
      });
    },
    // 删除工作经历
    deleteWorkExperience(index) {
      this.workExperience.splice(index, 1);
    },
    // 上传图片
    afterRead(file) {
      file.status = "uploading";
      file.message = "上传中...";
      apiCommon.uploadImage(file.file).then((res) => {
        if (res.errcode === 0) {
          file.status = "success";
          file.message = "上传成功";
          file.url = res.url;
          this.picList = this.imageList.map((item) => ({ url: item.url }));
        } else {
          file.status = "failed";
          file.message = "上传失败";
          file.url = "";
        }
      });
    },
    // 删除图片
    beforeDelete() {
      this.picList = this.imageList.map((item) => ({ url: item.url }));
      return true;
    },
    submit() {
      const applyType = this.applyType;
      const name = this.name;
      const gender = this.gender;
      const age = this.age;
      const nation = this.nation;
      const education = this.education;
      const graduationDate = this.graduationDate;
      const email = this.email;
      let politicCountenance = this.politicCountenance;
      const otherPoliticCountenance = this.otherPoliticCountenance;
      const organization = this.organization;
      const joinDate = this.joinDate;
      const title = this.title;
      const address = this.address;
      const phone = this.phone;
      const workExperience = this.workExperience;
      const content1 = this.content1;
      const content2 = this.content2;
      const content3 = this.content3;
      const content4 = this.content4;
      const content5 = this.content5;
      const content6 = this.content6;
      const content7 = this.content7;
      const story = this.story;
      const picList = this.picList;
      if (politicCountenance === 4) {
        politicCountenance = otherPoliticCountenance;
      } else if (politicCountenance === 3) {
        politicCountenance = "中共党员";
      } else if (politicCountenance === 2) {
        politicCountenance = "共青团员";
      } else if (politicCountenance === 1) {
        politicCountenance = "群众";
      }
      if (applyType === 0) {
        Notify({ type: "danger", message: "请选择申报方式" });
        return;
      }
      if (!name) {
        Notify({ type: "danger", message: "请填写姓名" });
        return;
      }
      if (gender === 0) {
        Notify({ type: "danger", message: "请选择性别" });
        return;
      }
      if (!age) {
        Notify({ type: "danger", message: "请填写年龄" });
        return;
      }
      if (!nation) {
        Notify({ type: "danger", message: "请选择民族" });
        return;
      }
      if (!education) {
        Notify({ type: "danger", message: "请选择最高学历" });
        return;
      }
      if (!graduationDate) {
        Notify({ type: "danger", message: "请选择毕业时间" });
        return;
      }
      if (!validator.isEmail(email)) {
        Notify({ type: "danger", message: "请填写正确格式的电子邮箱" });
        return;
      }
      if (!politicCountenance) {
        Notify({ type: "danger", message: "请选择或填写政治面貌" });
        return;
      }
      if (politicCountenance === 3 && !joinDate) {
        Notify({ type: "danger", message: "请选择加入时间" });
        return;
      }
      if (!organization) {
        Notify({ type: "danger", message: "请填写工作单位" });
        return;
      }
      if (!title) {
        Notify({ type: "danger", message: "请填写职别职称" });
        return;
      }
      if (!address) {
        Notify({ type: "danger", message: "请填写通信地址" });
        return;
      }
      if (!validator.isMobilePhone(phone, ["zh-CN"])) {
        Notify({ type: "danger", message: "请填写正确格式的联系电话" });
        return;
      }
      if (!this.validatorWorkExperience(workExperience)) {
        Notify({ type: "danger", message: "请完善工作经历" });
        return;
      }
      if (
        !content1 ||
        !content2 ||
        !content3 ||
        content3.length < 10 ||
        !content4 ||
        content4.length < 10 ||
        !content5 ||
        content5.length < 10 ||
        !content6 ||
        content6.length < 10
      ) {
        Notify({ type: "danger", message: "请完善申报资料" });
        return;
      }
      if (!story || story.length < 900) {
        Notify({ type: "danger", message: "成长故事不得少于900字" });
        return;
      }
      if ((applyType === 2 || applyType === 3) && picList.length === 0) {
        Notify({ type: "danger", message: "请上传申报表扫描件（需盖章）" });
        return;
      }

      this.canRequest = false;
      const params = {
        applyType,
        name,
        gender,
        age,
        nation,
        education,
        graduationDate,
        email,
        politicCountenance,
        organization,
        joinDate,
        title,
        address,
        phone,
        workExperience,
        content1,
        content2,
        content3,
        content4,
        content5,
        content6,
        content7,
        story,
        picList,
      };
      apiActivity.declareDGLSForm(params).then((res) => {
        this.canRequest = true;
        if (res.errcode !== 0) {
          Notify({ type: "danger", message: res.errmsg });
          return;
        }
        this.$router.push("/activity/dgls/success");
      });
    },
    // 验证工作经历
    validatorWorkExperience(workExperience) {
      let flag = true;
      workExperience.map((item) => {
        if (!item.organization || !item.position || !item.duty || !item.certifier) {
          flag = false;
        }
      });
      return flag;
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  width: 100%;
  padding: 30px;
}
.form-text-normal {
  font-size: 28px;
  color: #646566;
}
.form-border {
  border-bottom: 1px solid #ebedf0;
}
.form-label {
  font-size: 28px;
  font-weight: bold;
  line-height: 30px;
  padding: 20px 0px;
}
.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  &-p {
    padding: 14px 0;
  }
  &-between {
    justify-content: space-between;
  }
}
.delete-icon {
  width: 30px;
  height: 30px;
}
</style>

<style lang="less">
.van-cell {
  padding-left: 0;
  padding-right: 0;
}
.form {
  input:focus,
  textarea:focus {
    outline: none;
    border: 0px solid #ccc;
  }
}
</style>