import * as API from './'

export default {

  getXimalayaToken: params => {
    return API.POST('/ximalaya/getXimalayaToken', params)
  },

  getXimalayaAlbumsBrowse: params => {
    return API.GET('/ximalaya/getXimalayaAlbumsBrowse', params)
  },

  getAlbumsInfo: params => {
    return API.GET('/ximalaya/getAlbumsInfo', params)
  }
}
