<template>
  <div class="play-list" v-if="showMe">
    <div class="shadow to-show" @click.stop="hide" @touchmove.prevent></div>
    <div class="content-container bottom-in">
      <div class="content-op">
        <img style="width: 44px; height: 47px" :src="playOrderPic" @click="togglePlayOrder" />
        <div style="font-size: 30px; color: #333333; margin-left: 30px" @click="togglePlayOrder">{{playOrderText}}</div>
        <div style="font-size: 30px; color: #333333; flex: 1; text-align: right">共{{playListLength || 0}}集</div>
      </div>
      <div id="play-list" class="mescroll">
        <div class="list-container">

          <div :class="['content-list', playIndex === index ? 'playing' : '']"
               v-for="(item, index) in playList" :key="index" @click.stop="change(index)">
            <div class="index-text">{{index + 1 + diff}}</div>
            <div class="right-container">
              <div class="title">{{item.track_title}}</div>
              <div style="display: flex; flex-direction: row; align-items: center">
                <img style="width: 28px; height: 28px" src="/static/images/xmly/duration.png" />
                <div class="duration">{{item.duration}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="close" @click.stop="hide">关闭</div>
    </div>
  </div>
</template>

<script>

  export default {
    name: 'playList',
    props: ['playList', 'playIndex', 'playOrder', 'playListLength', 'diff'],
    computed: {
      playOrderPic() {
        if (this.playOrder === 'order') {
          return '/static/images/xmly/order.png'
        } else if (this.playOrder === 'loop') {
          return '/static/images/xmly/single.png'
        } else if (this.playOrder === 'random') {
          return '/static/images/xmly/random.png'
        } else {
          return ''
        }
      },
      playOrderText() {
        if (this.playOrder === 'order') {
          return '顺序播放'
        } else if (this.playOrder === 'loop') {
          return '单曲循环'
        } else if (this.playOrder === 'random') {
          return '随机播放'
        } else {
          return ''
        }
      },
    },
    data () {
      return {
        showMe: false
      }
    },
    methods: {
      hide() {
        this.showMe = false
      },
      show() {
        this.showMe = true
      },
      togglePlayOrder() {
        this.$emit('togglePlayOrder')
      },
      change(index) {
        if (index === this.playIndex) {
          return
        }
        this.$emit('changeSound', index)
      }
    },
    mounted() {

    }
  }
</script>

<style scoped>
  .play-list{
    position: fixed;
    top: 0;
    left: 0;
    width: 750px;
    height: 100vh;
    z-index: 999;
  }

  .shadow {
    position: fixed;
    top: 0;
    left: 0;
    width: 750px;
    height: 100vh;
    background-color: rgba(0,0,0,0.3);
  }

  .content-container {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 750px;
    height: 848px;
    background-color: white;
  }

  .mescroll{
    width: 750px;
    height: 678px;
    background-color: #f2f2f2;
  }

  .content-list {
    width: 690px;
    height: 100px;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: white;
    margin-bottom: 20px;
  }

  .content-op {
    width: 750px;
    height: 72px;
    display: flex;
    flex-direction: row;
    align-items: center;
    box-sizing: border-box;
    padding: 0 30px;
  }

  .index-text {
    width: 60px;
    height: 100px;
    line-height: 100px;
    text-align: center;
    color: #333333;
    font-size: 28px;
  }

  .right-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .title {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 28px;
    line-height: 42px;
    color: #333333;
  }

  .duration {
    font-size: 24px;
    color: #999999;
    margin-left: 10px;
  }

  .list-container {
    width: 750px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 20px;
  }

  .playing {
    border: 1px solid #e71e28;
  }

  .close {
    width: 750px;
    height: 98px;
    line-height: 98px;
    font-size: 30px;
    color: #333333;
    text-align: center;
    border-top: 2px solid #f2f2f2;
  }

  @keyframes bottom-in {
    0% {
      transform: translate(0, 100%);
    }
    100% {
      transform: translate(0, 0);
    }
  }

  .bottom-in {
    animation: bottom-in 0.2s;
  }

  @keyframes to-show {
    0% {opacity: 0;}
    100% {opacity: 1;}
  }


  .to-show {
    animation: to-show .5s;
    animation-fill-mode: both !important;
    -webkit-animation-fill-mode: both !important;
  }
</style>
