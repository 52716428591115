<template>
    <div class="art-team-container">
        <img v-if="bannerList.length > 0" class="banner" :src="bannerList[0].url" alt="" />
        <div class="works-wrapper">
            <div class="works-item" v-for="item in itemList" :key="item.id">
                <span class="title">{{ item.name }}</span>
                <div class="line"></div>
                <div class="scroll-wrapper">
                    <div class="video-wrapper">
                        <div
                            class="video"
                            v-for="video in item.artItems"
                            :key="video.id"
                            @click="$router.push({ name: 'ArtVideo', query: { id: video.id } })"
                        >
                            <img class="cover" :src="video.cover" alt="" />
                            <img class="play-icon" src="/static/images/play.png" alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="desc-wrapper">
            <img class="avatar" :src="teamInfo.face" alt="" />
            <span class="title">{{ teamInfo.name }}介绍</span>
            <div class="desc">
                <span>
                    {{ teamInfo.properties }}
                </span>
            </div>
        </div>
        <div class="like-wrapper">
            <img src="/static/images/like_icon.png" alt="" @click="like" />
            <span>喜欢作品</span>
            <div class="count">— {{ teamInfo.likeCount }} 人喜欢 —</div>
        </div>
        <div class="footer">
            <div class="title">策划方</div>
            <div class="value">{{ teamInfo.planName }}</div>
        </div>
        <div class="footer">
            <div class="title">商务合作</div>
            <div class="value">{{ teamInfo.mobile }}</div>
        </div>
    </div>
</template>

<script>
import { getBanner, getItemList, getTeamInfo, likeTeam } from "@/assets/api/api_art"
import { Notify } from "vant"

export default {
    name: "ArtTeam",
    components: {},
    data() {
        return {
            // 团队Id
            teamId: 1,
            // banner列表
            bannerList: [],
            // 团队信息
            teamInfo: {},
            // 作品列表
            itemList: [],
        }
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {
        this.getBanner()
        this.getTeamInfo()
        this.getItemList()
    },
    methods: {
        // 获取banner
        async getBanner() {
            const res = await getBanner()
            if (res.errcode !== 0) {
                return
            }
            this.bannerList = res.data
        },
        // 获取团队信息
        async getTeamInfo() {
            const res = await getTeamInfo(this.teamId)
            if (res.errcode !== 0) {
                return
            }
            this.teamInfo = res.data
        },
        // 获取作品列表
        async getItemList() {
            const res = await getItemList(this.teamId)
            if (res.errcode !== 0) {
                return
            }
            this.itemList = res.data
        },
        // 点赞
        async like() {
            const res = await likeTeam(this.teamId)
            if (res.errcode !== 0) {
                Notify({ type: "danger", message: res.errmsg })
                return
            }
            Notify({ type: "success", message: "感谢您的支持" })
            this.getTeamInfo()
        },
    },
}
</script>
<style lang="less" scoped>
.art-team-container {
    box-sizing: border-box;
    width: 100vw;
    padding-block: 32px;
    padding-left: 32px;

    .banner {
        width: 686px;
        aspect-ratio: 686 / 340;
        object-fit: cover;
        border-radius: 24px;
        background-color: red;
    }

    .works-wrapper {
        padding-top: 20px;

        .works-item {
            margin-top: 40px;
            display: flex;
            align-items: center;

            .title {
                writing-mode: vertical-lr;
                font-size: 32px;
                line-height: 40px;
                font-weight: bold;
                letter-spacing: 8px;
                color: #999999;
            }

            .line {
                margin-inline: 30px;
                width: 4px;
                height: 72px;
                background-color: #d8d8d8;
            }

            .scroll-wrapper {
                overflow-x: scroll;
                flex: 1;
                display: flex;

                .video-wrapper {
                    flex: 1;
                    display: flex;
                    align-items: center;
                    overflow-x: visible;
                    overflow-y: hidden;

                    .video {
                        margin-right: 20px;
                        width: 390px;
                        height: 270px;
                        background-color: #33333350;
                        position: relative;

                        .cover {
                            width: 390px;
                            height: 270px;
                        }

                        .play-icon {
                            position: absolute;
                            left: 50%;
                            top: 50%;
                            transform: translate(-50%, -50%);
                            width: 80px;
                            height: 80px;
                            border-radius: 50%;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                        }
                    }
                }
            }
        }
    }

    .desc-wrapper {
        margin-top: 40px;
        margin-right: 32px;
        display: flex;
        flex-direction: column;

        .avatar {
            width: 120px;
            height: 120px;
            border-radius: 99px;
            object-fit: cover;
            align-self: center;
        }

        .title {
            margin-top: 20px;
            width: 100%;
            text-align: center;
            align-self: center;
            font-size: 32px;
            line-height: 40px;
            font-weight: bold;
            color: #333333;
        }

        .desc {
            margin-top: 50px;
            padding: 30px;
            background-color: #eeeeee;
            text-align: justify;

            span {
                color: #333333;
                font-size: 28px;
                line-height: 48px;
                font-weight: bold;
                letter-spacing: 2px;
                text-align: justify;
                display: inline-block;
                white-space: pre-wrap;
            }
        }
    }

    .like-wrapper {
        margin-top: 50px;
        margin-right: 32px;
        margin-bottom: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;

        img {
            width: 48px;
            height: 48px;
        }

        span {
            text-wrap: nowrap;
            color: #333333;
            font-size: 32px;
            line-height: 44px;
            font-weight: bold;
        }

        .count {
            text-align: center;
            text-wrap: nowrap;
            margin-top: 12px;
            color: #bbbbbb;
            font-size: 24px;
            line-height: 32px;
        }
    }

    .footer {
        margin-top: 40px;
        margin-right: 32px;
        text-align: center;

        .title {
            color: #333333;
            font-size: 28px;
            line-height: 34px;
            font-weight: bold;
        }

        .value {
            margin-top: 4px;
            color: #333333;
            font-size: 28px;
            line-height: 34px;
        }
    }
}
</style>
