<template>
    <div class="container">
        <div class="title">“光明教育家”隐私政策</div>
        <p>欢迎您使用由鸿宇数据科技（上海）有限公司（以下简称“鸿宇”）提供的光明教育家产品及服务！光明教育家是由鸿宇提供的互联网产品，对光明教育家的所有权和运营权归鸿宇所有。</p>
    </div>
</template>

<script>
export default {
    name: 'Privacy',
    data() {
        return {

        }
    }
}
</script>

<style scoped>
.container {
    box-sizing: border-box;
    width: 750px;
    padding: 30px;
    color: #333333;
}

.title {
    font-size: 32px;
    width: 100%;
    text-align: center;
}

p {
    margin-top: 20px;
    font-size: 24px;
    line-height: 42px;
    text-align: start;
}
</style>