<template>
  <div class="container">
    <img class="cover" v-show="cover" :src="cover" />
    <div class="cover" v-show="!cover" />
    <div class="album-title">
      <marquee v-if="title" :val="title" :max-width="540"></marquee>
    </div>
    <div class="album-sub-title">以下内容来自喜马拉雅APP</div>
    <div class="progress-container">
      <img style="width: 60px; height: 57px;" src="/static/images/xmly/m15s.png" @click="m15s" />
      <div style="width: 540px; height: 42px; margin-left: 18px; margin-right: 18px">
        <player-progress-bar ref="ppb" @onSeek="seek" />
      </div>
      <img style="width: 60px; height: 57px" src="/static/images/xmly/p15s.png" @click="p15s" />
    </div>
    <div class="controller-container">
      <img class="controller-btn-small"
           style="margin-right: 80px"
           :src="playOrderPic"
           @click="togglePlayOrder" />
      <img class="controller-btn-middle"
           style="margin-right: 80px"
           src="/static/images/xmly/previous.png"
           @click="playPrev" />
      <img class="controller-btn-big"
           style="margin-right: 80px"
           :src="isPlaying ? '/static/images/xmly/pause.png' : '/static/images/xmly/play.png'"
           @click="togglePlay" />
      <img class="controller-btn-middle"
           style="margin-right: 80px"
           src="/static/images/xmly/next.png"
           @click="playNext" />
      <img class="controller-btn-small"
           src="/static/images/xmly/play-list.png"
           @click="showPlayList" />
    </div>

    <div class="bottom-recommend" @click="showIntro">
      <div class="recommend-cover">
        <img style="width: 110px; height: 110px" :src="albumDetail.cover_url_large" />
        <img class="xmly-tag" src="/static/images/xmly/xmly-tag.png" />
      </div>
      <div style="margin-left: 20px; display: flex; flex-direction: column">
        <div style="font-size: 30px; color: #3e3844">{{albumDetail.album_title}}</div>
        <div style="font-size: 24px; color: #999999; margin-top: 20px">{{albumDetail.announcer.nickname}} | {{albumDetail.play_count}}已听</div>
      </div>
    </div>
    <play-list ref="pl"
               :play-list="playList"
               :diff="(playPage - 1) * 50"
               :play-index="playIndex"
               :play-order="playOrder"
               :play-list-length="albumDetail.include_track_count"
               @togglePlayOrder="togglePlayOrder"
               @changeSound="changeSound" />
    <album-intro ref="ai" :title="albumDetail.album_title" :intro="intro" />
  </div>
</template>

<script>

  import {initXMLYPlayer, initXMLYService} from "../../utils/xmly";
  import Marquee from '../../components/marquee'
  import PlayList from '../../components/playList'
  import AlbumIntro from '../../components/albumIntro'
  import PlayerProgressBar from '../../components/playerProgressBar'
  import api from "../../assets/api/api_xmly";
  import storageAction from "../../utils/storageAction";
  import {formatSeconds, formatViewCount, tagAddStyle} from "../../utils/render";

  export default {
    name: 'ShareTopic',
    data() {
      return {
        albumId: null,
        rawIndex: 0,
        isPlaying: true,
        playPage: 1,
        playOrder: 'order',
        playIndex: 0,
        albumDetail: {
          album_title: '',
          announcer: {}
        },
        playList: [],
        cover: '',
        title: '',
        intro: ''
      }
    },
    computed: {
      playOrderPic() {
        if (this.playOrder === 'order') {
          return '/static/images/xmly/order.png'
        } else if (this.playOrder === 'loop') {
          return '/static/images/xmly/single.png'
        } else if (this.playOrder === 'random') {
          return '/static/images/xmly/random.png'
        } else {
          return ''
        }
      }
    },
    components: {
      Marquee,
      PlayerProgressBar,
      PlayList,
      AlbumIntro
    },
    methods: {
      showPlayList() {
        this.$refs.pl.show()
      },
      showIntro() {
        this.$refs.ai.show()
      },
      changeSound(index) {
        this.xmlyPlayer.playByIndex(index)
        this.playIndex = index
        this.isPlaying = true
      },
      togglePlay() {
        if (!this.xmlyPlayer) {
          return
        }
        this.isPlaying = !this.xmlyPlayer.isPlaying()
        if (this.xmlyPlayer.isPlaying()) {
          this.xmlyPlayer.pause()
        } else {
          this.xmlyPlayer.play()
        }
      },
      play() {
        this.isPlaying = true
        this.xmlyPlayer.play()
      },
      playNext() {
        this.xmlyPlayer.next()
      },
      playPrev() {
        this.xmlyPlayer.prev()
      },
      togglePlayOrder() {
        let orderList = ['order', 'loop', 'random']
        let idx = orderList.findIndex(it => it === this.playOrder)
        idx++
        if (idx >= 3) {
          idx = 0
        }
        this.playOrder = orderList[idx]
        this.xmlyPlayer.setPlayMode(this.playOrder)
      },
      p15s() {
        this.xmlyPlayer.seekForward(15)
      },
      m15s() {
        this.xmlyPlayer.seekBack(15)
      },
      seek(time) {
        this.xmlyPlayer.seek(time)
      },
      getPlayList() {
        let {page, index} = this.calPageAndListIndex(this.rawIndex)
        this.playPage = page
        this.playIndex = index
        let param = {
          albumId: this.albumId,
          deviceId: storageAction.getUUID(),
          page: page,
          count: 50,
          sort: 'asc'
        }
        api.getXimalayaAlbumsBrowse(param).then(res => {
          if (res.errcode === 0) {
            let results = res.data.tracks
            results.forEach(item => {
              item.duration = formatSeconds(item.duration)
            })
            this.playList = results
            let reg = /(font-size\s*:)(\s*\d+\.?\d*)(px|pt)(\s*;?\s*)/g;
            let content = res.data.short_rich_intro
            content = tagAddStyle(content)
            content = content.replace(reg,(matched,capture1,capture2,capture3,capture4)=>{
              return capture1+capture2*1.8+capture3+capture4;
            })
            let reg2 = /(line-height\s*:)(\s*\d+\.?\d*)(px|pt)(\s*;?\s*)/g;
            content = content.replace(reg2,(matched,capture1,capture2,capture3,capture4)=>{
              return capture1+capture2*1.8+capture3+capture4;
            })
            // content = content.replace(/<a/g, '<span')
            // content = content.replace(/<\\a/g, '<\\span')
            content = content.replace(/href/g, 'nhref')
            this.intro = content
            let pl = res.data.tracks.map(it => it.id)
            this.xmlyPlayer.setPlaylist(pl)
            this.xmlyPlayer.playByIndex(this.playIndex)
            this.xmlyPlayer.on('change.sound', sound => {
              console.log('change.sound', sound)
              this.$refs.ppb.resetDuration(sound.duration)
              let currentIndex = this.playList.findIndex(it => it.id === sound.id)
              console.log(sound, this.playList)
              if (currentIndex !== -1) {
                this.playIndex = currentIndex
                this.cover = this.playList[currentIndex].cover_url_large
                this.title = this.playList[currentIndex].track_title
              }
            })
            this.xmlyPlayer.on('timeupdate', time => {
              this.$refs.ppb.updateDuration(time)

            })
            this.xmlyPlayer.on('play', () => {
              this.isPlaying = true
            })
            this.xmlyPlayer.on('pause', () => {
              this.isPlaying = false
            })
            this.xmlyPlayer.on('loaderror', error => {
              alert(error)
            })
          } else {
            alert(res.errmsg)
          }
        })
      },
      getDetail() {
        let param = {
          ids: this.albumId,
          deviceId: storageAction.getUUID(),
        }
        api.getAlbumsInfo(param).then(res => {
          if (res.errcode !== 0) {
            alert(res.errmsg)
            return
          }

          let albumDetail = res.data[0]
          albumDetail.play_count = formatViewCount(albumDetail.play_count)
          this.albumDetail = albumDetail
        })
      },
      calPageAndListIndex(rawIndex) {
        let page = Math.floor(rawIndex / 50) + 1
        let index = rawIndex % 50
        return {page, index}
      }
    },
    mounted() {
      this.albumId = this.$route.query.albumId
      this.rawIndex = this.$route.query.rawIndex
      let tokenId = this.$route.query.tokenId
      initXMLYService(tokenId)
      this.xmlyPlayer = initXMLYPlayer(tokenId)
      this.getPlayList()
      this.getDetail()
    }
  }
</script>

<style scoped>

  .container {
    width: 750px;;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .cover {
    width: 540px;
    height: 540px;
    margin-top: 49px;
    border-radius: 8px;
    background-color: #f2f2f2;
  }

  .album-title {
    width: 540px;
    margin-top: 24px;
  }

  .album-sub-title {
    font-size: 24px;
    color: #999999;
    margin-top: 27px;
  }

  .progress-container {
    width: 750px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 60px;
  }

  .controller-container {
    width: 750px;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 40px;
    justify-content: center;
  }

  .controller-btn-small {
    width: 50px;
    height: 50px;
  }

  .controller-btn-middle {
    width: 60px;
    height: 60px;
  }

  .controller-btn-big {
    width: 120px;
    height: 120px;
  }

  .bottom-recommend {
    width: 750px;
    height: 170px;
    border-top: 1px solid #f2f2f2;
    display: flex;
    flex-direction: row;
    align-items: center;
    position: absolute;
    bottom: 0;
    left: 0;
  }

  .recommend-cover {
    width: 110px;
    height: 110px;
    border: solid 1px #d5d5d5;
    margin-left: 30px;
    position: relative;
  }

  .xmly-tag {
    width: 56px;
    height: 20px;
    position: absolute;
    left: 0;
    top: 9px;
  }

  .mq {
    width: 540px;
    font-size: 36px;
    color: #333333;
  }
</style>
