<template>
    <div class="rs-enroll-container">
        <div class="content">
            <div class="card">
                <van-tabs v-model:active="type" type="card" color="#e41926">
                    <van-tab title="短视频" :name="1">
                        <van-form @submit="onSubmit">
                            <van-field
                                required
                                v-model="form1.area"
                                name="区县"
                                label="区县"
                                placeholder="请选择区县"
                                :rules="rules.area"
                                readonly
                                @click="showPicker = true"
                            />
                            <van-field
                                required
                                v-model="form1.worksName"
                                name="作品名称"
                                label="作品名称"
                                placeholder="请填写作品名称"
                                :rules="rules.worksName"
                            />
                            <van-field
                                required
                                v-model="form1.worksDetail"
                                name="作品简介"
                                label="作品简介"
                                type="textarea"
                                rows="2"
                                autosize
                                maxlength="100"
                                show-word-limit
                                placeholder="请填写作品简介，100个字以内"
                                :rules="rules.worksDetail"
                            />
                            <van-field
                                required
                                v-model="form1.author"
                                name="作者"
                                label="作者"
                                placeholder="请填写作者姓名（仅限一名）"
                                :maxlength="4"
                                :rules="rules.author"
                            />
                            <van-field v-model="form1.sclass" name="班级" label="班级" placeholder="请填写班级" />
                            <van-field
                                v-model="form1.teacher"
                                name="指导老师"
                                label="指导老师"
                                :maxlength="4"
                                placeholder="请填写指导老师姓名（仅限一名）"
                            />
                            <van-field
                                required
                                v-model="form1.recommendCompany"
                                name="推荐单位"
                                label="推荐单位"
                                placeholder="请填写推荐单位或学校"
                                :rules="rules.recommendCompany"
                            />
                            <van-field required name="上传作品" label="上传作品">
                                <template #input>
                                    <div class="uploader">
                                        <van-uploader
                                            v-model="form1.worksUrl"
                                            accept="video/*"
                                            upload-icon="plus"
                                            max-count="1"
                                            :max-size="100 * 1024 * 1024"
                                            :before-read="onVideoBeforeRead"
                                            :after-read="onVideoAfterRead"
                                            :before-delete="onVideoDelete"
                                        />
                                        <div class="tip">
                                            提示：视频格式必须为MP4，横屏，大小不超过100M，时长不超过5分钟。
                                        </div>
                                    </div>
                                </template>
                            </van-field>
                            <van-field
                                required
                                v-model="form1.contacts"
                                name="联系人"
                                label="联系人"
                                placeholder="请填写联系人姓名"
                                :rules="rules.contacts"
                            />
                            <van-field
                                required
                                v-model="form1.mobile"
                                name="手机号"
                                label="手机号"
                                placeholder="请填写联系人手机号"
                                :rules="rules.mobile"
                            />
                            <div class="submit-button-warp">
                                <van-button class="submit-button" type="primary" native-type="submit">
                                    提交作品
                                </van-button>
                            </div>
                        </van-form>
                    </van-tab>
                    <van-tab title="征文" :name="2">
                        <van-form @submit="onSubmit">
                            <van-field
                                required
                                v-model="form2.area"
                                name="区县"
                                label="区县"
                                placeholder="请选择区县"
                                :rules="rules.area"
                                readonly
                                @click="showPicker = true"
                            />
                            <van-field
                                required
                                v-model="form2.worksName"
                                name="作品名称"
                                label="作品名称"
                                placeholder="请填写作品名称"
                                :rules="rules.worksName"
                            />
                            <van-field
                                required
                                v-model="form2.worksDetail"
                                name="作品简介"
                                label="作品简介"
                                type="textarea"
                                rows="2"
                                autosize
                                maxlength="100"
                                show-word-limit
                                placeholder="请填写作品简介，100个字以内"
                                :rules="rules.worksDetail"
                            />
                            <van-field
                                required
                                v-model="form2.author"
                                name="作者"
                                label="作者"
                                placeholder="请填写作者姓名（仅限一名）"
                                :maxlength="4"
                                :rules="rules.author"
                            />
                            <van-field required name="作者照片" label="作者照片">
                                <template #input>
                                    <div class="uploader">
                                        <van-uploader
                                            v-model="form2.authorPhoto"
                                            accept="image/*"
                                            upload-icon="plus"
                                            max-count="1"
                                            :max-size="10 * 1024 * 1024"
                                            :after-read="onAuthorPhotoAfterRead"
                                            :before-delete="onAuthorPhotoDelete"
                                        />
                                        <div class="tip">请上传作者照片（格式jpg，大小不超过10M）。</div>
                                    </div>
                                </template>
                            </van-field>
                            <van-field v-model="form2.sclass" name="班级" label="班级" placeholder="请填写班级" />
                            <van-field
                                v-model="form2.teacher"
                                name="指导老师"
                                label="指导老师"
                                placeholder="请填写指导老师姓名（仅限一名）"
                                :maxlength="4"
                            />
                            <van-field
                                required
                                v-model="form2.recommendCompany"
                                name="推荐单位"
                                label="推荐单位"
                                placeholder="请填写推荐单位或学校"
                                :rules="rules.recommendCompany"
                            />
                            <van-field required name="上传作品" label="上传作品">
                                <template #input>
                                    <div class="uploader">
                                        <van-uploader
                                            v-model="form2.worksUrl"
                                            accept="image/*"
                                            upload-icon="plus"
                                            multiple
                                            :max-size="10 * 1024 * 1024"
                                            :after-read="onFilesAfterRead"
                                            :before-delete="onFilesDelete"
                                        />
                                        <div class="tip">
                                            （多图上传）提示：格式为jpg，大小不超过10M。要求字体清晰，使用活动统一格式用纸。
                                        </div>
                                    </div>
                                </template>
                            </van-field>
                            <van-field
                                required
                                v-model="form2.contacts"
                                name="联系人"
                                label="联系人"
                                placeholder="请填写联系人姓名"
                                :rules="rules.contacts"
                            />
                            <van-field
                                required
                                v-model="form2.mobile"
                                name="手机号"
                                label="手机号"
                                placeholder="请填写联系人手机号"
                                :rules="rules.mobile"
                            />
                            <div class="submit-button-warp">
                                <van-button class="submit-button" type="primary" native-type="submit"
                                    >提交作品</van-button
                                >
                            </div>
                        </van-form>
                    </van-tab>
                </van-tabs>
                <van-popup v-model:show="showPicker" position="bottom">
                    <van-picker
                        :columns="areaList"
                        @confirm="onPickerConfirm"
                        @cancel="showPicker = false"
                        item-height="80"
                    />
                </van-popup>
            </div>
        </div>
    </div>
</template>

<script>
import pattern from "@/utils/pattern"
import api_common from "@/assets/api/api_common"
import api_activity from "@/assets/api/api_activity"
import { Dialog, Notify } from "vant"
import { AuthMixin } from "@/assets/utils/mixin"

// eslint-disable-next-line no-undef

export default {
    // "最美红色故事发声人"短视频、征文报名
    mixins: [AuthMixin],
    name: "RSEnroll",
    data() {
        return {
            type: 1,
            form1: {},
            form2: {},
            rules: {
                area: [{ required: true, message: "区县不能为空" }],
                worksName: [{ required: true, message: "作品名称不能为空" }],
                worksDetail: [{ required: true, message: "作品简介不能为空" }],
                author: [{ required: true, message: "作者不能为空" }],
                authorPhoto: [],
                sclass: [],
                teacher: [],
                recommendCompany: [{ required: true, message: "推荐单位不能为空" }],
                worksUrl: [{ required: true, message: "作品不能为空" }],
                contacts: [{ required: true, message: "联系人不能为空" }],
                mobile: [
                    { required: true, message: "手机号不能为空" },
                    { pattern: pattern.mobile, message: "手机号格式错误" },
                ],
            },
            showPicker: false,
            areaList: [
                "万州区",
                "黔江区",
                "涪陵区",
                "渝中区",
                "大渡口区",
                "江北区",
                "沙坪坝区",
                "九龙坡区",
                "南岸区",
                "北碚区",
                "渝北区",
                "巴南区",
                "长寿区",
                "江津区",
                "合川区",
                "永川区",
                "南川区",
                "綦江区",
                "大足区",
                "璧山区",
                "铜梁区",
                "潼南区",
                "荣昌区",
                "开州区",
                "梁平区",
                "武隆区",
                "城口县",
                "丰都县",
                "垫江县",
                "忠县",
                "云阳县",
                "奉节县",
                "巫山县",
                "巫溪县",
                "石柱县",
                "秀山县",
                "酉阳县",
                "彭水县",
                "两江新区",
                "高新区",
                "万盛经开区",
            ],
            // eslint-disable-next-line no-undef
            uploader: new AliyunUpload.Vod({
                timeout: 60000,
                partSize: 1048576,
                parallel: 5,
                retryCount: 3,
                retryDuration: 2,
                region: "cn-shanghai",
                userId: "12312312312413",
                // 添加文件成功
                addFileSuccess: this.handleAddFileSuccess,
                // 开始上传
                onUploadstarted: this.handleOnUploadstarted,
                // 文件上传成功
                onUploadSucceed: this.handleOnUploadSucceed,
                // 文件上传失败
                onUploadFailed: this.handleOnUploadFailed,
                // 取消文件上传
                onUploadCanceled: this.handleOnUploadCanceled,
                // 文件上传进度，单位：字节, 可以在这个函数中拿到上传进度并显示在页面上
                onUploadProgress: this.handleOnUploadProgress,
                // 上传凭证超时
                onUploadTokenExpired: this.handleOnUploadTokenExpired,
                // 全部文件上传结束
                onUploadEnd: this.handleOnUploadEnd,
            }),
        }
    },
    methods: {
        // 选择器确认
        onPickerConfirm(val) {
            if (this.type === 1) {
                this.form1.area = val
            } else {
                this.form2.area = val
            }
            this.showPicker = false
        },
        // 表单提交
        onSubmit() {
            const params = {}
            if (this.type === 1) {
                Object.assign(params, this.form1, { type: 1 })
                if (!params.videoId) {
                    Notify({ type: "danger", message: "请上传作品" })
                    return
                }
                delete params.worksUrl
            } else {
                Object.assign(params, this.form2, { type: 2 })
                if (!params.authorPhoto || params.authorPhoto.length === 0) {
                    Notify({ type: "danger", message: "请上传作者照片" })
                    return
                }
                if (!params.worksUrl || params.worksUrl.length === 0) {
                    Notify({ type: "danger", message: "请上传作品" })
                    return
                }
                params.authorPhoto = params.authorPhoto[0].url
                params.worksUrl = JSON.stringify(params.worksUrl.map((item) => item.url))
            }
            api_activity.submitReadStoryEnroll(params).then((res) => {
                if (res.errcode !== 0) {
                    Notify({ type: "danger", message: res.errmsg })
                    return
                }
                Dialog.alert({
                    title: "提示",
                    message: "您的作品已提交",
                }).then(() => {
                    this.form1 = {}
                    this.form2 = {}
                })
            })
        },
        // 视频上传前
        onVideoBeforeRead(file) {
            if (file.type !== "video/mp4" && file.type !== "video/mov") {
                Notify({ type: "danger", message: "作品格式错误 " })
                return false
            }
            return true
        },
        // 上传视频文件
        async onVideoAfterRead(file) {
            file.status = "uploading"
            file.message = "上传中..."
            this.uploader.addFile(file.file, null, null, null, '{"Vod":{}}')
        },
        // 删除视频文件
        onVideoDelete() {
            this.form1.videoId = ""
            return true
        },
        // 上传作者照片
        onAuthorPhotoAfterRead(file) {
            file.status = "uploading"
            file.message = "上传中..."
            api_common.uploadImage(file.file).then((res) => {
                if (res.errcode === 0) {
                    file.status = "success"
                    file.message = "上传成功"
                    file.url = "https://wxfactory.zhmobi.com" + res.url
                } else {
                    file.status = "failed"
                    file.message = "上传失败"
                    file.url = ""
                }
            })
        },
        // 删除作者照片
        onAuthorPhotoDelete() {
            this.form2.authorPhoto = ""
            return true
        },
        // 上传作品照片
        onFilesAfterRead(files) {
            let tmpFiles = files
            if (!(files instanceof Array)) {
                tmpFiles = [files]
            }
            tmpFiles.forEach((file) => {
                file.status = "uploading"
                file.message = "上传中..."
                api_common.uploadImage(file.file).then((res) => {
                    if (res.errcode === 0) {
                        file.status = "success"
                        file.message = "上传成功"
                        file.url = "https://wxfactory.zhmobi.com" + res.url
                    } else {
                        file.status = "failed"
                        file.message = "上传失败"
                        file.url = ""
                    }
                })
            })
        },
        // 删除作品
        onFilesDelete() {
            return true
        },
        // 视频添加成功回调
        handleAddFileSuccess() {
            this.uploader.startUpload()
        },
        // 视频开始上传回调
        async handleOnUploadstarted(uploadInfo) {
            if (!uploadInfo.videoId) {
                const params = { CateId: "1000425120", FileName: uploadInfo.file.name }
                const res = await api_common.createUploadVideo(params)
                if (res.errcode !== 0) {
                    return
                }
                this.uploader.setUploadAuthAndAddress(
                    uploadInfo,
                    res.data.UploadAuth,
                    res.data.UploadAddress,
                    res.data.VideoId
                )
            }
        },
        // 视频上传成功回调
        handleOnUploadSucceed(uploadInfo) {
            const url = "短视频作品"
            const videoId = uploadInfo.videoId
            this.form1.worksUrl = [{ url, status: "success", message: "上传成功" }]
            this.form1.videoId = videoId
        },
        // 视频上传失败回调
        handleOnUploadFailed(uploadInfo, code, message) {
            console.log("handleOnUploadFailed", uploadInfo, code, message)
        },
        // 视频上传取消回调
        handleOnUploadCanceled(uploadInfo, code, message) {
            console.log("handleOnUploadCanceled", uploadInfo, code, message)
        },
        // 视频上传进度回调
        handleOnUploadProgress(uploadInfo, totalSize, progress) {
            console.log(progress)
        },
        // 视频上传凭证超时回调
        handleOnUploadTokenExpired(uploadInfo) {
            console.log("handleOnUploadTokenExpired", uploadInfo)
        },
        // 视频上传完成回调
        handleOnUploadEnd(uploadInfo) {
            console.log("handleOnUploadEnd", uploadInfo)
        },
    },
    mounted() {
        this.initWxConfig({
            itemName: "“最美红色故事发声人”短视频、征文报名",
            desc: "“最美红色故事发声人”短视频、征文报名",
            pic: "https://image2.cqcb.com/d/file/image/default/2021-05-17/7f0a5358236913593b031b3fdbdd31f8.jpeg",
            link: `activity/read-story/enroll`,
        })
    },
}
</script>

<style lang="less" scoped>
.rs-enroll-container {
    .banner {
    }

    .content {
        margin-top: -4px;
        background-color: #e41926;
        padding: 20px;

        .card {
            background-color: white;
            border-radius: 10px;
            padding: 20px;

            .uploader {
                position: relative;
                width: 100%;
                padding-bottom: 50px;

                .tip {
                    position: absolute;
                    bottom: 0px;
                    font-size: 20px;
                    line-height: 1.5;
                    color: gray;
                }
            }

            .submit-button-warp {
                width: 100%;
                margin-top: 20px;
                display: flex;
                flex-direction: row;
                justify-content: center;

                .submit-button {
                    width: 300px;
                    border-radius: 10px;
                    background-color: #e41926;
                    border-color: #e41926;
                }
            }
        }
    }
}
</style>

<style>
.van-tabs__nav--card {
    margin: 0 !important;
}
</style>
