import * as API from "./"

export default {
    // 大国良师申报
    declareDGLSForm: (params) => {
        return API.POST("/dgls/declareForm", params)
    },
    // 榜样园长活动投票
    assistance: (params) => {
        return API.POST("/activity/assistance", params)
    },
    // 榜样园长活动园长事迹
    getPrincipalItem: (params) => {
        return API.GET("/activity/getPrincipalItem", params)
    },
    // "百部新书一起读"活动-图书列表
    getVoteBookList: (params) => {
        return API.GET("/item/getVoteBookListV2", params)
    },
    // "百部新书一起读"活动-排行榜
    getVoteRank: (params) => {
        return API.GET("/item/getVoteRankV2", params)
    },
    // "百部新书一起读"活动-投票
    vote: (params) => {
        return API.GET("/item/voteV2", params)
    },
    // "百部新书一起读"活动-取消投票
    cancelVote: (params) => {
        return API.GET("/item/unVoteV2", params)
    },
    // "百部新书一起读"活动-根据bookId获取图书内容
    getBookById: (params) => {
        return API.GET("/item/getBookByIdV2", params)
    },
    // 获取“三叁阅读”小程序跳转链接
    getSCReadMiniUrlScheme: (params) => {
        return API.GET("/wechat/getUrlScheme", params)
    },

    // 提交“光明阅读领航人”报名表单
    submitGMReadForm: (params) => {
        return API.POST("/gmyd/onlineRegister", params)
    },

    // 提交“申请成为小记者”报名表单
    submitLittleReporterForm: (params) => {
        return API.POST("/reporter/reporterApply", params)
    },

    // 提交“申请校记者站”报名表单
    submitLittleReporterForm2: (params) => {
        return API.POST("/reporter/reporterStationApply", params)
    },

    // 获取小记者推荐机构列表
    getLROrganizationList: (params) => {
        return API.GET("/reporter/station/list", params)
    },

    // 查询“光明小书童”记者证
    queryPressCard: (params) => {
        return API.POST("/reporter/search", params)
    },

    // 提交“光明小书童”报名表单
    submitBookBoy: (params) => {
        return API.POST("/educourse/applyBookBoy", params)
    },

    // 提交最美红色故事发声人表单
    submitReadStoryEnroll: (params) => {
        return API.POST("/vote/sign/up", params)
    },
    // 获取最美红色故事发声人作品列表
    getReadStoryWorks: (params) => {
        return API.GET("/vote/works/list", params)
    },

    // 获取最美红色故事发声人作品详情
    getReadStoryWorkDetail: (params) => {
        return API.GET("/vote/info", params)
    },
    // 最美红色故事发声人作品投票
    voteReadStoryWork: (params) => {
        return API.POST("/vote/works/vote", params)
    },
}

/**
 * 获取红色故事动态列表
 * @param {Object} params
 * @param {Number} params.start
 * @param {Number} params.length
 * @returns
 */
export function getReadStoryTrendsList(params) {
    return API.GET("/vote/dynamic/list", params)
}
/**
 * 获取红色故事动态详情
 * @param {Number} id 动态Id
 * @returns
 */
export function getReadStoryTrendsDetail(id) {
    return API.GET("/vote/dynamic/info", { id })
}
