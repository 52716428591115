<template>
  <div class="share-training-camp-task-container">
    <div class="card">
      <div class="title">{{ info?.StudyWorks?.name }}</div>
      <div class="center">
        <van-image round width="144px" height="144px" :src="info?.User?.face" />
        <div class="nick-name">{{ info?.User?.nickName }}的作业</div>
        <div class="date">
          {{ dayjs(info?.updateTime).format("YYYY-MM-DD") }}
        </div>
      </div>
      <div style="height: 128px">
        <Transition>
          <div v-if="show" class="center tran">
            <van-button type="primary" color="white" round @click="toAppTask">
              <div class="button-text">立即查看</div>
            </van-button>
            <div style="margin-top: 16px">
              <span style="font-size: 24px; color: #f3ffee">尚未下载光明教育家？</span>
              <span style="font-size: 24px; color: white; font-weight: bold" @click="toAppTask">立即下载</span>
            </div>
          </div>
        </Transition>
      </div>
    </div>
    <div class="shadow" v-if="showShadow" @click="showShadow = false">
      <img class="hint" src="http://educator.oss-cn-hangzhou.aliyuncs.com/static/open-browser.png" />
    </div>
  </div>
</template>

<script>
import { jump } from "@/utils/render";
import api_share from "@/assets/api/api_share";
import dayjs from "dayjs";
export default {
  name: "ShareTrainingCampTask",
  data() {
    return {
      info: null,
      show: false,
      // 是否显示在浏览器打开
      showShadow: false,
    };
  },
  mounted() {
    this.getStudentTask();
    setTimeout(() => {
      this.show = true;
    }, 1000);
  },
  methods: {
    dayjs,
    // 跳转到app学生作业页面
    toAppTask() {
      const memberWorksId = parseInt(this.$route.query.memberWorksId, 10);
      jump(this, "WorkshopsMemberTaskDetail", { id: memberWorksId, }, () => {
        this.showShadow = true;
      });
    },
    // 获取学生作业
    getStudentTask() {
      const params = { userWorksId: this.$route.query.memberWorksId };
      api_share.getWorkshopsMemberTask(params).then((res) => {
        this.info = res.data;
      });
    },
  },
};
</script>

<style scoped>
.share-training-camp-task-container {
  box-sizing: border-box;
  width: 100vw;
  height: 100vh;
  padding: 40px;
  display: flex;
  flex-direction: column;
  color: white;
}

.card {
  flex: 1;
  background-color: #43cf7c;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.title {
  max-width: 500px;
  font-size: 44px;
  font-weight: bold;
  line-height: 66px;
  text-align: center;
  word-break: break-all;
}

.center {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.nick-name {
  max-width: 500px;
  margin-top: 24px;
  font-size: 40px;
  line-height: 60px;
  font-weight: bold;
  text-align: center;
  word-break: break-all;
}

.date {
  margin-top: 12px;
  color: #ffffff80;
  font-size: 24px;
  font-weight: bold;
}

.button-text {
  margin: 0 40px;
  font-size: 36px;
  color: #43cf7c;
  font-weight: bold;
}

.v-enter-active,
.v-leave-active {
  transition: all 0.75s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
  transform: translateY(100px);
  -webkit-transform: translateY(100px);
  -moz-transform: translateY(100px);
  -ms-transform: translateY(100px);
  -o-transform: translateY(100px);
}

.shadow {
  position: fixed;
  z-index: 99999;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
}

.hint {
  width: 378px;
  height: 236px;
  position: fixed;
  z-index: 999;
  right: 20px;
  top: 0;
}
</style>