<template>
  <div style="position: relative; width: 750px">
    <div class="container">
      <img class="header"
           src="https://educator.oss-cn-hangzhou.aliyuncs.com/static/share-top-banner.png"
           @click="downloadApk" />
      <trend-item :item="reply" />

      <img class="white-cover" src="https://educator.oss-cn-hangzhou.aliyuncs.com/static/share-white-cover.png"/>
      <img class="share-more"
           src="https://educator.oss-cn-hangzhou.aliyuncs.com/static/share-more.png"
           @click="downloadApk" />

      <div class="bottom-open-app" @click="downloadApk">打开光明教育家APP参与话题讨论</div>

      <div class="shadow" v-if="showShadow" @click="showShadow = false">
        <img class="hint" src="https://educator.oss-cn-hangzhou.aliyuncs.com/static/open-browser.png" />
      </div>
    </div>
  </div>
</template>

<script>
  import api from "../../assets/api/api_share";
  import {getPicList, jump, timePeriod} from "../../utils/render";
  import TrendItem from "@/components/trendItem"
  import {AuthMixin} from "@/assets/utils/mixin"

  /**
   * 4.0版本以前的帖子分享
   */
  export default {
    mixins: [AuthMixin],
    name: 'ShareTrend',
    components: {
      TrendItem
    },
    data() {
      return {
        itemId: null,
        replyId: null,
        reply: {
          User: {},
          properties: {}
        },
        showShadow: false
      }
    },
    methods: {
      downloadApk() {
        let param = {
          itemId: this.itemId,
          replyId: this.replyId
        }
        jump(this, 'DynamicDetail', param, () => {
          this.showShadow = true
        })
      },
    },
    mounted() {
      this.itemId = this.$route.query.itemId
      this.replyId = this.$route.query.replyId
      api.getReplyById({replyId: this.replyId}).then(res => {
        if (res.errcode === 0) {
          let result = res.data
          result.hideComment = true
          result.properties = JSON.parse(result.properties)
          result.time = timePeriod(result.createTime)
          result.picList = getPicList(result.picList)
          this.reply = result
          this.initWxConfig({
            itemName: result.gambitName,
            pic: 'https://educator.oss-cn-hangzhou.aliyuncs.com/static/app-icon.png',
            link: `share-trend?replyId=${this.replyId}&itemId=${this.itemId}`
          })
          document.title = result.gambitName || '光明教育家'
        }
      })
    }
  }
</script>

<style scoped>

  .container {
    width: 750px;
    min-height: 100vh;
    max-height: 200vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    position: relative;
  }

  .bottom-open-app {
    background: #d8201e;
    width: 575px;
    height: 84px;
    line-height: 84px;
    text-align: center;
    color: white;
    font-size: 28px;
    border-radius: 42px;
    position: absolute;
    left: 88px;
    bottom: 60px;
  }

  .shadow {
    position: fixed;
    z-index: 99999;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(0,0,0,0.3);
  }

  .hint {
    width: 378px;
    height: 236px;
    position: fixed;
    z-index: 999;
    right: 20px;
    top: 0;
  }

  .header {
    width: 750px;
    height: 80px;
    border-bottom: 1px solid #f2f2f2;
  }

  .white-cover {
    width: 750px;
    height: 400px;
    position: absolute;
    left: 0;
    bottom: 0;
  }

  .share-more {
    width: 50px;
    height: 50px;
    position: absolute;
    bottom: 200px;
    left: 350px;
  }
</style>
