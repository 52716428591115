import * as API from './'

/**
 * 提交表单
 * @param {object} params 请求参数
 * @param {string} params.name 姓名
 * @param {string} params.mobile 手机号
 * @param {number} params.code 手机验证码
 * @param {number} params.email 邮箱
 * @param {number} params.address 地址
 * @param {number} params.workUnit 工作单位
 * @param {number} params.post 职位
 * @returns 
 */
export function submitForm(params) {
    return API.POST('/advert/lenovo/form/collect', params);
}