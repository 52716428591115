<template>
  <div class="trend-item" @click="showViewer">
    <div class="trend-item-inner">
      <div class="avatar-wrapper">
        <img class="avatar" :src="item.User.face"/>
      </div>

      <div class="right-container">
        <div style="font-size: 30px; color: #333333; margin-top: 42px">{{item.User.nickName}}</div>
        <div style="font-size: 24px; color: #999999; margin-top: 9px">{{item.User.School ? item.User.School.name + ' ' :
          null}}{{item.time}}
        </div>
        <div style="display: flex; flex-direction: row" v-if="item.gambitName">
          <div class="topic-wrapper">
            <div class="topic">#{{item.gambitName}}</div>
          </div>
        </div>

        <div class="content">{{item.properties.detail}}</div>
        <!--<div ref="pl" class="pic-list" v-if="item.picList && item.picList.length" v-viewer>-->
        <div ref="pl" class="pic-list" v-if="item.picList && item.picList.length">
          <img class="pic" v-for="(p, index) in item.picList"
               :key="index"
               :src="p"
               :style="{marginRight: (index+1) % 3 === 0 ? 0 : '10px'}"/>
        </div>
      </div>
    </div>

    <div style="width: 690px; height: 1px; background-color: #f2f2f2"></div>
  </div>
</template>

<script>
  export default {
    name: 'trend-item',
    props: ['item'],
    data () {
      return {

      }
    },
    mounted () {
    },
    methods: {
      showViewer() {
        // this.$refs.pl.$viewer.show()
      }
    }
  }
</script>

<style scoped>
  .trend-item {
    width: 750px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .trend-item-inner {
    width: 750px;
    position: relative;
    display: flex;
    flex-direction: row;
  }

  .avatar-wrapper {
    width: 128px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .avatar {
    width: 80px;
    height: 80px;
    border-radius: 40px;
    margin-top: 30px;
    background-color: #f2f2f2;
  }

  .right-container {
    width: 592px;
    display: flex;
    flex-direction: column;
    padding-bottom: 30px;
  }

  .topic-wrapper {
    padding: 0 20px;
    box-sizing: border-box;
    border-radius: 25px;
    background-color: #eeeeee;
    height: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 20px;
  }

  .topic {
    font-size: 24px;
    color: #333333;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .content {
    width: 596px;
    font-size: 32px;
    color: #333333;
    margin-top: 20px;
    line-height: 50px;
  }

  .pic-list {
    width: 596px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 20px;
  }

  .pic {
    width: 190px;
    height: 190px;
    border-radius: 8px;
    border: 1px solid #f2f2f2;
    margin-top: 10px;
  }
</style>

