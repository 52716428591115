import api from "@/assets/api/api_vote"
import { getHashParameters } from "@/assets/utils/utils"
import { getFullPicUrl } from "../../utils/render"

export const AuthMixin = {
    data() {
        return {
            webInfo: {},
            fromId: 0,
        }
    },
    methods: {
        initWxConfig(shareInfo) {
            let requestParams = getHashParameters()
            this.fromId = shareInfo.fromId
            this.getPageInfo(requestParams, shareInfo)
        },
        getPageInfo(data, shareInfo) {
            api.getWebInfo(data).then((res) => {
                if (res.errcode === 0) {
                    this.webInfo = res.data
                    this.initWebInfo(shareInfo)
                }
            })
        },
        initWebInfo(shareInfo) {
            console.log("initWebInfo", shareInfo)
            let timestamp = this.webInfo.timestamp
            let nonceStr = this.webInfo.nonceStr
            let signature = this.webInfo.signature
            let appId = this.webInfo.appId
            wx.config({
                debug: false,
                appId: appId,
                timestamp: timestamp,
                nonceStr: nonceStr,
                signature: signature,
                jsApiList: [
                    "hideMenuItems",
                    "chooseWXPay",
                    "closeWindow",
                    "onMenuShareWeibo",
                    "onMenuShareQQ",
                    "onMenuShareQZone",
                    "getLocation",
                    "chooseImage",
                    "uploadImage",
                    "getLocalImgData",
                    "downloadImage",
                    "updateTimelineShareData",
                    "updateAppMessageShareData",
                ], // 必填,需要使用的JS接口列表,所有JS接口列表见附录2
            })
            wx.ready(() => {
                let imgUrl = shareInfo.pic || (shareInfo.picList ? shareInfo.picList[0] : null)
                imgUrl = imgUrl || this.webInfo.photo
                imgUrl = getFullPicUrl(imgUrl)
                let title = shareInfo.itemName || this.webInfo.title
                let desc = shareInfo.desc
                if (!desc) {
                    desc = ""
                }
                let link = ""
                if (this.webInfo.link) {
                    link = `https://xst.olomobi.com/#/?fromId=${shareInfo.fromId}&applyId=${shareInfo.applyId}`
                } else {
                    link = `https://xst.olomobi.com/#/${shareInfo.link}`
                }
                console.log("wx.ready======================>", link)
                let config = {
                    title: title,
                    desc: desc,
                    link: link,
                    imgUrl: imgUrl,
                    type: "link",
                    success() {
                        console.log("分享success")
                    },
                    cancel() {
                        console.log("分享cancel")
                    },
                }
                console.log("config------------>>>>>>>>>>>>>>>")
                console.log(config)
                wx.hideMenuItems({
                    menuList: ["menuItem:copyUrl", "menuItem:share:email"],
                })
                wx.onMenuShareQQ(config)
                wx.onMenuShareQZone(config)
                wx.onMenuShareWeibo(config)
                wx.updateAppMessageShareData(config)
                wx.updateTimelineShareData(config)
            })
            wx.error((res) => {
                console.log("init失败", res)
            })
        },
    },
}
