const storage = {
  set(key, value) {
    localStorage.setItem(key, JSON.stringify(value))
  },
  get(key) {
    return JSON.parse(localStorage.getItem(key))
  },
  remove(key) {
    localStorage.removeItem(key)
  },
  setText(key, value) {
    localStorage.setItem(key, value)
  },
  getText(key) {
    return localStorage.getItem(key)
  }
}
export default storage
