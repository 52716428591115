<template>
  <div class="splash">
    <img class="splash-img" :src="splashImage">
    <div class="skip-btn">跳过({{countdown}})</div>
  </div>
</template>

<script>

  export default {
    props: ['splashImage'],
    data() {
      return {
        countdown: 3
      }
    },
    mounted() {
      setInterval(() => {
        if (this.countdown > 1) {
          this.countdown--
        } else {
          this.$emit('onSplashSkip')
        }
      }, 1000)
    },
    methods: {

    }
  }
</script>

<style scoped>

  .splash {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
  }

  .splash-img {
    width: 100vw;
    height: 100vh;
    object-fit: cover;
  }

  .skip-btn {
    height: 60px;
    padding: 0 34px;
    line-height: 60px;
    background: rgba(34, 34, 34, 0.3);
    border-radius: 30px;
    font-size: 32px;
    color: #FFFFFF;
    position: fixed;
    right: 24px;
    top: 60px;
  }
</style>

