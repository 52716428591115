import * as API from './'

export default {
    /**
     * 获取机构详情
     * @param {*} params {schoolId}
     * @returns 
     */
    getInfo: params => {
        return API.GET('/school/getSchoolByID', params)
    },
    /**
     * 获取机构获赞数 
     * @param {*} params {schoolId}
     * @returns 
     */
    getLikeCount: params => {
        return API.GET('/school/getSchoolLikeCount', params)
    },
    /**
     * 获取机构粉丝数
     * @param {*} params {schoolId}
     * @returns 
     */
    getFansCount: params => {
        return API.GET('/school/getSchoolFansCount', params)
    },
    /**
     * 获取机构文章列表
     * @param {*} params 
     * @returns 
     */
    getArticleList: params => {
        return API.GET('/school/getSchoolItem', params)
    },
}