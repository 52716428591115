<template>
  <div class="container">
    <img class="header" :src="detail.pic" />
    <!--<div class="course-list">-->
    <!--<course-item v-for="(item, index) in dataList"-->
    <!--:key="index"-->
    <!--:item="item" />-->
    <!--</div>-->

    <div id="mescroll" class="mescroll">
      <div class="course-list">
        <course-item v-for="(item, index) in dataList" :key="index" :item="item" />
      </div>
    </div>
  </div>
</template>

<script>
import CourseItem from "@/components/courseItem";
import { formatDate, formatSeconds, getFullPicUrl } from "../utils/render";
import api from "../assets/api/api_vote";
import { AuthMixin } from "../assets/utils/mixin";

export default {
  mixins: [AuthMixin],
  name: "Home",
  data() {
    return {
      detail: {},
      pageNum: 1,
      hasMore: true,
      dataList: [],
    };
  },
  components: {
    CourseItem,
  },
  methods: {
    initScroll() {
      // eslint-disable-next-line
      this.mescroll = new MeScroll("mescroll", {
        down: {
          use: false,
        },
        up: {
          callback: this.getTopicItem,
          htmlNodata: '<p class="edu-nodata">我是有底线的</p>',
        },
      });
    },
    getDetail() {
      let params = {
        topicId: 2,
      };
      api.getTopicById(params).then((res) => {
        res.data.pic = getFullPicUrl(res.data.pic);
        this.detail = res.data;
      });
    },
    getTopicItem() {
      if (!this.hasMore) {
        return;
      }
      let params = {
        start: (this.pageNum - 1) * 10,
        length: 10,
        topicId: 2,
        topicTypeId: 3,
      };

      api.getTopicItem(params).then((res) => {
        let results = res.data ? res.data : [];
        results.forEach((t) => {
          if (t.properties && t.properties.pic) {
            t.properties.pic = getFullPicUrl(t.properties.pic);
            t.time = formatDate(new Date(t.createTime));
            t.duration = t.totalTime ? formatSeconds(t.totalTime) : 0;
          }
        });
        let hasMore = results.length >= 10;
        if (this.pageNum !== 1) {
          results = this.dataList.concat(results);
        }

        this.dataList = results;
        this.pageNum += 1;
        this.hasMore = hasMore;
        this.mescroll.endSuccess(results.length, hasMore);
      });
    },
  },
  mounted() {
    this.initScroll();
    this.getDetail();
    // this.getTopicItem()

    this.initWxConfig({
      fromId: "Home",
      applyId: "",
    });
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.container {
  width: 750px;
  height: 100vh;
  position: relative;
}

.header {
  width: 750px;
  height: 320px;
  background: #f2f2f2;
  position: fixed;
  top: 0;
  left: 0;
}

.course-list {
  width: 750px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.mescroll {
  position: fixed;
  top: 320px;
  bottom: 0;
  height: auto; /*如设置bottom:50px,则需height:auto才能生效*/
}
</style>
