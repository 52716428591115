import axios from "axios"
import qs from "qs"

// let HOST = "http://192.168.1.131:6363"
let HOST = "https://best.olomobi.com"
let HOST_HXZ = "https://hxz.olomobi.com"
let HOST_PCK = "https://pck-server.olomobi.com"

axios.defaults.withCredentials = true
axios.defaults.timeout = 500000
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
axios.defaults.headers.post["Content-Type"] = "application/x-www-form-urlencoded;charset=UTF-8" //配置请求头
// 添加一个响应拦截器
axios.interceptors.response.use(
    function (response) {
        if (response.data && response.data.errcode) {
            if (parseInt(response.data.errcode) === -1) {
                //未登录
            }
        }
        return response
    },
    function (error) {
        console.log(error)
        // Do something with response error
        // console.log('=======================error=====================',error)
        let customErrors = "网络繁忙!请稍后再试!/(ㄒoㄒ)/~~"
        return Promise.reject(customErrors)
    }
)

//通用方法
export const POST = (url, params, ext) => {
    if (params) {
        if (!params.platform) {
            params.platform = "h5"
        }
    }
    let host = ext && ext.host ? ext.host : HOST
    return axios.post(`${host}${url}`, params).then((res) => res.data)
}

export const GET = (url, params, ext) => {
    if (params) {
        if (!params.platform) {
            params.platform = "h5"
        }
    }
    let host = ext && ext.host ? ext.host : HOST
    return axios
        .get(`${host}${url}`, {
            params: params,
            paramsSerializer: (params) => {
                return qs.stringify(params, { indices: false })
            },
        })
        .then((res) => res.data)
}

export const POST_HXZ = (url, params) => {
    return POST(url, params, { host: HOST_HXZ })
}

export const POST_PCK = (url, params) => {
    return POST(url, params, { host: HOST_PCK })
}

export const GET_HXZ = (url, params) => {
    return GET(url, params, { host: HOST_HXZ })
}

export const GET_PCK = (url, params) => {
    return GET(url, params, { host: HOST_PCK })
}

export const UPLOAD = (url, file) => {
    // if (!(imgs && imgs.length)) return
    let formData = new FormData()
    // imgs.map(item => {
    //   let file = {uri: item.uri, type: 'multipart/form-data', name: 'image.png'}
    //   formData.append('file', file)
    // })
    formData.append("file", file)
    return axios
        .post(`${HOST}${url}`, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        })
        .then((res) => res.data)
}

export const PUT = (url, params) => {
    return axios.put(`${HOST}${url}`, params).then((res) => res.data)
}

export const DELETE = (url, params) => {
    return axios.delete(`${HOST}${url}`, { params: params }).then((res) => res.data)
}

export const PATCH = (url, params) => {
    return axios.patch(`${HOST}${url}`, params).then((res) => res.data)
}

export default HOST
