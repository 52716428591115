import CallApp from 'callapp-lib';
import { IS_IOS } from './platform';

const QQ_SCHEME = 'tencent102032163';
const WX_SCHEME = 'wx6e6fd710a14f80f8';


const options = {
    scheme: {
        protocol: IS_IOS ? 'tencent102032163' : 'edutop',
    },
    intent: {
        package: 'com.olomobi.edutopapp',
        scheme: 'edutop',
    },
    universal: {
        host: 'edu.olomobi.com',
        pathKey: '',
    },
    fallback: '//a.app.qq.com/o/simple.jsp?pkgname=com.olomobi.edutopapp',
    appstore: 'https://itunes.apple.com/cn/app/id1523960965',
    yingyongbao: '//a.app.qq.com/o/simple.jsp?pkgname=com.olomobi.edutopapp',
};

const callLib = new CallApp(options);


export function callApp(path, param) {
    callLib.open({ path, param });
}