<template>
    <div class='official-cases-digitalize-container'>
        <img src="../../../../public/static/images/official/cases/digitalize/1.png" alt="">
        <img src="../../../../public/static/images/official/cases/digitalize/2.png" alt="">\
    </div>
</template>

<script>

export default {
    name: '',
    components: {},
    data() {
        return {

        };
    },
    computed: {},
    watch: {},
    created() { },
    mounted() { },
    methods: {},
}
</script>
<style lang='less' scoped>
.official-cases-digitalize-container {

    font-size: 0;

    img {
        width: 100%;
        vertical-align: top;
        margin-top: -2px;
    }
}
</style>