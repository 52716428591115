<template>
  <div class="container">
    <div class="container-first-screen">
      <div id="canvas-container" class="canvas-container" v-show="showCanvas">
        <img
          style="width: 672px; height: 896px"
          src="https://educator.oss-cn-hangzhou.aliyuncs.com/static/event/ev3/bg-share.png?rd=2"
          crossorigin="anonymous"
        />
        <div class="canvas-content">
          <div style="font-size: 36px; color: #333; margin-top: 60px">
            父母类型：<span style="font-size: 42px; color: #e71e28">{{ result.resultKeyType.type }}</span>
          </div>
          <div style="display: flex; flex-direction: row; align-items: center; margin-top: 42px">
            <img
              style="
                width: 225px;
                height: 174px;
                border-radius: 10px;
                background: #f2f2f2;
                margin-right: 30px;
              "
              :src="result.pic"
              crossorigin="anonymous"
            />
            <div style="flex: 1; color: #333; font-size: 28px; line-height: 36px">
              {{ result.resultKeyType.detail }}
            </div>
          </div>
          <div style="font-size: 36px; color: #333; margin-top: 45px">教育关键词</div>
          <div class="results" style="margin-top: 16px">
            <span style="font-size: 32px; color: #e71e28">{{ result.resultKeyWords[0].key }}：</span
            >{{ result.resultKeyWords[0].detail }}
          </div>
          <div class="results">
            <span style="font-size: 32px; color: #e71e28">{{ result.resultKeyWords[1].key }}：</span
            >{{ result.resultKeyWords[1].detail }}
          </div>
          <div class="qr-code-container">
            <img
              style="width: 166px; height: 92px; margin-right: 26px; margin-top: 35px"
              src="https://educator.oss-cn-hangzhou.aliyuncs.com/static/event/ev3/scan.png"
              crossorigin="anonymous"
            />
            <img
              style="width: 108px; height: 108px; margin-right: 85px; margin-top: 30px"
              src="https://educator.oss-cn-hangzhou.aliyuncs.com/static/event/ev3/qrcode.png"
              crossorigin="anonymous"
            />
            <img
              style="width: 191px; height: 163px; margin-top: -14px"
              src="https://educator.oss-cn-hangzhou.aliyuncs.com/static/event/ev3/cer.png"
              crossorigin="anonymous"
            />
          </div>
          <div class="download-hint-container">
            <img
              class="app-icon"
              src="https://educator.oss-cn-hangzhou.aliyuncs.com/static/app-icon.png"
              crossorigin="anonymous"
            />
            <div style="font-size: 26px; color: #333">
              应用市场搜<span style="color: #e71e28">“光明教育家”</span>,让教育更轻松！
            </div>
          </div>
        </div>
      </div>
      <div class="btn-container">
        <img
          style="width: 274px; height: 80px; margin-right: 20px"
          src="http://educator.oss-cn-hangzhou.aliyuncs.com/static/event/ev3/btn2.png"
          @click="again"
        />
        <img
          style="width: 348px; height: 74px"
          src="http://educator.oss-cn-hangzhou.aliyuncs.com/static/event/ev3/btn1.png"
          @click="share"
        />
      </div>
      <div style="width: 500px; font-size: 28px; color: #333; margin-top: 40px" @click="downloadApk">
        下载 <span style="color: #e71e28">光明教育家APP</span> 让教育更轻松！
      </div>

      <div class="slide-hint">
        <img
          style="width: 46px; height: 30px"
          src="https://educator.oss-cn-hangzhou.aliyuncs.com/static/event/ev3/slide-up.png"
        />
        <div style="font-size: 24px; color: white; margin-left: 30px">上滑发现更多</div>
      </div>
      <img id="canvas-result" class="canvas-result" />
    </div>
    <img
      class="long-press"
      src="https://educator.oss-cn-hangzhou.aliyuncs.com/static/event/ev3/long-press.png"
    />

    <!--第二屏-->
    <div class="container-second-screen">
      <img
        class="poster-logo"
        src="https://educator.oss-cn-hangzhou.aliyuncs.com/static/event/ev3/poster-logo.png"
      />
      <img
        class="poster-title"
        src="https://educator.oss-cn-hangzhou.aliyuncs.com/static/event/ev3/poster-title.png"
      />
      <img
        class="poster-preview"
        src="https://educator.oss-cn-hangzhou.aliyuncs.com/static/event/ev3/poster-preview.png"
      />
      <img
        class="poster-preview-cover"
        src="https://educator.oss-cn-hangzhou.aliyuncs.com/static/event/ev3/poster-preview-cover.png"
      />

      <div class="poster-download-container" @click="downloadApk">
        <img
          style="width: 40px; height: 36px; margin-right: 10px"
          src="https://educator.oss-cn-hangzhou.aliyuncs.com/static/event/ev3/poster-download.png"
        />
        <div style="color: #333; font-size: 28px">极速下载，让教育更轻松！</div>
      </div>
    </div>

    <div class="shadow" v-if="showShadow" @click="showShadow = false">
      <img class="hint" src="http://educator.oss-cn-hangzhou.aliyuncs.com/static/open-browser.png" />
    </div>
  </div>
</template>

<script>
import html2canvas from "html2canvas";
import { jump } from "../../../utils/render";
import apiCommon from "@/assets/api/api_common";
import apiEvent from "@/assets/api/api_event";
import { AuthMixin } from "@/assets/utils/mixin";

export default {
  name: "EV3Result",
  mixins: [AuthMixin],
  data() {
    return {
      showShadow: false,
      result: {
        resultKeyWords: [{}, {}],
        resultKeyType: {},
      },
      showCanvas: false,
    };
  },
  methods: {
    downloadApk() {
      jump(this, null, null, () => {
        this.showShadow = true;
      });
      apiCommon
        .traceReport({
          eventName: "clickElement",
          reportData: {
            function_type: "教育类型结果页",
            element_type: "下载",
          },
        })
        .then(() => {});
    },
    share() {
      if (!this.result) {
        return;
      }
      let resultKeyWords = this.result.resultKeyWords;
      // eslint-disable-next-line no-undef
      WeixinJSBridge.invoke(
        "sendAppMessage",
        {
          data_url: "",
          img_url: this.result.pic,
          link: "https://xst.olomobi.com/#/event/ev3/ev3-testing?m=1",
          title: `我的教育关键词：${resultKeyWords[0].key}+${resultKeyWords[1].key}，点击测测你的吧~`,
          desc: `我原来是${this.result.resultKeyType.type}的父母，你呢？`,
          type: "link",
        },
        (e) => {
          console.log(e);
        }
      );
      apiCommon
        .traceReport({
          eventName: "clickElement",
          reportData: {
            function_type: "教育类型结果页",
            element_type: "分享（晒晒指数）",
          },
        })
        .then(() => {});
    },
    again() {
      apiCommon
        .traceReport({
          eventName: "clickElement",
          reportData: {
            function_type: "教育类型结果页",
            element_type: "再测一次",
          },
        })
        .then(() => {
          window.location.href = "https://xst.olomobi.com/#/ev3-testing?m=1";
        });
    },
    updateCanvas() {
      html2canvas(document.getElementById("canvas-container"), {
        width: 672,
        height: 896,
        allowTaint: true,
        useCORS: true,
        backgroundColor: "rgb(247,235,200)",
      }).then((canvas) => {
        const dataUrl = canvas.toDataURL("image/png", 1.0);
        const imgElement = document.getElementById("canvas-result");
        imgElement.src = dataUrl;
      });
    },
  },
  mounted() {
    let query = this.$route.query;
    if (!query || !query.form) {
      return;
    }

    let form = JSON.parse(decodeURIComponent(query.form));
    let count = [0, 0, 0];
    form.forEach((it) => {
      count[it]++;
    });

    apiEvent.getDetectionResult({ clickInfo: count }).then((res) => {
      if (res.errcode !== 0) {
        return;
      }
      let result = res;
      let resultKeyWords = result.resultKeyWords;
      this.result = result;
      this.showCanvas = true;
      this.$nextTick(() => {
        this.updateCanvas();
      });
      this.initWxConfig({
        desc: `我的教育关键词：${resultKeyWords[0].key}+${resultKeyWords[1].key}，点击测测你的吧~`,
        itemName: `我原来是${result.resultKeyType.type}的父母，你呢？`,
        pic: result.pic,
        link: `?fromId=EV3Testing`,
      });
    });
  },
};
</script>

<style scoped>
.container {
  width: 750px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.container-first-screen {
  width: 750px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url(https://educator.oss-cn-hangzhou.aliyuncs.com/static/event/ev3/bg-result.png);
}

.container-second-screen {
  width: 750px;
  height: 100vh;
  position: relative;
  background: rgb(255, 94, 36);
}

.canvas-container {
  width: 672px;
  height: 896px;
  position: relative;
  margin-top: 38px;
}

.canvas-result {
  width: 672px;
  height: 896px;
  position: absolute;
  left: 39px;
  top: 38px;
}

.canvas-content {
  width: 672px;
  height: 896px;
  position: absolute;
  left: 0;
  top: 0;
  padding: 0 50px;
  box-sizing: border-box;
}

.results {
  color: #333;
  font-size: 28px;
  line-height: 42px;
  max-width: 582px;
}

.qr-code-container {
  height: 163px;
  display: flex;
  flex-direction: row;
}

.download-hint-container {
  width: 100%;
  height: 70px;
  border-radius: 35px;
  border: solid 4px #e71e28;
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.app-icon {
  width: 46px;
  height: 46px;
  border-radius: 23px;
  margin-left: 25px;
  margin-right: 10px;
}

.long-press {
  width: 152px;
  height: 40px;
  position: absolute;
  right: 57px;
  top: 58px;
}

.btn-container {
  width: 672px;
  height: 80px;
  display: flex;
  flex-direction: row;
  margin-top: 25px;
  justify-content: center;
  align-items: center;
}

.slide-hint {
  width: 750px;
  height: 30px;
  position: absolute;
  bottom: 25px;
  left: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.shadow {
  position: fixed;
  z-index: 99999;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
}

.hint {
  width: 378px;
  height: 236px;
  position: fixed;
  z-index: 999;
  right: 20px;
  top: 0;
}

.poster-logo {
  width: 194px;
  height: 90px;
  position: absolute;
  left: 278px;
  top: 26px;
}

.poster-title {
  width: 348px;
  height: 108px;
  position: absolute;
  left: 201px;
  top: 148px;
}

.poster-preview {
  width: 376px;
  height: 734px;
  position: absolute;
  left: 187px;
  top: 305px;
}

.poster-download-container {
  width: 450px;
  height: 80px;
  background: white;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 66px;
  left: 150px;
}

.poster-preview-cover {
  width: 440px;
  height: 354px;
  position: absolute;
  left: 155px;
  top: 750px;
}
</style>
