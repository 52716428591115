<template>
  <div class="container">
    <img class="banner" :src="info.cover" alt="" />
    <div class="info-wrapper">
      <div class="price-wrapper">
        <span class="symbol">¥</span>
        <span class="price">{{ parseInt(info.price / 100, 10) }}</span>
        <span class="decimal">.{{ formatNumber(parseInt(info.price, 10) % 100) }}</span>
        <span class="price-tip">（单人购买价格）</span>
      </div>
      <div class="tag-wrapper">
        <van-tag type="primary" color="#19b157" round size="small">园所报名享优惠</van-tag>
      </div>
      <div class="course-name">{{ info.name }}</div>
      <div class="props-wrapper">
        <van-icon name="notes-o" />
        <span>日期 | {{ courseTime }}</span>
      </div>
      <div class="props-wrapper" style="margin-bottom: 80px">
        <van-icon name="label-o" />
        <span>{{ info.describe }}</span>
      </div>
      <van-divider class="divider"> 课程介绍 </van-divider>
    </div>
    <img class="introduce" :src="info.introduce" alt="" />
    <div class="fied-bottom">
      <div class="button personal" @click="handleTeamPurchase">园所购买</div>
      <div class="button team" @click="handlePersonalPurchase">个人购买</div>
    </div>
    <van-action-sheet
      v-model:show="show"
      description="园所购买咨询电话"
      cancel-text="取消"
      :actions="actions"
      @select="onActionSelect"
    />
  </div>
</template>

<script>
import { Divider, Notify } from "vant";
import { ActionSheet } from "vant";
import { getTrainingCampGoodsDetail } from "@/assets/api/api_shop";
import dayjs from "dayjs";
import { formatNumber } from "@/utils/formatter";
export default {
  name: "TrainingCampGoods",
  components: { [Divider.name]: Divider, [ActionSheet.name]: ActionSheet },
  data() {
    return {
      // 是否显示联系方式
      show: false,
      // 联系方式
      actions: [],
      // 商品详情
      info: {},
    };
  },
  computed: {
    // 上课时间
    courseTime() {
      return `${dayjs(this.info.startTime).format("YYYY-MM-DD")} 至 ${dayjs(this.info.endTime).format(
        "YYYY-MM-DD"
      )}`;
    },
  },
  watch: {},
  created() {},
  mounted() {
    this.getDetail();
  },
  methods: {
    formatNumber,
    // 个人购买按钮点击
    handlePersonalPurchase() {
      this.$router.push({ path: "/shop/training-camp/createOrder" });
    },
    // 园所购买按钮点击
    handleTeamPurchase() {
      this.show = true;
    },
    // 点击联系方式
    onActionSelect(e) {
      const mobile = e.name;
      window.location.href = `tel:${mobile}`;
    },
    // 获取商品详情
    async getDetail() {
      const params = { id: 1 };
      const res = await getTrainingCampGoodsDetail(params);
      if (res.errcode !== 0) {
        Notify({ type: "danger", message: res.errmsg });
        return;
      }
      this.info = res.data;
      this.actions = [{ name: res.data.mobile }];
    },
  },
};
</script>
<style lang="less" scoped>
.container {
  --van-tag-padding: 2px 14px;
  --van-tag-font-size: 16px;
  --theme-color: #19b157;
  width: 100vw;
  .banner {
    background-color: #989898;
    width: 100%;
  }
  .info-wrapper {
    padding: 32px;
    .price-wrapper {
      display: flex;
      flex-direction: row;
      align-items: baseline;
      .symbol {
        color: var(--theme-color);
        font-size: 24px;
        font-weight: bold;
      }
      .price {
        margin-left: 10px;
        color: var(--theme-color);
        font-size: 40px;
        font-weight: bold;
        font-family: "Avenir-Heavy, PingFang SC, Helvetica Neue, Arial, sans-serif";
      }
      .decimal {
        color: var(--theme-color);
        font-size: 26px;
        font-weight: bold;
        font-family: "Avenir-Heavy, PingFang SC, Helvetica Neue, Arial, sans-serif";
      }
      .price-tip {
        color: #000;
        font-size: 18px;
      }
    }
    .tag-wrapper {
      margin-top: 10px;
    }
    .course-name {
      margin-top: 40px;
      color: #000;
      font-size: 28px;
      font-weight: bold;
    }
    .props-wrapper {
      margin-top: 30px;
      font-size: 26px;
      span {
        margin-left: 8px;
        color: #808080;
        font-size: 20px;
      }
      color: #808080;
    }
    .divider {
      color: #808080;
      border-color: #808080;
      margin-bottom: -10px;
    }
  }
  .introduce {
    background-color: #989898;
    width: 100%;
    margin-bottom: 100px;
  }
  .fied-bottom {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    border: 1px solid var(--theme-color);
    .button {
      flex: 1;
      height: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 28px;
    }
    .personal {
      background-color: white;
      color: var(--theme-color);
    }
    .team {
      background-color: var(--theme-color);
      color: white;
    }
  }
}
</style>