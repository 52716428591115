<template>
  <div style="position: relative; width: 750px">
    <img class="header"
         src="https://educator.oss-cn-hangzhou.aliyuncs.com/static/share-top-banner.png"
         @click="downloadApk" />
    <div class="container">
      <div class="detail">
        <div class="topic-cover">
          <!--<img style="width: 750px; height: 322px; object-fit: cover" :src="topic.pic" />-->
        </div>
        <!--<div class="topic-cover" style="background-color: rgba(0,0,0,0.3)" ></div>-->
        <div class="detail-content">
          <div style="display: flex; flex-direction: row; ">
            <div class="detail-item-name">#{{topic.gambitName}}</div>
          </div>
          <div class="detail-item-desc">{{topic.properties ? topic.properties.content : ''}}</div>
        </div>
      </div>
      <div class="navi">精彩评论</div>
      <dynamic-item :item="item" v-for="(item, index) in replyList" :key="index" />
      <div class="face-container" v-if="faceList && faceList.length">
        <img class="face" v-for="(f, fidx) in faceList" :src="f" :key="fidx" />
        <div style="font-size: 26px; color: #333333; margin-left: 30px">正在热议中...</div>
      </div>

      <!--<div class="white-cover"></div>-->
      <img class="share-more"
           src="https://educator.oss-cn-hangzhou.aliyuncs.com/static/share-more.png"
           @click="downloadApk" />
      <div class="bottom-open-app" @click="downloadApk">打开光明教育家APP参与话题讨论</div>
      <div class="shadow" v-if="showShadow" @click="showShadow = false">
        <img class="hint" src="https://educator.oss-cn-hangzhou.aliyuncs.com/static/open-browser.png" />
      </div>
    </div>
    <fixed-bottom @download="downloadApk" />
  </div>
</template>

<script>
  import api from "@/assets/api/api_share";
  import {DEFAULT_AVATAR, formatDate, getFullPicUrl, getPicList, jump, timePeriod} from "@/utils/render";
  import DynamicItem from "@/components/share/dynamicItem"
  import {AuthMixin} from "@/assets/utils/mixin";
  import FixedBottom from '@/components/share/fixedBottom'

  /**
   * 话题分享
   * @since 4.0
   */
  export default {
    mixins: [AuthMixin],
    name: 'ShareGambit',
    components: {DynamicItem, FixedBottom},
    data() {
      return {
        itemId: null,
        topic: {
          User: {},
          properties: {}
        },
        replyList: [],
        faceList: [],
        showShadow: false
      }
    },
    methods: {
      downloadApk() {
        let param = {
          gambitId: this.gambitId,
        }
        jump(this, 'GambitDetail', param, () => {
          this.showShadow = true
        })
      },
      getReplyList() {
        let params = {
          start: 0,
          length: 3,
          gambitId: this.gambitId
        }

        api.getShareGambitReply(params).then(res => {
          let results = res && res.data && res.data.rows ? res.data.rows : []
          let faceList = []
          results.forEach(item => {
            faceList.push(getFullPicUrl(item.User.face) || DEFAULT_AVATAR)

            // item.ChildReplyLength = item.ChildReply.length
            // item.ChildReply = item.ChildReply.slice(0, 2)
            // item.ChildReply.forEach(el => {
            //   el.properties = JSON.parse(el.properties)
            // })
            item.picList = getPicList(item.picList)
            if (item.Like && item.Like.length === 0) {
              item.Like.push({
                isLike: 0
              })
            }
            item.time = timePeriod(item.createTime)

            let desc = ''
            // let user = item.User || {}
            // if (user.School) {
            //   desc = user.School.name
            // } else if (!user.schoolId) {
            //   desc = user.isPersonalAuth
            //     ? '入驻用户'
            //     : user.vipLevel
            //       ? '年费会员'
            //       : '普通用户';
            // }
            item.desc = desc

            // 显示类型
            item.showType = 0
          })

          this.replyList = results[0] ? [results[0]] : []
          this.faceList = faceList
        })
      }
    },
    mounted() {
      this.gambitId = this.$route.query.gambitId
      api.getGambitInfo({gambitId: this.gambitId}).then(res => {
        let data = res.data

        data.properties.share = data.properties.share && getFullPicUrl(data.properties.share)
        data.pic = data.properties.picList ? getFullPicUrl(data.properties.picList[0]) : ''
        data.time = formatDate(new Date(data.createTime))
        document.title = data.gambitName
        this.topic = data

        this.initWxConfig({
          itemName: data.gambitName,
          pic: 'https://educator.oss-cn-hangzhou.aliyuncs.com/static/app-icon.png',
          link: `share-gambit?gambitId=${data.gambitId}`
        })
      })

      this.getReplyList()
    }
  }
</script>

<style scoped>

  .container {
    width: 750px;
    margin-top: 80px;
    min-height: calc(100vh - 80px);
    max-height: 200vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    position: relative;
  }

  .detail {
    width: 750px;
    height: 302px;
    position: relative;
  }

  .topic-cover {
    width: 750px;
    height: 322px;
    position: absolute;
    left: 0;
    top: -10px;
    opacity: 1;
    background-image: linear-gradient(180deg,
    #5a89a9 0%,
    #2c3e50 100%),
    linear-gradient(
      #213375,
      #213375);
    background-blend-mode: normal,
    normal;
  }

  .detail-content {
    width: 700px;
    display: flex;
    flex-direction: column;
    position: absolute;
    left: 25px;
    top: 30px;
  }

  .detail-item-name {
    max-width: 700px;
    font-size: 28px;
    color: white;
    line-height: 40px;
    text-shadow: 2px 2px 2px #333333;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 8px;
    padding: 10px 40px;
    box-sizing: border-box;
  }

  .detail-item-desc {
    width: 700px;
    font-size: 28px;
    color: white;
    line-height: 36px;
    margin-top: 30px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .navi {
    width: 750px;
    height: 90px;
    line-height: 90px;
    padding: 0 30px;
    font-size: 32px;
    color: #666666;
    box-sizing: border-box;
    border-bottom: solid 1px #e5e5e5;
    z-index: 998;
    background-color: white;
  }

  .bottom-open-app {
    background: #d8201e;
    width: 575px;
    height: 84px;
    line-height: 84px;
    text-align: center;
    color: white;
    font-size: 28px;
    border-radius: 42px;
    z-index: 999;
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .shadow {
    position: fixed;
    z-index: 99999;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(0,0,0,0.3);
  }

  .hint {
    width: 378px;
    height: 236px;
    position: fixed;
    z-index: 999;
    right: 20px;
    top: 0;
  }

  .header {
    width: 750px;
    height: 80px;
    border-bottom: 1px solid #f2f2f2;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
  }

  .white-cover {
    width: 750px;
    height: 400px;
    position: absolute;
    left: 0;
    bottom: 0;
  }

  .share-more {
    width: 50px;
    height: 50px;
  }

  .face-container {
    width: 750px;
    height: 220px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .face {
    width: 76px;
    height: 76px;
    border-radius: 38px;
    border: 2px solid #7dcdf2;
    margin-left: -38px;
  }
</style>
