<template>
  <div class="trend-item" @click="showViewer">
    <div class="trend-item-inner">
      <img class="avatar" :src="item.User.face" />
      <div class="right-container">
        <div class="nickname-wrapper">
          {{ item.User.nickName }}
          <img
            style="width: 28px; height: 28px; margin-left: 12px"
            v-if="item.Circle"
            src="https://educator.oss-cn-hangzhou.aliyuncs.com/static/share/circle.png"
          />
          <span class="circle" v-if="item.Circle">{{ item.Circle.circleName }}</span>
        </div>
        <div style="font-size: 24px; color: #999999; margin-top: 9px">{{ item.desc }} {{ item.time }}</div>
      </div>
    </div>
    <div>
      <div style="display: flex; flex-direction: row" v-if="item.gambitName">
        <div class="topic-wrapper">
          <div class="topic">#{{ item.gambitName }}</div>
        </div>
      </div>
      <div class="title" v-if="item.title">{{ item.title }}</div>

      <!-- <div v-if="item.htmlContent" class="content" v-html="item.htmlContent"></div> -->
      <div v-if="item.htmlContent" class="content" >
        <rich-content :html="item.htmlContent" />
      </div>
      <div v-else>
        <!-- <div class="content">{{ item.properties.detail }}</div> -->
        <div class="content"><rich-content :item="item.properties.detail" /></div>
        <!--<div ref="pl" class="pic-list" v-if="item.picList && item.picList.length" v-viewer>-->
        <div ref="pl" class="pic-list" v-if="item.picList && item.picList.length">
          <img
            class="pic"
            v-for="(p, index) in item.picList"
            :key="index"
            :src="p"
            :style="{ marginRight: (index + 1) % 3 === 0 ? 0 : '10px' }"
            @click="previewImage"
          />
        </div>
      </div>
      <video
        v-if="item.videoUrl"
        :src="item.videoUrl.videoUrl"
        controls
        :style="{ width: '592px', height: '360px', background: '#000' }"
      ></video>
    </div>

    <div style="width: 690px; height: 1px; background-color: #f2f2f2"></div>
  </div>
</template>

<script>
import RichContent from "./richContent";
export default {
  name: "dynamic-item",
  props: ["item"],
  components: {
    RichContent
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    previewImage() {
      this.$viewerApi({
        images: this.item.picList,
      });
    },
  },
};
</script>

<style scoped>
@import '../../assets/css/quill.css';

.trend-item {
  box-sizing: border-box;
  width: 750px;
  padding: 0 24px;
}

.trend-item-inner {
  display: flex;
  flex-direction: row;
}

.avatar {
  width: 80px;
  height: 80px;
  border-radius: 40px;
  margin-top: 30px;
  background-color: #f2f2f2;
}

.nickname-wrapper {
  height: 30px;
  font-size: 30px;
  color: #333333;
  margin-top: 42px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.right-container {
  margin-left: 24px;
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-bottom: 30px;
}
.topic-wrapper {
  padding: 0 20px;
  box-sizing: border-box;
  border-radius: 25px;
  background-color: #eeeeee;
  height: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
}

.topic {
  font-size: 24px;
  color: #333333;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  text-overflow: ellipsis;
  overflow: hidden;
}

.title {
  font-size: 28px;
  color: #333333;
  font-weight: bold;
  margin-top: 20px;
  line-height: 40px;
}

.content {
  font-size: 32px;
  color: #333333;
  margin-top: 20px;
  line-height: 50px;
  text-align: justify;
  width: 100%;
}

.pic-list {
  width: 596px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 20px;
}

.pic {
  width: 190px;
  height: 190px;
  border-radius: 8px;
  border: 1px solid #f2f2f2;
  margin-top: 10px;
}

.circle {
  font-size: 22px;
  color: #ffa4a4;
}
</style>

