<template>
    <div class="rs-index-container">
        <img class="banner" src="../../../../public/static/images/activity/red-story/banner.png" alt="" />
        <router-view />
        <van-tabbar active-color="white" inactive-color="#e41926" route :border="false">
            <van-tabbar-item replace to="/activity/read-story/display">作品展示</van-tabbar-item>
            <van-tabbar-item replace to="/activity/read-story/enroll">报名通道</van-tabbar-item>
            <!-- <van-tabbar-item replace to="/activity/read-story/notice">获奖通知</van-tabbar-item> -->
            <van-tabbar-item replace to="/activity/read-story/introduce">活动介绍</van-tabbar-item>
        </van-tabbar>
    </div>
</template>

<script>
export default {
    // "最美红色故事发声人"
    name: "RSIndex",
    data() {
        return {}
    },
}
</script>

<style lang="less" scoped>
.rs-index-container {
    box-sizing: border-box;
    padding-bottom: var(--van-tabbar-height);
}
</style>

<style>
.van-tabbar {
    background-color: #fbe29b;
}

.van-tabbar-item--active {
    background-color: #e41926;
}
</style>
