<template>
  <div class="container">
      <span class="success">提交成功!</span>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="less" scoped>
.container {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.success {
    font-size: 48px;
    color: green;
}
</style>