<template>
  <div class="container">
    <img class="img" alt="" src="../../../../public/static/images/activity/little-reporter/banner1.jpg" />
    <div class="form">
      <van-form @submit="submit" v-model="form" label-width="120px">
        <van-field
          v-model="form.schoolName"
          label="学校名称"
          placeholder="请输入学校名称"
          :rules="rules.schoolName"
        />
        <van-field
          v-model="form.stuNum"
          label="学生人数"
          placeholder="请输入在校学生人数"
          type="digit"
          :rules="rules.stuNum"
        />
        <van-field
          v-model="form.classNum"
          label="班级数"
          placeholder="请输入学校班级数"
          type="digit"
          :rules="rules.classNum"
        />
        <van-field
          v-model="form.applicant"
          label="申报人"
          placeholder="请输入申报人姓名"
          :rules="rules.applicant"
        />
        <van-field v-model="form.duties" label="职务" placeholder="请填写申报人职务" :rules="rules.duties" />
        <van-field
          v-model="form.mobile"
          label="手机号码"
          placeholder="请输入手机号码"
          type="tel"
          :rules="rules.mobile"
        />
        <van-field v-model="form.email" label="电子邮箱" placeholder="请输入电子邮箱" :rules="rules.email" />
        <van-field
          v-model="form.schoolAdd"
          label="学校地址"
          placeholder="请输入学校地址"
          :rules="rules.schoolAdd"
        />
        <van-field
          v-model="form.area"
          label="所在区域"
          placeholder="请选择所在区域"
          :rules="rules.area"
          readonly
          clearable
          @click="pickerVisible = true"
        />
        <van-field
          v-model="form.schoolIntro"
          label="学校介绍"
          placeholder="请输入学校介绍"
          :rules="rules.schoolIntro"
          autosize
          type="textarea"
          rows="1"
        />
        <van-field
          v-model="form.honor"
          label="所获荣誉"
          placeholder="请输入学校所获荣誉"
          :rules="rules.honor"
          autosize
          type="textarea"
          rows="1"
        />
        <van-button round block type="primary" color="#EB002A" native-type="submit"> 申 请 </van-button>
      </van-form>
    </div>
    <van-popup v-model:show="pickerVisible" round position="bottom">
      <van-picker
        show-toolbar
        title="所在区域"
        :item-height="60"
        :columns="city"
        @cancel="pickerVisible = false"
        @confirm="onPickerConfirm"
      />
    </van-popup>
  </div>
</template>

<script>
import apiActivity from "@/assets/api/api_activity";
import { Notify, Dialog } from "vant";
import city from "@/assets/json/city";
export default {
  data() {
    return {
      city,
      form: {},
      rules: {
        schoolName: [{ required: true, message: "学校名称不能为空" }],
        stuNum: [{ required: true, message: "在校学生数不能为空" }],
        classNum: [{ required: true, message: "学校班级数不能为空" }],
        applicant: [{ required: true, message: "申报人不能为空" }],
        duties: [{ required: true, message: "职务不能为空" }],
        mobile: [
          {
            pattern: /^1(3\d|4[5-9]|5[0-35-9]|6[567]|7[0-8]|8\d|9[0-35-9])\d{8}$/,
            message: "手机号格式错误",
          },
        ],
        email: [
          {
            pattern: new RegExp("^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\\.[a-zA-Z0-9_-]+)+$"),
            message: "电子邮箱格式错误",
          },
        ],
        schoolAdd: [{ required: true, message: "学校地址不能为空" }],
        area: [{ required: true, message: "所在区域不能为空" }],
        schoolIntro: [{ required: true, message: "学校简介不能为空" }],
        honor: [{ required: true, message: "所获荣誉不能为空" }],
      },
      datePickerVisible: false,
      pickerVisible: false,
    };
  },
  methods: {
    async submit() {
      const res = await apiActivity.submitLittleReporterForm2(this.form);
      if (res.errcode !== 0) {
        Notify({ type: "danger", message: res.errmsg });
        return;
      }
      Dialog.alert({
        title: "提示",
        message: "您的申请已提交",
      }).then(() => {
        this.form = {};
      });
    },
    onPickerConfirm(selected) {
      this.form.area = `${selected[0].text}-${selected[1].text}-${selected[2].text}`;
      this.pickerVisible = false;
    },

    // 年月格式化
    formatter(type, val) {
      if (type === "year") {
        return `${val}年`;
      } else if (type === "month") {
        return `${val}月`;
      }
      return val;
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  box-sizing: border-box;
  width: 750px;
  padding: 0 32px;
  .img {
    margin-top: 20px;
    width: 100%;
    height: 236px;
    border-radius: 16px;
    background-color: red;
  }
  .form {
    width: 100%;
    padding: 16px 0;
  }
}
.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  &-p {
    padding: 14px 0;
  }
  &-between {
    justify-content: space-between;
  }
}
.form-border {
  border-bottom: 1px solid #ebedf0;
}
.form-text-normal {
  font-size: 28px;
  color: #646566;
}
.form-item {
  padding-top: 20px;
}
.form-label {
  font-size: 28px;
  font-weight: bold;
  line-height: 30px;
  padding: 20px 0px;
}
</style>