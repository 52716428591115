import storage from "./storage";
import {uuid} from "./render";

const storageAction = {

  getUUID() {
    let u = storage.getText('__xst_uuid__')
    if (!u) {
      u = uuid()
      storage.setText('__xst_uuid__', u)
    }

    return u
  }
}
export default storageAction
