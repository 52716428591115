<template>
  <div class="hint-box" @click.stop="nothing" @touchmove.prevent v-show="showMe">
    <div class="box">
      <div class="title-wrapper">
        <div class="title">{{content}}</div>
      </div>
      <div class="btn-container">
        <div class="btn" style="color: #999999" @click="cancel">取消</div>
        <div style="width: 1px; height: 100px; background-color: #f2f2f2"></div>
        <div class="btn" style="color: #333333" @click="confirm">确定</div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'hintBox',
    props: ['content'],
    data () {
      return {
        showMe: false
      }
    },
    mounted () {
    },
    methods: {
      nothing() {},
      show() {
        this.showMe = true
      },
      hide() {
        this.showMe = false
      },
      confirm() {
        this.hide()
        this.$emit('onConfirm')
      },
      cancel() {
        this.hide()
        this.$emit('onCancel')
      }
    }
  }
</script>

<style scoped>
  .hint-box {
    width: 750px;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.3);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99999;
  }

  .box {
    width: 570px;
    height: 290px;
    background-color: white;
    border-radius: 8px;
  }

  .title-wrapper {
    width: 570px;
    height: 190px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .title {
    font-size: 28px;
    color: #333333;
  }

  .btn-container {
    width: 570px;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-top: 1px solid #f2f2f2;
  }

  .btn {
    flex: 1;
    height: 100px;
    line-height: 100px;
    text-align: center;
    font-size: 30px;
  }
</style>

