<template>
  <div class="container">
    <div class="dis-scroll">
      <img class="header"
           src="https://educator.oss-cn-hangzhou.aliyuncs.com/static/share-top-banner.png"
           @click="downloadApk"/>

      <div style="width: 750px; height: 422px; position: relative">
        <video class="player"
               id="player"
               ref="player"
               v-if="detail.videoLink && displayTime < displayMaxTime"
               :src="detail.videoLink"
               :poster="detail.videoPic"
               preload
               controls
               x5-playsinline=""
               playsinline=""
               webkit-playsinline=""></video>
        <div class="display-end" v-if="displayTime >= displayMaxTime" @touchmove.prevent @click="downloadApk">
          <img class="player" :src="detail.videoPic"/>
          <div class="display-end-hint">
            <div style="font-size: 32px; color: white">试看结束,打开APP观看完整视频</div>
          </div>
        </div>
      </div>
      <!--<div class="player" style="background-color: #f2f2f2" v-else></div>-->
      <div class="open-app" @click="downloadApk">试看3分钟,打开APP观看完整视频</div>
      <div class="author-container">
        <img id="avatarUser" webp="webp"
             style="width: 64px; height: 64px; border-radius: 32px; background: #f2f2f2"
             :src="detail.face"/>
        <div style="flex-direction: column; margin-left: 16px; flex: 1">
          <div id="nickName" style="font-size: 26px; color: #313332">{{detail.nickName}}</div>
          <div id="time" style="font-size: 26px; color: #b1b1b1; margin-top: 10px">{{detail.time}}</div>
        </div>
      </div>
    </div>
    <div id="richText" class="rich-text" v-html="detail.content"></div>

    <div class="shadow" v-if="showShadow" @click="showShadow = false">
      <img class="hint" src="http://educator.oss-cn-hangzhou.aliyuncs.com/static/open-browser2.png"/>
    </div>
    <fixed-bottom @download="downloadApk" />
  </div>
</template>

<script>

  import {DEFAULT_AVATAR, formatDate, formatSeconds, getFullPicUrl, jump, tagAddStyle} from "../../utils/render";
  import api from "../../assets/api/api_share";
  import {AuthMixin} from "@/assets/utils/mixin";
  import FixedBottom from '@/components/share/fixedBottom'

  export default {
    mixins: [AuthMixin],
    name: 'ShareCourse',
    components: {FixedBottom},
    data() {
      return {
        itemId: null,
        detail: {},
        showShadow: false,
        displayTime: 0,
        displayMaxTime: 180000
      }
    },
    methods: {
      downloadApk() {
        let param = {
          itemId: this.itemId,
        }
        jump(this, 'CourseDetail', param, () => {
          this.showShadow = true
        })
      },
      getCourseVideo(videoIds) {
        api.getItemByIds({ids: `[${videoIds.join(',')}]`}).then(res => {
          if (res.errcode !== 0) {
            return
          }
          let videoList = res.data
          videoList.forEach(it => {
            it.properties.pic = getFullPicUrl(it.properties.pic)
            it.properties.link = getFullPicUrl(it.properties.link)
            it.duration = it.properties.time ? formatSeconds(it.properties.time) : 0
          })
          this.detail.videoPic = videoList[0].properties.pic
          this.detail.videoLink = videoList[0].properties.link
          console.log(this.detail)
          this.initPlayer()
        })
      },
      initPlayer() {
        this.$nextTick(() => {
          let player = document.getElementById('player')
          player.addEventListener('play', () => {
            clearInterval(this.timer)
            this.timer = setInterval(() => {
              this.displayTime += 1000
              if (this.displayTime > this.displayMaxTime) {
                clearInterval(this.timer)
                this.exitFullScreen()
                player.pause()
              }
            }, 1000)
          })
          player.addEventListener('pause', () => {
            clearInterval(this.timer)
          })
        })
      },
      exitFullScreen() {
        let doc = document;
        if (doc.webkitCancelFullScree) {
          doc.webkitCancelFullScreen();
        } else if (doc.mozCancelFullScreen) {
          doc.mozCancelFullScreen();
        } else if (doc.exitFullscreen) {
          doc.exitFullscreen();
        }
      }
    },
    mounted() {
      this.itemId = this.$route.query.itemId
      api.getItem({itemId: this.itemId}).then(res => {
        let item = res.data
        let itemName = item.itemName
        let face = item.User.School.pic ? getFullPicUrl(item.User.School.pic) : DEFAULT_AVATAR
        let nickName = item.User.School ? item.User.School.name : item.User.nickName
        let time = formatDate(new Date(item.createTime))
        if (res.data.properties) {
          res.data.properties = JSON.parse(res.data.properties)
        }
        if (item.properties.picList) {
          item.properties.picList = JSON.parse(item.properties.picList)
        }
        let content = tagAddStyle(res.data.properties.content)
        let reg = /(font-size\s*:)(\s*\d+\.?\d*)(px|pt)(\s*;?\s*)/g;
        content = content.replace(reg, (matched, capture1, capture2, capture3, capture4) => {
          return capture1 + capture2 * 1.8 + capture3 + capture4;
        })
        let videoPic = null
        let videoLink = null
        if (item.itemTypeId === 2) {
          videoPic = getFullPicUrl(res.data.properties.pic)
          videoLink = getFullPicUrl(res.data.properties.link)
        }
        this.detail = {
          itemName,
          face,
          nickName,
          time,
          content,
          videoPic,
          videoLink
        }
        document.title = itemName

        this.initWxConfig({
          itemName: item.itemName,
          pic: 'https://educator.oss-cn-hangzhou.aliyuncs.com/static/app-icon.png',
          link: `share-course?itemId=${item.itemId}`
        })

        let videoIds = item.properties.ids
        if (videoIds && videoIds.length) {
          this.getCourseVideo(videoIds)
        } else {
          this.initPlayer()
        }
      })
    }
  }
</script>

<style scoped>

  .container {
    width: 750px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .dis-scroll {
    width: 750px;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: fixed;
    left: 0;
    top: 0;
  }

  .display-end {
    width: 750px;
    height: 422px;
    position: absolute;
    left: 0;
    top: 0;
  }

  .display-end-hint {
    width: 750px;
    height: 422px;
    position: absolute;
    left: 0;
    top: 0;
    background-color: rgba(46, 46, 46, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .header {
    width: 750px;
    height: 80px;
  }

  .player {
    width: 750px;
    height: 422px;
  }

  .shadow {
    position: fixed;
    z-index: 99999;
    left: 0;
    right: 0;
    top: 80px;
    bottom: 0;
    background: rgba(0, 0, 0, 0.3);
  }

  .hint {
    width: 750px;
    height: 80px;
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
  }

  .open-app {
    width: 650px;
    height: 80px;
    line-height: 80px;
    color: white;
    font-size: 30px;
    text-align: center;
    border-radius: 4px;
    background-color: #7dcdf2;
    margin-top: 30px;
  }

  .rich-text {
    width: 650px;
    overflow: hidden;
    padding-bottom: 204px;
    font-size: 32px;
    line-height: 60px;
    margin-top: 737px;
  }

  .author-container {
    width: 650px;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 125px;
  }

</style>
